import React, { useState, useEffect, useCallback } from 'react';
import {
    MenuItem,
    Stack,
    styled,
    TextField,
    IconButton,
    Checkbox,
    FormControlLabel,
    Divider,
    Modal,
    CircularProgress,
} from '@mui/material';
import MainTemplate from '../../components/templates/MainTemplate';
import Typography from '../../components/atoms/Typography';
import Button from '../../components/atoms/Button';
import {
    getForm,
    addQuestion,
    updateQuestion,
    deleteQuestion,
    updateForm,
} from '../../services/api';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import theme from '../../theme';
import Papa from 'papaparse';
import { useLocation, useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import RenderQuestion from './RenderQuestion';
import useSnackBar from '../../utils/hooks';
import CustomSnackbar from '../../components/molecules/CustomSnackbar';

const StyledButton = styled(Button)({
    padding: '5px 8px',
    width: '200px',
});

interface AnswerOptionDTO {
    text: string;
    value: string;
    answerValue?: number;
}

export interface QuestionDTO {
    id?: string;
    text: string;
    domain: string;
    evidenceRequirement: string;
    answerType: 'TEXT' | 'RADIO' | 'CHECKBOX' | 'FILE_UPLOAD' | 'COMPOSITE';
    options?: AnswerOptionDTO[];
    fileUploadAllowed?: boolean;
    noteAllowed?: boolean;
}

interface FormDTO {
    id: string;
    title: string;
    description: string;
    instructions: string;
    questions: QuestionDTO[];
}

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '38vw',
    bgcolor: 'background.paper',
    border: '1px solid #D4D4D4',
    boxShadow: 24,
    padding: '15px',
    borderRadius: '7px',
};

const EditQuestionModal = ({ question, onSave, onClose }) => {
    const [editedText, setEditedText] = useState(question.text);

    const handleSave = () => {
        onSave({ ...question, text: editedText });
    };

    return (
        <Modal
            open={true}
            onClose={onClose}
            aria-labelledby="edit-question-modal"
            aria-describedby="modal-to-edit-question"
        >
            <Stack sx={modalStyle}>
                <Typography variant="subtitle1">{'Edit Question'}</Typography>
                <TextField
                    fullWidth
                    label="Question Text"
                    value={editedText}
                    onChange={(e) => setEditedText(e.target.value)}
                    multiline
                    rows={1}
                />
                <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="flex-end"
                    mt={2}
                >
                    <Button onClick={onClose} variant="outlined">
                        <Typography variant="caption1">{'Cancel'}</Typography>
                    </Button>
                    <Button onClick={handleSave} variant="contained">
                        <Typography variant="caption1">
                            {'Save Changes'}
                        </Typography>
                    </Button>
                </Stack>
            </Stack>
        </Modal>
    );
};

const GenerateFormPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const [form, setForm] = useState<FormDTO | null>(null);
    const [newQuestion, setNewQuestion] = useState<Partial<QuestionDTO>>({
        text: '',
        domain: '',
        evidenceRequirement: '',
        answerType: 'RADIO',
        options: [{ text: '', value: '', answerValue: 0 }],
        noteAllowed: false,
        fileUploadAllowed: false,
    });
    const [editingQuestionId, setEditingQuestionId] = useState<string | null>(
        null
    );
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [openInstructions, setOpenInstructions] = useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const { openSnackBar, handleClick, handleClose } = useSnackBar();

    const handleInstructionClose = () => {
        setOpenInstructions(false);
    };

    const getFormData = useCallback(async () => {
        try {
            const response = await getForm(id);
            if (response) {
                setForm(response);
            }
        } catch (err) {
            setError('Failed to fetch form data');
            console.error(err);
        } finally {
            setLoading(false); // End loading
        }
    }, []);

    useEffect(() => {
        getFormData();
    }, [getFormData]);

    const handleCSVUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            Papa.parse(file, {
                header: true,
                complete: async (result) => {
                    const questions = parseCSVData(result.data);
                    if (questions) {
                        for (const question of questions) {
                            await addParsedQuestionToDB(question);
                        }
                        getFormData();
                    }
                    resetCSVInput();
                },
                error: (err) => {
                    console.error('Error parsing CSV:', err);
                    setError('Failed to parse CSV');
                },
            });
        }
    };

    const prepareQuestionPayload = (question) => {
        let options = question.options;

        // Ensure options array is always present
        if (!options || options.length === 0) {
            options = [{ text: '', value: '', answerValue: 0 }];
        }

        return {
            ...question,
            options: options,
        };
    };

    const addParsedQuestionToDB = async (question: Partial<QuestionDTO>) => {
        if (!form || !question.text || !question.answerType) {
            setError('Invalid question data');
            return;
        }

        const preparedQuestion = prepareQuestionPayload(question);

        const response = await addQuestion(form.id, preparedQuestion);
        if (response) {
            setMessage('Question added successfully.');
            handleClick();
            setSuccess(true);
        } else {
            setMessage('Failed to add Question.');
            handleClick();
            setSuccess(false);
        }
    };

    const parseCSVData = (data) => {
        return data.map((row) => {
            const question: QuestionDTO = {
                text: row['QUESTION'],
                answerType: row['ANSWER TYPE'] as QuestionDTO['answerType'],
                domain: row['DOMAIN'],
                evidenceRequirement: row['EVIDENCE REQUIREMENT'],
            };

            if (
                ['RADIO', 'CHECKBOX', 'COMPOSITE'].includes(question.answerType)
            ) {
                question.options = [];

                for (let i = 1; i <= 10; i++) {
                    // Adjust based on the maximum options you support
                    if (row[`OPTION ${i} TEXT`]) {
                        question.options.push({
                            text: row[`OPTION ${i} TEXT`],
                            value: row[`OPTION ${i} VALUE`],
                            answerValue: row[`OPTION ${i} ANSWERVALUE`],
                        });
                    }
                }

                if (question.answerType === 'COMPOSITE') {
                    question.noteAllowed =
                        row['NOTE ALLOWED']?.trim().toLowerCase() === 'true';
                    question.fileUploadAllowed =
                        row['FILE UPLOAD ALLOWED']?.trim().toLowerCase() ===
                        'true';
                }
            }

            return question;
        });
    };

    const handleAddQuestion = useCallback(async () => {
        if (!form || !newQuestion.text || !newQuestion.answerType) {
            setError('Please fill in all fields to add a question');
            return;
        }

        const preparedQuestion = prepareQuestionPayload(newQuestion);

        const response = await addQuestion(form.id, preparedQuestion);
        if (response) {
            getFormData();
            setMessage('Question added successfully.');
            handleClick();
            setSuccess(true);
        } else {
            setMessage('Failed to add Question.');
            handleClick();
            setSuccess(false);
        }
    }, [newQuestion, form]);

    const handleEditQuestion = (questionId: string) => {
        setEditingQuestionId(questionId);
    };

    const handleUpdateQuestion = async (updatedQuestion: QuestionDTO) => {
        if (!form) return;

        const response = await updateQuestion(
            form.id,
            updatedQuestion.id!,
            updatedQuestion
        );
        if (response) {
            setForm((prevForm) => ({
                ...prevForm!,
                questions: prevForm!.questions.map((q) =>
                    q.id === updatedQuestion.id ? updatedQuestion : q
                ),
            }));
            setEditingQuestionId(null);
            setError(null);

            setMessage('Question updated successfully.');
            handleClick();
            setSuccess(true);
        } else {
            setMessage('Failed to update question');
            handleClick();
            setSuccess(true);
        }
    };

    const handleFormUpdate = async () => {
        const response = await updateForm(form.id, {
            ...form,
            instructions: form?.instructions,
        });
        if (response) {
            getFormData();
            handleInstructionClose();
            setMessage('Instructions added successfully.');
            handleClick();
            setSuccess(true);
        } else {
            setMessage('Failed to add instructions.');
            handleClick();
            setSuccess(false);
        }
    };

    const handleCloseModal = () => {
        setEditingQuestionId(null);
    };

    const handleDeleteQuestion = async (questionId: string) => {
        if (!form) return;

        const response = await deleteQuestion(form.id, questionId);
        if (response) {
            getFormData();
            setMessage('Question deleted successfully.');
            handleClick();
            setSuccess(true);
        } else {
            setMessage('Failed to delete question');
            handleClick();
            setSuccess(false);
        }
    };

    const handleOptionChange = (
        index: number,
        field: keyof AnswerOptionDTO,
        value: string | number
    ) => {
        if (!newQuestion.options) return;
        const updatedOptions = newQuestion.options.map((option, i) =>
            i === index ? { ...option, [field]: value } : option
        );
        setNewQuestion({ ...newQuestion, options: updatedOptions });
    };

    const addOption = () => {
        if (!newQuestion.options) return;
        setNewQuestion({
            ...newQuestion,
            options: [
                ...newQuestion.options,
                {
                    text: '',
                    value: '',
                    answerValue: 0,
                },
            ],
        });
    };

    const removeOption = (index: number) => {
        if (!newQuestion.options) return;
        const updatedOptions = newQuestion.options.filter(
            (_, i) => i !== index
        );
        setNewQuestion({ ...newQuestion, options: updatedOptions });
    };

    const resetCSVInput = () => {
        const fileInput = document.getElementById(
            'csv-upload'
        ) as HTMLInputElement;
        if (fileInput) {
            fileInput.value = ''; // Reset the file input value
        }
    };

    const debouncedSetEvidenceRequirement = useCallback(
        debounce((value: string) => {
            setNewQuestion((prev) => ({
                ...prev,
                evidenceRequirement: value,
            }));
        }, 500),
        []
    );

    const handleEvidenceRequirementChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        debouncedSetEvidenceRequirement(e.target.value);
    };

    const debouncedSetQuestionText = useCallback(
        debounce((value: string) => {
            setNewQuestion((prev) => ({
                ...prev,
                text: value,
            }));
        }, 500),
        []
    );

    const handleQuestionTextChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        debouncedSetQuestionText(e.target.value);
    };

    const handleTextChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setForm((prevForm) => {
            if (!prevForm) return null;

            return {
                ...prevForm,
                instructions: event.target.value,
            };
        });
    };

    return (
        <MainTemplate
            main={
                <Stack sx={{ height: '100%' }}>
                    <Stack
                        sx={{
                            height: '40px',
                            width: '100%',
                            position: 'sticky',
                            top: 0,
                            backgroundColor: '#FFF',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '10px 0px',
                        }}
                        direction={'row'}
                    >
                        <Stack direction={'row'}>
                            <IconButton
                                disableRipple
                                onClick={() => navigate('/u/formPage')}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography variant="h1">
                                {form?.title || 'Create Form'}
                            </Typography>
                        </Stack>
                        <Stack
                            direction={'row'}
                            sx={{
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography variant="h3">
                                {'Total Questions: ' + form?.questions.length}
                            </Typography>
                            <Button
                                onClick={() => {
                                    setOpenInstructions(true);
                                }}
                                variant="contained"
                                sx={{ margin: '0px 20px' }}
                            >
                                <Typography variant="caption1">
                                    {'Add/Update Instructions'}
                                </Typography>
                            </Button>
                            <Modal
                                open={openInstructions}
                                onClose={() => {
                                    setOpenInstructions(false);
                                }}
                            >
                                <Stack sx={modalStyle}>
                                    <Typography variant="subtitle1">
                                        {'Add Instructions'}
                                    </Typography>
                                    <textarea
                                        placeholder={'Add Instructions'}
                                        value={form?.instructions || ''}
                                        onChange={handleTextChange}
                                        style={{
                                            width: '98.6%',
                                            maxWidth: '98.6%',
                                            padding: '5px',
                                            border: '1px solid #cecede',
                                            color: theme.palette.text
                                                .highEmphasis,
                                            fontFamily: 'Mier-Book',
                                            fontSize: '16px',
                                        }}
                                    />
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        justifyContent="flex-end"
                                        mt={2}
                                    >
                                        <Button
                                            onClick={() => {
                                                setOpenInstructions(false);
                                            }}
                                            variant="outlined"
                                        >
                                            <Typography variant="caption1">
                                                {'Cancel'}
                                            </Typography>
                                        </Button>
                                        <Button
                                            onClick={handleFormUpdate}
                                            variant="contained"
                                        >
                                            <Typography variant="caption1">
                                                {'Save'}
                                            </Typography>
                                        </Button>
                                    </Stack>
                                </Stack>
                            </Modal>
                        </Stack>
                    </Stack>
                    <Stack
                        sx={{
                            backgroundColor: '#F0F0F0',
                            height: '100%',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            padding: '10px',
                        }}
                    >
                        {loading ? (
                            <Stack
                                sx={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <CircularProgress />
                            </Stack>
                        ) : (
                            <>
                                <Stack
                                    sx={{
                                        border: '1px solid #DBDCE0',
                                        borderRadius: '10px',
                                        margin: '5px 10px',
                                        backgroundColor: 'white',
                                        width: '50vw',
                                        padding: '10px',
                                        gap: '10px',
                                    }}
                                >
                                    <Stack
                                        direction={'row'}
                                        sx={{ justifyContent: 'space-between' }}
                                    >
                                        <Typography variant="h6">
                                            Add New Question
                                        </Typography>
                                        <input
                                            accept=".csv"
                                            style={{ display: 'none' }}
                                            id="csv-upload"
                                            type="file"
                                            onChange={handleCSVUpload}
                                        />
                                        <label htmlFor="csv-upload">
                                            <Button
                                                component="span"
                                                variant="outlined"
                                            >
                                                Upload CSV
                                            </Button>
                                        </label>
                                    </Stack>
                                    <textarea
                                        placeholder={'Enter Question'}
                                        defaultValue={newQuestion.text}
                                        onChange={handleQuestionTextChange}
                                        style={{
                                            width: '98.6%',
                                            maxWidth: '98.6%',
                                            padding: '5px',
                                            border: '1px solid #cecede',
                                            color: theme.palette.text
                                                .highEmphasis,
                                            fontFamily: 'Mier-Book',
                                            fontSize: '16px',
                                        }}
                                    />
                                    <textarea
                                        placeholder={'Evidence Requirement'}
                                        defaultValue={
                                            newQuestion.evidenceRequirement
                                        }
                                        onChange={
                                            handleEvidenceRequirementChange
                                        }
                                        style={{
                                            width: '98.6%',
                                            maxWidth: '98.6%',
                                            padding: '5px',
                                            border: '1px solid #cecede',
                                            color: theme.palette.text
                                                .highEmphasis,
                                            fontFamily: 'Mier-Book',
                                            fontSize: '16px',
                                        }}
                                    />
                                    <TextField
                                        size="small"
                                        label="Domain"
                                        fullWidth
                                        value={newQuestion.domain}
                                        onChange={(e) =>
                                            setNewQuestion({
                                                ...newQuestion,
                                                domain: e.target.value,
                                            })
                                        }
                                    />
                                    <TextField
                                        size="small"
                                        select
                                        label="Answer Type"
                                        fullWidth
                                        value={newQuestion.answerType}
                                        onChange={(e) =>
                                            setNewQuestion({
                                                ...newQuestion,
                                                answerType: e.target
                                                    .value as QuestionDTO['answerType'],
                                            })
                                        }
                                    >
                                        {[
                                            'TEXT',
                                            'RADIO',
                                            'CHECKBOX',
                                            'FILE_UPLOAD',
                                            'COMPOSITE',
                                        ].map((option) => (
                                            <MenuItem
                                                key={option}
                                                value={option}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {(newQuestion.answerType === 'RADIO' ||
                                        newQuestion.answerType === 'CHECKBOX' ||
                                        newQuestion.answerType ===
                                            'COMPOSITE') && (
                                        <Stack spacing={2}>
                                            {newQuestion.options?.map(
                                                (option, index) => (
                                                    <Stack
                                                        key={index}
                                                        direction="row"
                                                        spacing={2}
                                                        alignItems="center"
                                                    >
                                                        <TextField
                                                            size="small"
                                                            label="Option Text"
                                                            value={option.text}
                                                            onChange={(e) =>
                                                                handleOptionChange(
                                                                    index,
                                                                    'text',
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            fullWidth
                                                        />
                                                        <TextField
                                                            size="small"
                                                            label="Value"
                                                            value={option.value}
                                                            onChange={(e) =>
                                                                handleOptionChange(
                                                                    index,
                                                                    'value',
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            fullWidth
                                                        />
                                                        <TextField
                                                            size="small"
                                                            label="Answer Value"
                                                            type="number"
                                                            value={
                                                                option.answerValue
                                                            }
                                                            onChange={(e) =>
                                                                handleOptionChange(
                                                                    index,
                                                                    'answerValue',
                                                                    parseInt(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                )
                                                            }
                                                            fullWidth
                                                        />
                                                        <IconButton
                                                            onClick={() =>
                                                                removeOption(
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Stack>
                                                )
                                            )}
                                            <Button
                                                onClick={addOption}
                                                startIcon={<AddIcon />}
                                            >
                                                Add Option
                                            </Button>
                                        </Stack>
                                    )}
                                    {newQuestion.answerType === 'COMPOSITE' && (
                                        <>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            newQuestion.noteAllowed
                                                        }
                                                        onChange={(e) =>
                                                            setNewQuestion({
                                                                ...newQuestion,
                                                                noteAllowed:
                                                                    e.target
                                                                        .checked,
                                                            })
                                                        }
                                                    />
                                                }
                                                label="Allow Notes"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            newQuestion.fileUploadAllowed
                                                        }
                                                        onChange={(e) =>
                                                            setNewQuestion({
                                                                ...newQuestion,
                                                                fileUploadAllowed:
                                                                    e.target
                                                                        .checked,
                                                            })
                                                        }
                                                    />
                                                }
                                                label="Allow File Upload"
                                            />
                                        </>
                                    )}
                                    <Divider />
                                    <StyledButton
                                        variant="outlined"
                                        onClick={handleAddQuestion}
                                    >
                                        <Typography variant="caption1">
                                            Add Question
                                        </Typography>
                                    </StyledButton>
                                </Stack>
                                <Stack spacing={'5px'} sx={{ width: '50vw' }}>
                                    {form !== null &&
                                        form?.questions.map((question) => (
                                            <Stack key={question.id}>
                                                <RenderQuestion
                                                    question={question}
                                                    handleEditQuestion={
                                                        handleEditQuestion
                                                    }
                                                    handleDeleteQuestion={
                                                        handleDeleteQuestion
                                                    }
                                                />
                                            </Stack>
                                        ))}
                                </Stack>
                            </>
                        )}
                    </Stack>
                    {editingQuestionId && (
                        <EditQuestionModal
                            question={
                                form!.questions.find(
                                    (q) => q.id === editingQuestionId
                                )!
                            }
                            onSave={handleUpdateQuestion}
                            onClose={handleCloseModal}
                        />
                    )}
                    <CustomSnackbar
                        open={openSnackBar}
                        message={message}
                        success={success}
                        onClose={handleClose}
                        autoHideDuration={1800}
                    />
                </Stack>
            }
        />
    );
};

export default GenerateFormPage;
