import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Button from '../../atoms/Button';
import { Stack, Typography } from '@mui/material';
import { DecodedJWT, DropdownMenuItemProps } from '../../../utils/types';
import { jwtDecode } from 'jwt-decode';
import theme from '../../../theme';

interface DropdownMenuProps {
    iconButtonContent: React.ReactNode;
    menuItems: DropdownMenuItemProps[];
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    variant?: 'text' | 'outlined' | 'contained';
    style?: React.CSSProperties;
    showUserData?: boolean;
    sxStyle?: any;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
    iconButtonContent,
    menuItems,
    startIcon,
    endIcon,
    variant,
    style,
    showUserData,
    sxStyle,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    let token;
    if (localStorage.getItem('token')) {
        token = localStorage.getItem('token');
    }
    const { name, sub } = jwtDecode<DecodedJWT>(token);

    return (
        <Box>
            <Box>
                <Button
                    variant={variant}
                    onClick={handleClick}
                    size="small"
                    sx={{
                        padding: 0,
                        margin: 0,
                        '&:hover': {
                            backgroundColor: 'inherit',
                        },
                    }}
                    startIcon={startIcon}
                    endIcon={endIcon}
                    disableRipple
                    style={style}
                >
                    {iconButtonContent}
                </Button>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                sx={sxStyle}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {showUserData && (
                    <Stack
                        sx={{
                            backgroundColor: '#024CAA',
                            padding: '5px 15px',
                        }}
                    >
                        <Typography
                            variant="caption1"
                            color={theme.palette.structuralColors.white}
                        >
                            {'Name: ' + name}
                        </Typography>
                        <Typography
                            variant="caption1"
                            color={theme.palette.structuralColors.white}
                        >
                            {'Email: ' + sub}
                        </Typography>
                    </Stack>
                )}
                {menuItems.map((item, index) => (
                    <div key={index}>
                        <MenuItem onClick={item.onClick || handleClose}>
                            {item.icon && (
                                <ListItemIcon>{item.icon}</ListItemIcon>
                            )}
                            <Typography variant="caption1">
                                {item.label}
                            </Typography>
                        </MenuItem>
                        {index < menuItems.length - 1 && <Divider />}
                    </div>
                ))}
            </Menu>
        </Box>
    );
};

export default DropdownMenu;
