import { Snackbar, Alert } from '@mui/material';

interface CustomSnackbarProps {
    open: boolean;
    message: string;
    success: boolean;
    onClose: () => void;
    autoHideDuration?: number;
}

const CustomSnackbar = ({
    open,
    message,
    success,
    onClose,
    autoHideDuration = 2500,
}: CustomSnackbarProps) => {
    return (
        <Snackbar
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            <Alert
                onClose={onClose}
                severity={success ? 'success' : 'error'}
                sx={{ width: '100%' }}
            >
                {message}
            </Alert>
        </Snackbar>
    );
};

export default CustomSnackbar;
