import { MenuItem, Stack, TextField } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import Typography from '../../atoms/Typography';
import Button from '../../atoms/Button';
import { updateRegistryProperties } from '../../../services/api';
import useSnackBar from '../../../utils/hooks';
import CustomSnackbar from '../../molecules/CustomSnackbar';
import { RegistryData } from '../../../utils/types';

const RegistryStatusValues = [
    {
        id: 1,
        option: 'Planned',
        value: 'PLANNED',
    },
    {
        id: 2,
        option: 'In Progress',
        value: 'IN_PROGRESS',
    },
    {
        id: 3,
        option: 'Completed',
        value: 'COMPLETED',
    },
];

interface RegistryPropertiesTabProps {
    registryId: string;
    userRole: string;
    fetchData: () => void;
    resgistryData: RegistryData;
}

const RegistryPropertiesTab = (props: RegistryPropertiesTabProps) => {
    const [registryProperties, setRegistryProperties] = useState({
        registryStatus: '',
        team: '',
        dataClassification: '',
    });
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const { openSnackBar, handleClick, handleClose } = useSnackBar();
    const [isDisabledByRole, setIsDisabledByRole] = useState(true);

    useEffect(() => {
        if (props.userRole === 'ADMIN') {
            setIsDisabledByRole(false);
        }
        setRegistryProperties({
            registryStatus: props.resgistryData.registryStatus || '',
            team: props.resgistryData.team || '',
            dataClassification: props.resgistryData.dataClassification || '',
        });
    }, [props.resgistryData]);

    const handleChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setRegistryProperties((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        const data = {
            id: props.registryId,
            ...registryProperties,
        };
        const response = await updateRegistryProperties(data);
        if (response) {
            setMessage('Properties updated successfully');
            handleClick();
            setSuccess(true);
            props.fetchData();
        } else {
            setMessage('Failed to update properties. Please try again.');
            handleClick();
            setSuccess(false);
        }
    };

    return (
        <Stack sx={{ height: '100%', padding: 2 }}>
            <Stack spacing={'20px'} sx={{ padding: '15px 10px' }}>
                <TextField
                    size="small"
                    select
                    label="Status"
                    name="registryStatus"
                    value={registryProperties.registryStatus}
                    onChange={handleChange}
                    disabled={isDisabledByRole}
                    fullWidth
                >
                    {RegistryStatusValues.map((data) => (
                        <MenuItem key={data.id} value={data.value}>
                            <Typography
                                variant="body1"
                                sx={{ textTransform: 'capitalize' }}
                            >
                                {data.option}
                            </Typography>
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    size="small"
                    label="Team"
                    name="team"
                    value={registryProperties.team}
                    onChange={handleChange}
                    disabled={isDisabledByRole}
                    fullWidth
                />
                <TextField
                    size="small"
                    label="Data Classification"
                    name="dataClassification"
                    value={registryProperties.dataClassification}
                    onChange={handleChange}
                    disabled={isDisabledByRole}
                    fullWidth
                />
                {props.userRole === 'ADMIN' ? (
                    <Button
                        variant="contained"
                        disableRipple
                        onClick={handleSubmit}
                    >
                        <Typography variant="caption1">{'Save'}</Typography>
                    </Button>
                ) : (
                    <></>
                )}
            </Stack>
            <CustomSnackbar
                open={openSnackBar}
                message={message}
                success={success}
                onClose={handleClose}
                autoHideDuration={1800}
            />
        </Stack>
    );
};

export default RegistryPropertiesTab;
