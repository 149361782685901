import { Box, Stack, styled } from '@mui/material';
import Logo from '../../../../public/assets/icons/logoPlain.png';
import theme from '../../../theme';
import Icon from '../../atoms/Icon';

interface LandingTemplateProps {
    main: React.ReactNode;
}

const StyledStack = styled(Stack)({
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.structuralColors.white,
});

const LogoStyle = {
    width: '200px',
    height: '200px',
};

const AuthTemplate = (props: LandingTemplateProps) => {
    return (
        <Stack
            direction={'column'}
            sx={{ width: '100%', height: '100vh' }}
            data-testid="gridItems"
            position={'relative'}
        >
            <Box
                width={'100%'}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Icon src={Logo} style={LogoStyle} alt={'Logo'} />
            </Box>
            <StyledStack>{props.main}</StyledStack>
        </Stack>
    );
};

export default AuthTemplate;
