import { Box, IconButton, Link, Stack, styled } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { getAllUserApproval, updateApproval } from '../../../services/api';
import Typography from '../../atoms/Typography';
import Button from '../../atoms/Button';
import ClientSideDataTable from '../ClientSideDataTable';
import theme from '../../../theme';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { StyledGridOverlay } from '../../../utils/helper';
import SyncIcon from '@mui/icons-material/Sync';
import useSnackBar from '../../../utils/hooks';
import GenericModal from '../GenericModal';
import CloseIcon from '@mui/icons-material/Close';
import './index.css';
import ConfirmationModal from '../../molecules/ConfirmationModal';
import { validateTextareaFormat } from '../../../utils/validations';
import CustomSnackbar from '../../molecules/CustomSnackbar';

const Container = styled(Stack)({
    height: '100%',
    padding: '10px',
    justifyContent: 'space-between',
    flexDirection: 'column',
});

const StyledButton = styled(Button)({
    padding: '5px 8px',
});

interface ApproveTabProps {
    registryId: string;
}

const ApproveTab = (props: ApproveTabProps) => {
    const registryId = props.registryId;
    const [documentData, setDocumentData] = useState([]);
    const [fetchNewData, setFetchNewData] = useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const { openSnackBar, handleClick, handleClose } = useSnackBar();
    const [modalOpen, setModalOpen] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [approvalId, setApprovalId] = useState('');
    const [textareaData, setTextareaData] = useState({
        changes: '',
    });
    const [errors, setErrors] = useState({
        changes: '',
    });
    const [wordCount, setWordCount] = useState({
        changesWordCount: 0,
    });

    const fetchData = async () => {
        const data = await getAllUserApproval({
            registryId: registryId,
        });
        if (data) {
            setDocumentData(data);
        } else {
            setDocumentData([]);
        }
    };

    useEffect(() => {
        fetchData();
    }, [fetchNewData]);

    const handleConfirmationModal = useCallback(() => {
        setConfirmationModalOpen((prev) => !prev);
    }, [setConfirmationModalOpen]);

    const handleModalOpen = useCallback(
        () => setModalOpen(true),
        [setModalOpen]
    );

    const handleModalClose = () => setModalOpen(false);

    const handleApprove = async () => {
        const response = await updateApproval({
            approvalId: approvalId,
            action: 'APPROVED',
        });
        if (response) {
            setMessage('Approval submited successfully');
            handleClick();
            setSuccess(true);
        } else {
            setMessage('Failed to submit approval. Please try again.');
            handleClick();
            setSuccess(false);
        }
        setFetchNewData(!fetchNewData);
    };

    const handleRequestChanges = async () => {
        const response = await updateApproval({
            approvalId: approvalId,
            action: 'REQUESTED_CHANGES',
            comment: textareaData.changes,
        });
        if (response) {
            setMessage('Requested Changes submited successfully');
            handleClick();
            setSuccess(true);
        } else {
            setMessage('Failed to submit the request. Please try again.');
            handleClick();
            setSuccess(false);
        }
        handleModalClose();
        setTextareaData({
            changes: '',
        });
        setFetchNewData(!fetchNewData);
    };

    const handleTextareaChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        fieldName: string
    ) => {
        const { value } = event.target;

        setTextareaData((prevState) => ({
            ...prevState,
            [fieldName]: value,
        }));

        const charCount = value.length;

        if ((value && !validateTextareaFormat(value)) || charCount > 2000) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [fieldName]: 'Invalid Input',
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [fieldName]: '',
            }));
        }

        setWordCount((prevState) => ({
            ...prevState,
            [`${fieldName}WordCount`]: charCount,
        }));
    };

    const getApprovalColumns = useMemo<GridColDef[]>(
        () => [
            {
                field: 'documents',
                headerName: 'Associated Documents',
                flex: 3.5,
                renderCell: (params: GridRenderCellParams) => {
                    return (
                        <Stack
                            sx={{ justifyContent: 'center', height: '100%' }}
                        >
                            {params.value.map((docs) => (
                                <Stack key={docs.id}>
                                    {docs.type === 'LINK' ? (
                                        <Typography variant="body1">
                                            <Link href={docs.url}>
                                                {docs.title}
                                            </Link>
                                        </Typography>
                                    ) : (
                                        <Typography variant="body1">
                                            {docs.title}
                                        </Typography>
                                    )}
                                </Stack>
                            ))}
                        </Stack>
                    );
                },
            },
            {
                field: 'currentLevel',
                headerName: 'Current Level',
                flex: 2,
                renderCell: (params: GridRenderCellParams) => {
                    return (
                        <Stack
                            sx={{ justifyContent: 'center', height: '100%' }}
                        >
                            <Typography
                                variant="body2"
                                color={theme.palette.text.highEmphasis}
                            >
                                {params.value}
                            </Typography>
                        </Stack>
                    );
                },
            },
            {
                field: 'userStatus',
                headerName: 'Approval Status',
                flex: 2,
                renderCell: (params: GridRenderCellParams) => {
                    const formattedStatus = params.value
                        .split('_')
                        .map(
                            (word: string) =>
                                word.charAt(0) + word.slice(1).toLowerCase()
                        )
                        .join(' ');
                    return (
                        <Stack
                            sx={{ justifyContent: 'center', height: '100%' }}
                        >
                            <Typography
                                variant="body2"
                                color={theme.palette.text.highEmphasis}
                            >
                                {formattedStatus}
                            </Typography>
                        </Stack>
                    );
                },
            },
            {
                field: 'actions',
                headerName: 'Action',
                type: 'actions',
                flex: 2.5,
                getActions: (params) => [
                    <StyledButton
                        key={params.id}
                        onClick={() => {
                            handleConfirmationModal();
                            setApprovalId(params.row.id);
                        }}
                        variant="outlined"
                        disableRipple
                        sx={{ marginRight: '5px' }}
                        disabled={params.row.userStatus === 'REQUESTED_CHANGES'}
                    >
                        <Typography variant="caption1">{'Approve'}</Typography>
                    </StyledButton>,
                    <StyledButton
                        key={params.id}
                        onClick={() => {
                            handleModalOpen();
                            setApprovalId(params.row.id);
                        }}
                        variant="outlined"
                        disableRipple
                        disabled={params.row.userStatus === 'REQUESTED_CHANGES'}
                    >
                        <Typography variant="caption1">
                            {'Request Changes'}
                        </Typography>
                    </StyledButton>,
                ],
            },
        ],
        []
    );

    function CustomNoRowsOverlay() {
        return (
            <StyledGridOverlay>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    width={96}
                    viewBox="0 0 452 257"
                    aria-hidden
                    focusable="false"
                >
                    <path
                        className="no-rows-primary"
                        d="M348 69c-46.392 0-84 37.608-84 84s37.608 84 84 84 84-37.608 84-84-37.608-84-84-84Zm-104 84c0-57.438 46.562-104 104-104s104 46.562 104 104-46.562 104-104 104-104-46.562-104-104Z"
                    />
                    <path
                        className="no-rows-primary"
                        d="M308.929 113.929c3.905-3.905 10.237-3.905 14.142 0l63.64 63.64c3.905 3.905 3.905 10.236 0 14.142-3.906 3.905-10.237 3.905-14.142 0l-63.64-63.64c-3.905-3.905-3.905-10.237 0-14.142Z"
                    />
                    <path
                        className="no-rows-primary"
                        d="M308.929 191.711c-3.905-3.906-3.905-10.237 0-14.142l63.64-63.64c3.905-3.905 10.236-3.905 14.142 0 3.905 3.905 3.905 10.237 0 14.142l-63.64 63.64c-3.905 3.905-10.237 3.905-14.142 0Z"
                    />
                    <path
                        className="no-rows-secondary"
                        d="M0 10C0 4.477 4.477 0 10 0h380c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 20 0 15.523 0 10ZM0 59c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 69 0 64.523 0 59ZM0 106c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 153c0-5.523 4.477-10 10-10h195.5c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 200c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 247c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10Z"
                    />
                </svg>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="body1">
                        {'No Pending Approvals'}
                    </Typography>
                </Box>
            </StyledGridOverlay>
        );
    }

    return (
        <Container>
            <Stack
                direction={'row'}
                sx={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h3">{'Approval Requests'}</Typography>
                <StyledButton
                    onClick={() => fetchData()}
                    startIcon={<SyncIcon />}
                    variant="contained"
                    disableRipple
                >
                    <Typography variant="caption1">{'Refresh'}</Typography>
                </StyledButton>
            </Stack>
            <Stack
                direction={'row'}
                sx={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    marginTop: '1rem',
                }}
            >
                <ClientSideDataTable
                    columns={getApprovalColumns}
                    rowsData={documentData}
                    isLoading={false}
                    checkboxSelection={false}
                    hideFooter={false}
                    slots={{
                        noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    slotProps={{
                        loadingOverlay: {
                            variant: 'skeleton',
                            noRowsVariant: 'skeleton',
                        },
                    }}
                    resizeThrottleMs={190}
                />
            </Stack>
            <CustomSnackbar
                open={openSnackBar}
                message={message}
                success={success}
                onClose={handleClose}
                autoHideDuration={2500}
            />
            <GenericModal
                open={modalOpen}
                style={{
                    backgroundColor: theme.palette.structuralColors.white,
                    padding: '10px',
                    borderRadius: '5px',
                    width: '25vw',
                }}
            >
                <Stack spacing={'10px'}>
                    <Stack
                        sx={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="subtitle1">
                            {'Changes Comment'}
                        </Typography>
                        <IconButton
                            onClick={handleModalClose}
                            sx={{ padding: 0 }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <div>
                        <textarea
                            value={textareaData.changes}
                            onChange={(e) => handleTextareaChange(e, 'changes')}
                            style={{
                                color: theme.palette.text.mediumEmphasis,
                                fontFamily: 'Mier-Book',
                                fontSize: '16px',
                                padding: '5px',
                                width: '97.5%',
                                border: `1px solid ${theme.palette.structuralColors.stroke1}`,
                            }}
                        />
                        <Stack
                            direction={'row'}
                            sx={{
                                width: '100%',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    width: '30%',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                {errors.changes && (
                                    <Typography
                                        variant="caption2"
                                        color={'red'}
                                    >
                                        {errors.changes}
                                    </Typography>
                                )}
                            </Box>
                            <Stack
                                sx={{
                                    display: 'flex',
                                    width: '70%',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                }}
                            >
                                <Typography
                                    variant="caption2"
                                    color={theme.palette.text.mediumEmphasis}
                                >
                                    {'Minimum Character: 50'}
                                </Typography>
                                <Typography
                                    variant="caption2"
                                    color={theme.palette.text.mediumEmphasis}
                                >
                                    {'WordCount: ' +
                                        wordCount.changesWordCount +
                                        '/2000'}
                                </Typography>
                            </Stack>
                        </Stack>
                    </div>
                    <StyledButton
                        variant="outlined"
                        sx={{ width: '160px' }}
                        onClick={() => handleRequestChanges()}
                        disabled={
                            wordCount.changesWordCount > 50 ? false : true
                        }
                    >
                        <Typography variant="caption1">{'Submit'}</Typography>
                    </StyledButton>
                </Stack>
            </GenericModal>
            <ConfirmationModal
                open={confirmationModalOpen}
                onClose={handleConfirmationModal}
                onConfirm={() => handleApprove()}
                onCancel={handleConfirmationModal}
                title={'Are you sure you want to approve the documents?'}
                confirmText={'Approve'}
                cancelText={'Cancel'}
            />
        </Container>
    );
};

export default ApproveTab;
