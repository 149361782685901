import React from 'react';
import { Outlet } from 'react-router-dom';
import { AuthContextProvider } from '../context/authContext';

export const InternalAuthLayout = () => {
    return (
        <AuthContextProvider>
            <Outlet />
        </AuthContextProvider>
    );
};
