import React, { useState } from 'react';
import { IconButton, Stack, styled, TextField } from '@mui/material';
import Typography from '../../atoms/Typography';
import Icon from '../../atoms/Icon';
import theme from '../../../theme';
import BackIcon from '../../../../public/assets/icons/back.svg';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import {
    manageRiskRegister,
    updateRegistryProperties,
    updateVendorProperties,
} from '../../../services/api';
import CustomSnackbar from '../CustomSnackbar';
import useSnackBar from '../../../utils/hooks';

const Container = styled(Stack)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.structuralColors.blackgray,
    padding: '4px 20px',
});

interface DocumentHeaderProps {
    backButtonClick: () => void;
    labelText: string;
    id: string;
    fetchData: () => void;
    userRole: string;
    type: string;
}

const DocumentHeader = (props: DocumentHeaderProps) => {
    const [isEditing, setIsEditing] = useState(false);
    const [name, setName] = useState(props.labelText);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const { openSnackBar, handleClick, handleClose } = useSnackBar();

    const handleSave = async () => {
        if (name.trim().length < 2) {
            setError('Name must be at least 2 characters long');
            return;
        }
        setError('');
        setIsEditing(false);

        const data = {
            id: props.id,
            name: name.trim(),
        };
        let response;
        if (props.type === 'REGISTRY') {
            response = await updateRegistryProperties(data);
        } else if (props.type === 'VENDOR') {
            response = await updateVendorProperties(data);
        } else if (props.type === 'RISKREGISTER') {
            response = await manageRiskRegister({
                id: props.id,
                name: name.trim(),
                type: 'MODIFY',
            });
        }
        if (response) {
            setMessage('Name updated successfully');
            handleClick();
            setSuccess(true);
            props.fetchData();
        } else {
            setMessage('Failed to update name. Please try again.');
            handleClick();
            setSuccess(false);
        }
    };

    return (
        <Container>
            <Stack
                sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <IconButton onClick={props.backButtonClick}>
                    <Icon src={BackIcon}></Icon>
                </IconButton>
                <Stack
                    sx={{
                        paddingTop: '5px',
                        marginLeft: '8px',
                        width: '100%',
                    }}
                >
                    {isEditing ? (
                        <TextField
                            size="small"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            error={!!error}
                            helperText={error}
                            sx={{
                                backgroundColor:
                                    theme.palette.structuralColors.lightGray,
                                borderRadius: '4px',
                            }}
                        />
                    ) : (
                        <Typography
                            variant={'h3'}
                            color={theme.palette.structuralColors.white}
                        >
                            {props.labelText}
                        </Typography>
                    )}
                </Stack>
            </Stack>
            {props.userRole === 'ADMIN' ? (
                <Stack direction="row" spacing={1}>
                    {isEditing ? (
                        <IconButton onClick={handleSave}>
                            <CheckIcon sx={{ color: 'white' }} />
                        </IconButton>
                    ) : (
                        <IconButton onClick={() => setIsEditing(true)}>
                            <EditIcon sx={{ color: 'white' }} />
                        </IconButton>
                    )}
                </Stack>
            ) : (
                <></>
            )}
            <CustomSnackbar
                open={openSnackBar}
                message={message}
                success={success}
                onClose={handleClose}
                autoHideDuration={1800}
            />
        </Container>
    );
};

export default DocumentHeader;
