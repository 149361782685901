import React, { useEffect, useState } from 'react';
import { Avatar, Stack, styled } from '@mui/material';
import Typography from '../../atoms/Typography';
import theme from '../../../theme';
import DropdownMenu from '../DropdownMenu';
import { useAuth } from '../../../context/authContext';
import { jwtDecode } from 'jwt-decode';
import { DecodedJWT } from '../../../utils/types';

const Container = styled(Stack)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: `linear-gradient(0.25turn,#05bdcd, #7c2be8)`,
    padding: '4px 20px',
});

const AppHeader = () => {
    const { logout } = useAuth();

    const [profilePicture, setProfilePicture] = useState<string | null>(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const { picture } = jwtDecode<DecodedJWT>(token);
            const cachedPicture = localStorage.getItem('profilePictureBase64');

            if (cachedPicture) {
                // Use cached base64 image if available
                setProfilePicture(cachedPicture);
            } else {
                // Fetch and encode image as base64
                fetch(picture)
                    .then((response) => response.blob())
                    .then((blob) => {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            const base64data = reader.result as string;
                            localStorage.setItem(
                                'profilePictureBase64',
                                base64data
                            );
                            setProfilePicture(base64data);
                        };
                        reader.readAsDataURL(blob);
                    })
                    .catch((error) => {
                        console.error('Error fetching profile picture:', error);
                    });
            }
        }
    }, []);

    const UserProfileOption = [
        {
            id: 1,
            label: <Stack sx={{ padding: '3px 0' }}>{'Logout'}</Stack>,
            onClick: () => logout(),
        },
    ];

    return (
        <Container>
            <Typography
                variant={'h2'}
                color={theme.palette.structuralColors.white}
            >
                {'CompliSys'}
            </Typography>
            <Stack
                direction={'row'}
                spacing={'10px'}
                style={{ alignItems: 'center' }}
            >
                <DropdownMenu
                    iconButtonContent={<Avatar src={profilePicture || ''} />}
                    menuItems={UserProfileOption}
                    showUserData={true}
                    sxStyle={{ '& .MuiList-root': { padding: '0' } }}
                />
            </Stack>
        </Container>
    );
};

export default AppHeader;
