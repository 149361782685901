import * as React from 'react';
import { Box, Button, Stack, styled, Tab, Tabs } from '@mui/material';
import Typography from '../../atoms/Typography';
import theme from '../../../theme';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    style?: React.CSSProperties;
    showHeader?: boolean;
    labelText?: string;
}

interface DocumentDetailProps {
    horizontalTabs: {
        label?: string;
        icon?: React.ReactElement;
        content: React.ReactNode;
    }[];
    verticalTabs: {
        label?: string;
        icon?: React.ReactElement;
        content: React.ReactNode;
    }[];
    buttons?: { label: string; onClick: () => void }[];
    hiddenHorizontalTabs?: string[];
    hiddenVerticalTabs?: string[];
    initialVerticalTab?: number;
    verticalTabValue?: number;
    onVerticalTabChange?: (newValue: number) => void;
}

const VerticalTabsContainer = styled(Box)({
    flexGrow: 1,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row-reverse',
    height: '100%',
    borderRadius: '5px',
});

const HorizontalTabsContainer = styled(Stack)({
    width: '75%',
    backgroundColor: 'white',
    borderRadius: '5px',
});

const VerticalTabStyle = styled(Tab)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '50px',
    minWidth: '40px',
});

const HorizontalTabStyle = styled(Tab)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '55px',
});

const MainContainer = styled(Stack)({
    width: '100%',
    flexDirection: 'row',
    backgroundColor: 'lightgrey',
    justifyContent: 'space-evenly',
    padding: '5px 0px',
    height: '100%',
});

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, style, showHeader, labelText, ...other } =
        props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={style}
            {...other}
        >
            {value === index && (
                <>
                    {showHeader && (
                        <Stack
                            sx={{
                                padding: '12.5px 10px',
                                borderBottom: 1,
                                borderColor: 'divider',
                            }}
                        >
                            <Typography
                                variant="subtitle1"
                                color={theme.palette.text.mediumEmphasis}
                            >
                                {labelText}
                            </Typography>
                        </Stack>
                    )}
                    <Box sx={{ padding: '3px' }}>{children}</Box>
                </>
            )}
        </div>
    );
}

function a11yProps(index: number, orientation: 'horizontal' | 'vertical') {
    return {
        id: `simple-tab-${orientation}-${index}`,
        'aria-controls': `simple-tabpanel-${orientation}-${index}`,
    };
}

const VerticalTabs: React.FC<{
    tabs: {
        label?: string;
        icon?: React.ReactElement;
        content: React.ReactNode;
    }[];
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
}> = ({ tabs, value, onChange }) => {
    return (
        <VerticalTabsContainer>
            <Tabs
                orientation="vertical"
                value={value}
                onChange={onChange}
                aria-label="Vertical tabs example"
                sx={{
                    borderLeft: 1,
                    borderColor: 'divider',
                    display: 'flex',
                }}
            >
                {tabs.map((tab, index) => (
                    <VerticalTabStyle
                        key={index}
                        icon={tab.icon}
                        {...a11yProps(index, 'vertical')}
                    />
                ))}
            </Tabs>
            {tabs.map((tab, index) => (
                <CustomTabPanel
                    key={index}
                    value={value}
                    index={index}
                    labelText={tab.label}
                    showHeader={true}
                    style={{ width: '100%' }}
                >
                    {tab.content}
                </CustomTabPanel>
            ))}
        </VerticalTabsContainer>
    );
};

const DocumentDetail: React.FC<DocumentDetailProps> = ({
    horizontalTabs,
    verticalTabs,
    buttons,
    hiddenHorizontalTabs = [],
    hiddenVerticalTabs = [],
    verticalTabValue = 0,
    onVerticalTabChange,
}) => {
    const [horizontalValue, setHorizontalValue] = React.useState(0);

    const handleHorizontalChange = (
        event: React.SyntheticEvent,
        newValue: number
    ) => {
        setHorizontalValue(newValue);
    };

    const handleVerticalChange = (
        event: React.SyntheticEvent,
        newValue: number
    ) => {
        if (onVerticalTabChange) {
            onVerticalTabChange(newValue);
        }
    };

    const visibleHorizontalTabs = horizontalTabs.filter(
        (tab) => !hiddenHorizontalTabs.includes(tab.label || '')
    );

    const visibleVerticalTabs = verticalTabs.filter(
        (tab) => !hiddenVerticalTabs.includes(tab.label || '')
    );

    return (
        <MainContainer>
            <HorizontalTabsContainer>
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Tabs
                        value={horizontalValue}
                        onChange={handleHorizontalChange}
                        aria-label="horizontal tabs example"
                    >
                        {visibleHorizontalTabs.map((tab, index) => (
                            <HorizontalTabStyle
                                key={index}
                                icon={tab.icon}
                                label={tab.label}
                                {...a11yProps(index, 'horizontal')}
                                sx={{
                                    textTransform: 'none',
                                    fontSize: '16px',
                                }}
                            />
                        ))}
                    </Tabs>
                    <Stack
                        direction={'row'}
                        spacing={'10px'}
                        sx={{ marginRight: '10px' }}
                    >
                        {buttons &&
                            buttons.map((button, index) => (
                                <Button
                                    key={index}
                                    onClick={button.onClick}
                                    variant="outlined"
                                >
                                    {button.label}
                                </Button>
                            ))}
                    </Stack>
                </Box>
                <Stack>
                    {visibleHorizontalTabs.map((tab, index) => (
                        <CustomTabPanel
                            key={index}
                            value={horizontalValue}
                            index={index}
                        >
                            {tab.content}
                        </CustomTabPanel>
                    ))}
                </Stack>
            </HorizontalTabsContainer>
            <Stack sx={{ width: '24%' }}>
                <VerticalTabs
                    tabs={visibleVerticalTabs}
                    value={verticalTabValue} // Use controlled value
                    onChange={handleVerticalChange} // Handle changes
                />
            </Stack>
        </MainContainer>
    );
};

export default DocumentDetail;
