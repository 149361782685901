import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    CircularProgress,
    Divider,
    IconButton,
    LinearProgress,
    Stack,
} from '@mui/material';
import {
    evaluateAnswers,
    getAnswersForFormSubmission,
    getForm,
    getFormSubmission,
    submitAnswer,
    submitFormSubmission,
} from '../../services/api';
import { FormSubmission, Form, Answer } from '../../utils/types';
import Typography from '../../components/atoms/Typography';
import Button from '../../components/atoms/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Icon from '../../components/atoms/Icon';
import Logo from '../../../public/assets/icons/logoPlain.png';
import theme from '../../theme';
import QuestionComponent from './QuestionComponent';
import { jwtDecode } from 'jwt-decode';
import CustomSnackbar from '../../components/molecules/CustomSnackbar';
import useSnackBar from '../../utils/hooks';

interface DecodedJWT {
    role: string;
    userId: string;
}

const POLLING_INTERVAL = 45000; // Poll every 45 seconds

const FormSubmissionComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const formSubmissionId = queryParams.get('id');

    const [formSubmission, setFormSubmission] = useState<FormSubmission | null>(
        null
    );
    const [form, setForm] = useState<Form | null>(null);
    const [answers, setAnswers] = useState<{ [questionId: string]: Answer }>(
        {}
    );
    const [loading, setLoading] = useState(true);
    const [totalAnswered, setTotalAnswered] = useState(0);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const { openSnackBar, handleClick, handleClose } = useSnackBar();
    const [visibleTime, setVisibleTime] = useState(1800);

    let token;
    if (localStorage.getItem('token')) {
        token = localStorage.getItem('token');
    } else if (localStorage.getItem('accessToken')) {
        token = localStorage.getItem('accessToken');
    }
    const { role } = jwtDecode<DecodedJWT>(token);

    const fetchData = async () => {
        const response = await getFormSubmission(formSubmissionId);

        if (response.message === 'success') {
            setFormSubmission(response);

            const formData = await getForm(response.formId);
            setForm(formData);

            // Fetch existing answers
            const existingAnswers =
                await getAnswersForFormSubmission(formSubmissionId);

            // Initialize answers with existing data
            const initialAnswers: { [questionId: string]: Answer } = {};
            let totalAnsweredQuestions = 0;
            formData.questions.forEach((question) => {
                const existingAnswer = existingAnswers.find(
                    (a) => a.questionId === question.id
                );
                initialAnswers[question.id] = existingAnswer || {
                    formSubmissionId,
                    questionId: question.id,
                    answered: false,
                    textAnswer: '',
                    selectedOptions: [],
                    note: '',
                    documentIds: [],
                    documents: [],
                    internalReviewStatus: '',
                    internalNotes: '',
                    riskValue: '',
                    comments: [],
                    commentStatus: '',
                    totalAnswerValue: 0,
                };
                if (
                    initialAnswers[question.id].totalAnswerValue >
                    totalAnsweredQuestions
                ) {
                    totalAnsweredQuestions =
                        initialAnswers[question.id].totalAnswerValue;
                }
                setTotalAnswered(totalAnsweredQuestions);
            });
            setAnswers(initialAnswers);
        } else {
            if (response.message === 'Overdue') {
                setVisibleTime(1800);
                setMessage(
                    'The due date for this form has passed. Please contact the administrator.'
                );
                handleClick();
                setSuccess(false);
            } else if (response.message === 'Unauthorised') {
                setVisibleTime(1800);
                setMessage('Unauthorised to access the form.');
                handleClick();
                setSuccess(false);
            }
        }

        setLoading(false);
    };

    const fetchAnswers = async () => {
        try {
            // Fetch existing answers
            const existingAnswers =
                await getAnswersForFormSubmission(formSubmissionId);

            // Initialize answers with existing data
            const initialAnswers: { [questionId: string]: Answer } = {};
            let totalAnsweredQuestions = 0;
            form.questions.forEach((question) => {
                const existingAnswer = existingAnswers.find(
                    (a) => a.questionId === question.id
                );
                initialAnswers[question.id] = existingAnswer || {
                    formSubmissionId,
                    questionId: question.id,
                    answered: false,
                    textAnswer: '',
                    selectedOptions: [],
                    note: '',
                    documentIds: [],
                    documents: [],
                    internalReviewStatus: '',
                    internalNotes: '',
                    riskValue: '',
                    comments: [],
                    commentStatus: '',
                    totalAnswerValue: 0,
                };
                if (
                    initialAnswers[question.id].totalAnswerValue >
                    totalAnsweredQuestions
                ) {
                    totalAnsweredQuestions =
                        initialAnswers[question.id].totalAnswerValue;
                }
                setTotalAnswered(totalAnsweredQuestions);
            });
            setAnswers(initialAnswers);
        } catch (error) {
            console.error('Error fetching answers:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchData();
        }, POLLING_INTERVAL);

        return () => clearInterval(intervalId);
    }, []);

    const handleAnswerChange = async (
        questionId: string,
        updatedAnswer: Partial<Answer>
    ) => {
        const currentAnswer = answers[questionId];

        let newAnswer: Answer = {
            ...currentAnswer,
            answered: true,
        };

        if (updatedAnswer.textAnswer !== undefined) {
            newAnswer.textAnswer = updatedAnswer.textAnswer;
        }

        if (updatedAnswer.note !== undefined) {
            newAnswer.note = updatedAnswer.note;
        }

        if (updatedAnswer.selectedOptions !== undefined) {
            newAnswer.selectedOptions = updatedAnswer.selectedOptions;
        }

        if (updatedAnswer.documentIds && updatedAnswer.documentIds.length > 0) {
            newAnswer.documentIds = updatedAnswer.documentIds;
        } else {
            newAnswer.documentIds = [];
        }

        if (updatedAnswer.internalNotes !== undefined) {
            newAnswer.internalNotes = updatedAnswer.internalNotes;
        }

        if (updatedAnswer.internalReviewStatus !== undefined) {
            newAnswer.internalReviewStatus = updatedAnswer.internalReviewStatus;
        }

        if (updatedAnswer.riskValue !== undefined) {
            newAnswer.riskValue = updatedAnswer.riskValue;
        }

        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionId]: newAnswer,
        }));

        try {
            if (
                updatedAnswer.internalNotes !== undefined ||
                updatedAnswer.internalReviewStatus !== undefined ||
                updatedAnswer.riskValue !== undefined
            ) {
                await evaluateAnswers(newAnswer);
                setVisibleTime(400);
                setMessage('Answer saved');
                handleClick();
                setSuccess(true);
            } else {
                await submitAnswer(newAnswer);
                setVisibleTime(400);
                setMessage('Answer saved');
                handleClick();
                setSuccess(true);
            }

            fetchAnswers();
        } catch (error) {
            console.error('Error submitting answer:', error);
        }
    };

    const handleSubmitForm = async (status: string) => {
        const response = await submitFormSubmission(formSubmissionId, status);
        if (response && response.message === 'success') {
            setVisibleTime(1800);
            setMessage('Form Submitted Successfully.');
            handleClick();
            setSuccess(true);
        } else {
            setVisibleTime(1800);
            setMessage(`Error submitting form. ${response.message}`);
            handleClick();
            setSuccess(false);
        }
    };

    const answered = totalAnswered;
    const total = form?.questions.length || 1;
    const progress = (answered / total) * 100;

    const date = new Date(formSubmission?.dueDate || '11-11-1111');
    const formattedDate = date.toISOString().split('T')[0];

    return (
        <>
            {loading || !form || !formSubmission ? (
                <Stack
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        width: '100%',
                    }}
                >
                    <CircularProgress />
                </Stack>
            ) : (
                <Stack sx={{ height: '100vh' }}>
                    <Stack sx={{ padding: '10px' }}>
                        <Stack direction={'row'}>
                            <IconButton
                                disableRipple
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography variant="h3">
                                {formSubmission.assessmentName}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack
                        sx={{
                            backgroundColor: '#B2B2B2',
                            padding: '10px',
                            height: '100%',
                            flexGrow: 1,
                        }}
                    >
                        <Stack
                            sx={{
                                backgroundColor: 'white',
                                padding: '10px',
                                borderRadius: '5px',
                            }}
                            spacing={'10px'}
                        >
                            <Stack
                                sx={{
                                    border: '1px solid #DBDCE0',
                                    borderRadius: '5px',
                                    justifyContent: 'space-between',
                                    padding: '10px 20px',
                                }}
                                spacing={'10px'}
                                direction={'row'}
                            >
                                <Stack
                                    direction="row"
                                    sx={{
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Stack>
                                        <Icon
                                            src={Logo}
                                            style={{
                                                height: '100px',
                                                width: '100px',
                                            }}
                                        />
                                    </Stack>
                                    {form.instructions && (
                                        <Stack spacing={'3px'}>
                                            <Typography variant="caption1">
                                                {'Instructions'}
                                            </Typography>
                                            <textarea
                                                value={form.instructions}
                                                readOnly
                                                style={{
                                                    width: '40vw',
                                                    resize: 'none',
                                                    fontFamily: 'Mier-Book',
                                                    fontSize: '0.85rem',
                                                    fontWeight: '400',
                                                    lineHeight: '1.36rem',
                                                    height: '120px',
                                                    border: `1px solid ${theme.palette.structuralColors.lightGray}`,
                                                }}
                                            />
                                        </Stack>
                                    )}
                                </Stack>
                                <Stack
                                    spacing={'10px'}
                                    sx={{
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    <Stack
                                        direction={'row'}
                                        sx={{
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            alignItem: 'center',
                                        }}
                                        spacing={'10px'}
                                    >
                                        <Stack
                                            sx={{
                                                width: '20vw',
                                                justifyContent: 'center',
                                            }}
                                            gap={1}
                                        >
                                            <Typography
                                                variant="body1"
                                                color={
                                                    theme.palette.text
                                                        .lowEmphasis
                                                }
                                            >
                                                {'Progress'}
                                            </Typography>
                                            <LinearProgress
                                                variant="determinate"
                                                value={progress}
                                                sx={{ width: '20vw' }}
                                            />
                                            <Typography
                                                variant="body2"
                                                sx={{ color: 'text.secondary' }}
                                            >
                                                {`${answered}/${total}`}
                                            </Typography>
                                        </Stack>
                                        <Stack
                                            spacing={1}
                                            sx={{
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Typography
                                                variant="body1"
                                                color={
                                                    theme.palette.text
                                                        .lowEmphasis
                                                }
                                            >
                                                {'Due Date'}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color={
                                                    theme.palette.text
                                                        .highEmphasis
                                                }
                                            >
                                                {formattedDate}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack
                                        direction={'row'}
                                        sx={{
                                            justifyContent: 'space-between',
                                            alignItem: 'center',
                                            width: '100%',
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            onClick={() =>
                                                handleSubmitForm('SUBMITTED')
                                            }
                                            sx={{ width: '180px' }}
                                        >
                                            <Typography variant="caption1">
                                                {'Submit Assessment'}
                                            </Typography>
                                        </Button>
                                        {role === 'ADMIN' ? (
                                            <Button
                                                variant="contained"
                                                onClick={() =>
                                                    handleSubmitForm(
                                                        'COMPLETED'
                                                    )
                                                }
                                                sx={{ width: '180px' }}
                                            >
                                                <Typography variant="caption1">
                                                    {'Mark as Completed'}
                                                </Typography>
                                            </Button>
                                        ) : (
                                            <></>
                                        )}
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Stack
                                spacing={'10px'}
                                sx={{
                                    border: '1px solid #DBDCE0',
                                    borderRadius: '5px',
                                    height: '77vh',
                                    overflowY: 'scroll',
                                    overflowX: 'hidden',
                                }}
                            >
                                <Stack
                                    sx={{
                                        width: '100%',
                                        borderBottom: '2px solid #DBDCE0',
                                        zIndex: 50,
                                        position: 'sticky',
                                        top: 0,
                                        backgroundColor:
                                            theme.palette.structuralColors
                                                .stroke2,
                                    }}
                                >
                                    <table>
                                        <thead>
                                            <tr>
                                                <th
                                                    style={{
                                                        width: '22%',
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        color={
                                                            theme.palette.text
                                                                .highEmphasis
                                                        }
                                                        textAlign={'left'}
                                                    >
                                                        {'Question'}
                                                    </Typography>
                                                </th>
                                                <th
                                                    style={{
                                                        width: '18%',
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        color={
                                                            theme.palette.text
                                                                .highEmphasis
                                                        }
                                                        textAlign={'left'}
                                                    >
                                                        {'Evidence Requirement'}
                                                    </Typography>
                                                </th>
                                                <th
                                                    style={{
                                                        width: '25%',
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        color={
                                                            theme.palette.text
                                                                .highEmphasis
                                                        }
                                                        textAlign={'left'}
                                                    >
                                                        {'Response'}
                                                    </Typography>
                                                </th>
                                                <th
                                                    style={{
                                                        width: '5%',
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        color={
                                                            theme.palette.text
                                                                .highEmphasis
                                                        }
                                                        textAlign={'left'}
                                                    >
                                                        {'Comments'}
                                                    </Typography>
                                                </th>
                                                <th
                                                    style={{
                                                        width: '20%',
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        color={
                                                            theme.palette.text
                                                                .highEmphasis
                                                        }
                                                        textAlign={'left'}
                                                    >
                                                        {'Internal Review'}
                                                    </Typography>
                                                </th>

                                                <th
                                                    style={{
                                                        width: '10%',
                                                    }}
                                                >
                                                    {role === 'ADMIN' ? (
                                                        <Typography
                                                            variant="body2"
                                                            color={
                                                                theme.palette
                                                                    .text
                                                                    .highEmphasis
                                                            }
                                                            textAlign={'left'}
                                                        >
                                                            {'Risk Value'}
                                                        </Typography>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                </Stack>
                                {form.questions.map((question, index) => (
                                    <Stack
                                        key={question.id}
                                        sx={{
                                            gap: '10px',
                                            padding: '3px',
                                        }}
                                    >
                                        <QuestionComponent
                                            question={question}
                                            answer={answers[question.id]}
                                            onChange={(updatedAnswer) => {
                                                handleAnswerChange(
                                                    question.id,
                                                    updatedAnswer
                                                );
                                            }}
                                            refreshAnswers={fetchAnswers}
                                            role={role}
                                            vendorId={formSubmission.vendorId}
                                        />
                                        {index < form.questions.length - 1 && (
                                            <Divider />
                                        )}
                                    </Stack>
                                ))}
                            </Stack>
                        </Stack>
                    </Stack>

                    <CustomSnackbar
                        open={openSnackBar}
                        message={message}
                        success={success}
                        onClose={handleClose}
                        autoHideDuration={visibleTime}
                    />
                </Stack>
            )}
        </>
    );
};

export default FormSubmissionComponent;
