import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { DecodedJWT, UserData, VendorData } from '../../utils/types';
import {
    getAllUsers,
    getAllVendorUser,
    getVendorById,
} from '../../services/api';
import { useNavigate, useLocation } from 'react-router-dom';
import DocumentHeader from '../../components/molecules/DocumentHeader';
import DocumentDetail from '../../components/organisms/DocumentDetail';
import MainTemplate from '../../components/templates/MainTemplate';
import HistoryIcon from '@mui/icons-material/History';
import TuneIcon from '@mui/icons-material/Tune';
import { DocValues } from '../../components/organisms/DocumentTab';
import VendorDocuments from '../../components/organisms/VendorDocumentsTab';
import ChatIcon from '@mui/icons-material/Chat';
import RemarkViewer from '../../components/organisms/RemarkViewer';
import ManageVendorUsers from '../../components/organisms/ManageVendorUsers';
import SendFormForSubmission from '../../components/organisms/SendFormForSubmission';
import AllAssessmentsTab from '../../components/organisms/AllAssessmentsTab';
import FillForm from '../../components/organisms/FillForm';
import VendorPropertiesTab from '../../components/organisms/VendorPropertiesTab';
import { jwtDecode } from 'jwt-decode';
import useSnackBar from '../../utils/hooks';
import CustomSnackbar from '../../components/molecules/CustomSnackbar';

const VendorDetailsPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const [vendorUsers, setVendorUsers] = useState<UserData[]>([]);
    const [verticalTab, setVerticalTab] = useState(0);
    const [documentId, setDocumentId] = useState('');
    const [documentType, setDocumentType] = useState('');
    const [internalUsers, setInternalUsers] = useState<UserData[]>([]);
    const [hiddenHorizontalTabs, setHiddenHorizontalTab] = useState([
        'Documents & Links',
        'Risk Assessment',
        'Send Form',
        'Manage Vendor Users',
    ]);
    const [hiddenVerticalTabs, setHiddenVerticalTab] = useState([
        'Remarks',
        'Activities',
    ]);
    const [vendorData, setVendorData] = useState<VendorData>({
        id: '',
        referenceId: '',
        name: '',
        vendorStatus: '',
        vendorRiskValue: '',
        vendorRiskScore: '',
    });
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const { openSnackBar, handleClick, handleClose } = useSnackBar();

    let token;
    if (localStorage.getItem('token')) {
        token = localStorage.getItem('token');
    }
    const { role } = jwtDecode<DecodedJWT>(token);

    const handleVerticalTabChange = (newValue: number) => {
        setVerticalTab(newValue);
    };

    const handleDocValueChange = (docValues: DocValues) => {
        setDocumentId(docValues.docId);
        setDocumentType(docValues.type);
    };

    const fetchVendorUsers = async () => {
        const response = await getAllVendorUser(id);
        if (response) {
            setVendorUsers(response);
        } else {
            setMessage('Failed to fetch data from server.');
            handleClick();
            setSuccess(false);
        }
    };
    const fetchUserData = async () => {
        const response: UserData[] = await getAllUsers();
        if (response) {
            setInternalUsers(response);
        } else {
            setMessage('Failed to fetch data from server.');
            handleClick();
            setSuccess(false);
        }
    };

    const fetchVendorData = async () => {
        const response = await getVendorById(id);
        if (response) {
            setVendorData(response);
        } else {
            setMessage('Failed to get properties. Please try again.');
            handleClick();
            setSuccess(false);
        }
    };

    useEffect(() => {
        fetchVendorData();
        if (role === 'ADMIN') {
            setHiddenHorizontalTab([]);
            setHiddenVerticalTab([]);
            fetchUserData();
            fetchVendorUsers();
        }
    }, []);

    const horizontalTabs = [
        {
            label: 'Documents & Links',
            content: (
                <Stack
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    {id && (
                        <VendorDocuments
                            userRole={role}
                            setRemarkTab={() => handleVerticalTabChange(1)}
                            vendorId={id}
                            onDocumentIdChange={(docValues) =>
                                handleDocValueChange(docValues)
                            }
                        />
                    )}
                </Stack>
            ),
        },
        {
            label: 'Fill Forms',
            content: (
                <Stack
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    {id && <FillForm vendorId={id} />}
                </Stack>
            ),
        },
        {
            label: 'Risk Assessment',
            content: (
                <Stack
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    {id && (
                        <AllAssessmentsTab
                            vendorId={id}
                            vendorUsers={vendorUsers}
                            internalUsers={internalUsers}
                        />
                    )}
                </Stack>
            ),
        },
        {
            label: 'Send Form',
            content: (
                <Stack
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    {id && (
                        <SendFormForSubmission
                            vendorId={id}
                            vendorUsers={vendorUsers}
                            internalUsers={internalUsers}
                        />
                    )}
                </Stack>
            ),
        },
        {
            label: 'Manage Vendor Users',
            content: (
                <Stack
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    {id && (
                        <ManageVendorUsers
                            vendorId={id}
                            vendorUsers={vendorUsers}
                            fetchVendorUsers={fetchVendorUsers}
                        />
                    )}
                </Stack>
            ),
        },
    ];

    const verticalTabs = [
        {
            label: 'Properties',
            icon: <TuneIcon />,
            content: (
                <Stack
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    {id && (
                        <VendorPropertiesTab
                            userRole={role}
                            vendorId={id}
                            fetchData={fetchVendorData}
                            vendorData={vendorData}
                        />
                    )}
                </Stack>
            ),
        },
        {
            label: 'Remarks',
            icon: <ChatIcon />,
            content: <RemarkViewer docId={documentId} docType={documentType} />,
        },
        {
            label: 'Activities',
            icon: <HistoryIcon />,
            content: <div>Content for Vertical Tab 2</div>,
        },
    ];

    return (
        <MainTemplate
            main={
                <Stack sx={{ width: '100%', height: '100%' }}>
                    {vendorData.name && id && (
                        <DocumentHeader
                            backButtonClick={() => navigate('/u/vendors')}
                            labelText={vendorData.name}
                            id={id}
                            fetchData={fetchVendorData}
                            userRole={role}
                            type={'VENDOR'}
                        />
                    )}
                    <DocumentDetail
                        horizontalTabs={horizontalTabs}
                        verticalTabs={verticalTabs}
                        verticalTabValue={verticalTab}
                        onVerticalTabChange={handleVerticalTabChange}
                        hiddenHorizontalTabs={hiddenHorizontalTabs}
                        hiddenVerticalTabs={hiddenVerticalTabs}
                    />
                    <CustomSnackbar
                        open={openSnackBar}
                        message={message}
                        success={success}
                        onClose={handleClose}
                        autoHideDuration={1800}
                    />
                </Stack>
            }
        />
    );
};

export default VendorDetailsPage;
