import { MenuItem, Stack, styled } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { validateEmailFormat } from '../../../utils/constants';
import { validateTitleFormat } from '../../../utils/validations';
import TypoInput from '../../molecules/TypoInput';
import Typography from '../../atoms/Typography';
import Button from '../../atoms/Button';
import { createVendorUser } from '../../../services/api';
import ClientSideDataTable from '../ClientSideDataTable';
import { getUsersColumns } from '../../../utils/helper';
import theme from '../../../theme';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const BackButtonStack = styled(Stack)({
    width: '100%',
    justifyContent: 'flex-start',
    flexDirection: 'row',
});

const BackButton = styled(Button)({
    '&:hover': {
        backgroundColor: theme.palette.structuralColors.white,
    },
    padding: '0px',
});

const ErrorStyle = {
    marginLeft: '14px',
};

const RoleValues = [
    {
        id: 1,
        option: 'VENDOR',
        value: 'VENDOR',
    },
];

interface ManageVendorUsersProps {
    vendorId: string;
    vendorUsers: any;
    fetchVendorUsers: () => void;
}

const ManageVendorUsers = (props: ManageVendorUsersProps) => {
    const [userDetails, setUserDetails] = useState({
        name: '',
        email: '',
        role: '',
    });
    const [errors, setErrors] = useState({
        name: '',
        email: '',
        role: '',
    });
    const vendorUsers = props.vendorUsers || [];
    const [showComponent, setShowComponent] = useState(false);

    const handleComponetChange = () => {
        setShowComponent(!showComponent);
    };

    const handleNameChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { value } = event.target;

        setUserDetails((prevState) => ({
            ...prevState,
            name: value,
        }));

        const charCount = value.length;

        if ((value && !validateTitleFormat(value)) || charCount > 30) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                name: 'Invalid Name Input',
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                name: '',
            }));
        }
    };

    const handleEmailChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const value = event.target.value.replace(/\s/g, '');

        setUserDetails((prevState) => ({
            ...prevState,
            email: value,
        }));

        const charCount = value.length;

        if ((value && !validateEmailFormat(value)) || charCount > 64) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: 'Invalid Email Input',
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: '',
            }));
        }
    };

    const handleRoleChange = (event: ChangeEvent<{ value: unknown }>) => {
        const { value } = event.target as HTMLSelectElement;

        setUserDetails((prevState) => ({
            ...prevState,
            role: value as string,
        }));

        if (!value) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                role: 'Role is required',
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                role: '',
            }));
        }
    };

    const handleContinue = async (
        name: string,
        email: string,
        role: string
    ) => {
        const response = await createVendorUser({
            name: name,
            email: email,
            vendorId: props.vendorId,
        });
        if (response) {
            props.fetchVendorUsers();
        } else {
            console.log('fail');
        }
    };

    const areMandatoryFieldsFilled = () => {
        return (
            !errors.name &&
            !errors.email &&
            !errors.role &&
            userDetails.name &&
            userDetails.email &&
            userDetails.role
        );
    };

    return (
        <Stack padding={'10px'}>
            <Stack
                spacing={'15px'}
                sx={{
                    border: `1px solid ${theme.palette.structuralColors.gray}`,
                    padding: '15px 10px',
                    borderRadius: '3px',
                }}
            >
                {!showComponent && (
                    <Stack spacing={'15px'}>
                        <Stack
                            sx={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                variant="h2"
                                color={theme.palette.text.highEmphasis}
                            >
                                {'Vendor Users'}
                            </Typography>
                            <Button
                                onClick={handleComponetChange}
                                variant="contained"
                                sx={{ width: '250px' }}
                                disableElevation
                            >
                                <Typography variant="body1">
                                    {'Add User'}
                                </Typography>
                            </Button>
                        </Stack>
                        <ClientSideDataTable
                            columns={getUsersColumns((row) => {
                                const rowData = {
                                    name: row.name,
                                    email: row.email,
                                    id: row.id,
                                };
                            })}
                            rowsData={vendorUsers}
                            isLoading={false}
                            checkboxSelection={false}
                            slotProps={{
                                loadingOverlay: {
                                    variant: 'skeleton',
                                    noRowsVariant: 'skeleton',
                                },
                            }}
                            resizeThrottleMs={190}
                            pageSizeOptions={[5, 10, 25, 50, 75, 100]}
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: 10, page: 0 },
                                },
                            }}
                        />
                    </Stack>
                )}
                {showComponent && (
                    <Stack
                        sx={{
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                        }}
                    >
                        <BackButtonStack>
                            <BackButton
                                startIcon={<ArrowBackIcon />}
                                onClick={handleComponetChange}
                            >
                                <Typography
                                    variant={'body1'}
                                    color={theme.palette.primary[500]}
                                >
                                    {'Back'}
                                </Typography>
                            </BackButton>
                        </BackButtonStack>
                        <Stack spacing={'10px'} sx={{ width: '30vw' }}>
                            <div>
                                <TypoInput
                                    labelvalue="Name"
                                    value={userDetails.name}
                                    onChange={(e) => handleNameChange(e)}
                                    manditoryfield="true"
                                ></TypoInput>
                                {errors.name && (
                                    <Typography
                                        variant="caption2"
                                        color={'red'}
                                        sx={ErrorStyle}
                                    >
                                        {errors.name}
                                    </Typography>
                                )}
                            </div>
                            <div>
                                <TypoInput
                                    labelvalue="Email"
                                    value={userDetails.email}
                                    onChange={(e) => handleEmailChange(e)}
                                    manditoryfield="true"
                                ></TypoInput>
                                {errors.email && (
                                    <Typography
                                        variant="caption2"
                                        color={'red'}
                                        sx={ErrorStyle}
                                    >
                                        {errors.email}
                                    </Typography>
                                )}
                            </div>
                            <div>
                                <TypoInput
                                    select
                                    labelvalue="Role"
                                    value={userDetails.role}
                                    onChange={handleRoleChange}
                                    fullWidth
                                >
                                    {RoleValues.map((data) => (
                                        <MenuItem
                                            key={data.id}
                                            value={data.value}
                                        >
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    textTransform: 'capitalize',
                                                }}
                                            >
                                                {data.option}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </TypoInput>
                            </div>
                            <Stack
                                sx={{
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    paddingTop: '15px',
                                }}
                            >
                                <Button
                                    type="submit"
                                    variant="contained"
                                    onClick={() => {
                                        handleContinue(
                                            userDetails.name,
                                            userDetails.email,
                                            userDetails.role
                                        );
                                        setUserDetails({
                                            name: '',
                                            email: '',
                                            role: '',
                                        });
                                    }}
                                    sx={{ width: '15vw' }}
                                    disabled={!areMandatoryFieldsFilled()}
                                >
                                    <Typography variant="body1">
                                        {'Add User'}
                                    </Typography>
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};

export default ManageVendorUsers;
