import React, { useEffect, useState } from 'react';
import {
    Autocomplete,
    Typography,
    Stack,
    TextField,
    styled,
    Button,
    Divider,
} from '@mui/material';
import theme from '../../../theme';
import { Teams } from '../../../utils/constants';
import { updateRiskProcess } from '../../../services/api';
import { UserData } from '../../../utils/types';
import useSnackBar from '../../../utils/hooks';
import CustomSnackbar from '../../molecules/CustomSnackbar';

const StyledButton = styled(Button)({
    padding: '5px 8px',
    width: '160px',
});

interface RiskEvaluationProps {
    riskId: string;
    userProfileData: any;
    riskData: any;
    fetchData: () => void;
}

const RiskEvaluation = (props: RiskEvaluationProps) => {
    const [formData, setFormData] = useState({
        controlData: '',
        riskControlOwnerIds: [] as string[],
        riskControlOwnersTeam: [] as string[],
    });
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const { openSnackBar, handleClick, handleClose } = useSnackBar();
    const userData = props.userProfileData || [];
    const riskFormData = props.riskData || [];

    const fetchedData = () => {
        const response = riskFormData;
        if (response) {
            if (response.riskEvaluation != null) {
                const data = response.riskEvaluation;
                const formattedData = {
                    controlData: data?.controlData,
                    riskControlOwnerIds: data?.riskControlOwners.map(
                        (owner: UserData) => owner.id
                    ),
                    riskControlOwnersTeam: data?.riskControlOwnersTeam,
                };
                setFormData(formattedData);
            }
        } else {
            setMessage('Failed to fetch data from server.');
            handleClick();
            setSuccess(false);
        }
    };

    useEffect(() => {
        fetchedData();
    }, []);

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleAutocompleteChange = (
        e: React.ChangeEvent<unknown>,
        value: any[],
        type: 'team' | 'user'
    ) => {
        if (type === 'team') {
            setFormData({
                ...formData,
                riskControlOwnersTeam: value as string[],
            });
        } else if (type === 'user') {
            setFormData({
                ...formData,
                riskControlOwnerIds: value.map((user) => user.id),
            });
        }
    };

    const handleSave = async () => {
        const response = await updateRiskProcess(
            props.riskId,
            'EVALUATION',
            formData
        );
        if (response && response.success) {
            setMessage(response.message);
            handleClick();
            setSuccess(true);
            props.fetchData();
        } else {
            setMessage('Please fill in all the fields before saving.');
            handleClick();
            setSuccess(false);
        }
    };

    return (
        <Stack spacing={'20px'} padding={'10px'}>
            <Stack
                spacing={'15px'}
                sx={{
                    border: `1px solid ${theme.palette.structuralColors.gray}`,
                    padding: '15px 10px',
                    borderRadius: '3px',
                }}
            >
                <Typography
                    variant="subtitle1"
                    color={theme.palette.text.highEmphasis}
                >
                    {'Risk Evaluation'}
                </Typography>
                <Divider />
                <Autocomplete
                    multiple
                    size="small"
                    options={Teams}
                    getOptionLabel={(option) => option}
                    value={formData.riskControlOwnersTeam}
                    onChange={(e, value) =>
                        handleAutocompleteChange(e, value, 'team')
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={`Risk Owner(s) - Team`}
                            placeholder={`Risk Owner(s) - Team`}
                        />
                    )}
                />
                <Autocomplete
                    multiple
                    size="small"
                    options={userData}
                    getOptionLabel={(option) => option.name}
                    value={userData.filter((user) =>
                        formData.riskControlOwnerIds.includes(user.id)
                    )}
                    onChange={(e, value) =>
                        handleAutocompleteChange(e, value, 'user')
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={`Risk Owner(s)`}
                            placeholder={`Risk Owner(s)`}
                        />
                    )}
                />
                <Stack spacing={'5px'}>
                    <Typography
                        variant="caption1"
                        color={theme.palette.text.mediumEmphasis}
                    >
                        {'Existing Control Identification'}
                    </Typography>
                    <textarea
                        placeholder="Existing Control Identification"
                        name="controlData"
                        value={formData.controlData}
                        onChange={handleInputChange}
                        style={{
                            fontFamily: 'Mier-Book',
                            fontSize: '16px',
                            padding: '5px',
                            width: '99%',
                            border: `1px solid ${theme.palette.structuralColors.stroke1}`,
                            minHeight: '120px',
                        }}
                    />
                </Stack>
                <StyledButton variant="outlined" onClick={handleSave}>
                    <Typography variant="caption1">{'Save'}</Typography>
                </StyledButton>
            </Stack>
            <CustomSnackbar
                open={openSnackBar}
                message={message}
                success={success}
                onClose={handleClose}
                autoHideDuration={1800}
            />
        </Stack>
    );
};

export default RiskEvaluation;
