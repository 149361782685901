import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '../../atoms/Typography';
import Button from '../../atoms/Button';
interface ConfirmationModalProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    onCancel: () => void;
    title: string;
    confirmText: string;
    cancelText: string;
}

const ConfirmationModal = (props: ConfirmationModalProps) => {
    return (
        <Modal open={props.open} onClose={props.onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'white',
                    width: '25vw',
                    padding: '20px',
                    borderRadius: '10px',
                }}
            >
                <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                    {props.title}
                </Typography>
                <Stack
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        marginTop: '4rem',
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{ marginRight: '1rem' }}
                        onClick={() => {
                            props.onConfirm();
                            props.onClose();
                        }}
                        disableElevation
                    >
                        <Typography variant="caption1">
                            {props.confirmText}
                        </Typography>
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={props.onCancel}
                        disableElevation
                    >
                        <Typography variant="caption1">
                            {props.cancelText}
                        </Typography>
                    </Button>
                </Stack>
            </Box>
        </Modal>
    );
};

export default ConfirmationModal;
