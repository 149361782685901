import axios from 'axios';
import React, { createContext, useContext, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

const signOutAxiosInstance = axios.create();

interface AuthContextType {
    isAuthenticated: boolean;
    login: (token: string, refreshToken: string) => void;
    logout: () => void;
}

const AuthContext = createContext<AuthContextType>({
    isAuthenticated: false,
    login: () => {},
    logout: () => {},
});

export function useAuth(): AuthContextType {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthContextProvider');
    }
    return context;
}

interface AuthContextProviderProps {
    children: ReactNode;
}

export function AuthContextProvider({
    children,
}: AuthContextProviderProps): JSX.Element {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
        const token = localStorage.getItem('token');
        return !!token;
    });
    const [accessToken, setAccessToken] = useState<string | null>(() =>
        localStorage.getItem('token')
    );
    
    const signOut = () => {
        const token = accessToken;
        if (token) {
            localStorage.clear();
            setIsAuthenticated(false);
            setAccessToken(null);
            navigate('/');
        }
    };

    const login = (token: string, refreshToken: string): void => {
        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', refreshToken);
        setAccessToken(token);
        setIsAuthenticated(true);
    };

    const logout = (): void => {
        setIsAuthenticated(false);
        signOut();
    };

    const value: AuthContextType = {
        isAuthenticated,
        login,
        logout,
    };

    setLogoutFunction(logout);
    setAccessTokenFunction(setAccessToken);

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
}

let logoutFunction: () => void = () => {};
let accessTokenFunction: (token: string | null) => void = () => {};

export function setLogoutFunction(logout: () => void) {
    logoutFunction = logout;
}

export function setAccessTokenFunction(
    setToken: (token: string | null) => void
) {
    accessTokenFunction = setToken;
}

export { logoutFunction, accessTokenFunction };
