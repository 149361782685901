import axios, { AxiosError } from 'axios';
import {
    AddDocumentLinkRequestBody,
    CreateApprovalRequestBody,
    CreateRegistryRequestBody,
    CreateRiskRegisterRequestBody,
    GetFileRequestBody,
    GetUserApprovalsRequestBody,
    RegistryIdRequestBody,
    ResendApprovalStatus,
    UpdateApprovalStatus,
} from '../utils/types';
import { accessTokenFunction, logoutFunction } from '../context/authContext';
const baseURL = process.env.BACKEND_URL;

export const API = axios.create({
    baseURL: baseURL,
});

API.interceptors.request.use(
    async (config) => {
        let token;
        if (localStorage.getItem('token')) {
            token = localStorage.getItem('token');
        } else if (localStorage.getItem('accessToken')) {
            token = localStorage.getItem('accessToken');
        }
        if (shouldIncludeToken(config.url)) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

API.interceptors.response.use(
    async (response) => {
        return response;
    },

    async function (error) {
        const originalRequest = error.config;

        if (
            error.response &&
            error.response.status === 401 &&
            !originalRequest._retry
        ) {
            originalRequest._retry = true;
            try {
                const refreshToken = localStorage.getItem('refreshToken');
                if (!refreshToken) {
                    logoutFunction();
                    return Promise.reject(error);
                }
                const resp = await axios.post(
                    baseURL + `/auth/refresh-token`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${refreshToken}`,
                        },
                    }
                );
                const data = resp.data;
                if (data && data.jwt) {
                    localStorage.setItem('token', data.jwt);
                    API.defaults.headers.common['Authorization'] =
                        `Bearer ${data.jwt}`;
                    accessTokenFunction(data.jwt);
                    return API(originalRequest);
                } else {
                    logoutFunction();
                    return Promise.reject(error);
                }
            } catch (error) {
                logoutFunction();
                return Promise.reject(error);
            }
        }
        return Promise.reject(error);
    }
);

function shouldIncludeToken(url: any) {
    const protectedEndpoints = ['/api'];
    return protectedEndpoints.some((endpoint) => url.includes(endpoint));
}
export const addDocumentLink = async (data: AddDocumentLinkRequestBody) => {
    try {
        const response = await API.post(`/api/document/addDocumentLink`, data);
        return response.data;
    } catch (err) {
        console.error(err);
    }
};

export const getDocumentRemarks = async (data: any) => {
    try {
        const response = await API.post(`/api/document/get-remarks`, data);
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const addDocumentRemarks = async (data: any) => {
    try {
        const response = await API.post(`/api/document/add-remarks`, data);
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const editDocumentLink = async (data: any) => {
    try {
        const response = await API.post(`/api/document/editDocumentLink`, data);
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const editFileUpload = async (data: any) => {
    try {
        const response = await API.post(`/api/document/editFileUpload`, data);
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const getAllRegistryDocuments = async (data: RegistryIdRequestBody) => {
    try {
        const response = await API.post(
            `/api/document/getRegistryDocuments`,
            data
        );
        return response.data;
    } catch (err) {
        console.error(err);
    }
};

export const getAllUsers = async () => {
    try {
        const response = await API.get(`/api/user/allSearchUsers`);
        return response.data;
    } catch (err) {
        console.error(err);
    }
};

export const addNewUser = async (data: any) => {
    try {
        const response = await API.post(`/api/user/addNewUser`, data);
        return response.data;
    } catch (err) {
        console.error(err);
    }
};

export const removeUser = async (data: any) => {
    try {
        const response = await API.post(`/api/user/removeUser`, data);
        return response.data;
    } catch (err) {
        console.error(err);
    }
};

export const getAllUsersWithRole = async (data: any) => {
    try {
        const response = await API.post(`/api/user/getAllUsers`, data);
        return response.data;
    } catch (err) {
        console.error(err);
    }
};

// API -> /api/approvals

export const createApproval = async (data: CreateApprovalRequestBody) => {
    try {
        const response = await API.post(`/api/approvals/createApproval`, data);
        return response.data;
    } catch (err) {
        console.error(err);
    }
};

export const getAllUserApproval = async (data: GetUserApprovalsRequestBody) => {
    try {
        const response = await API.post(
            `/api/approvals/getUserApprovals`,
            data
        );
        return response.data;
    } catch (err) {
        console.error(err);
    }
};

export const updateApproval = async (data: UpdateApprovalStatus) => {
    try {
        const response = await API.post(`/api/approvals/updateApproval`, data);
        return response.data;
    } catch (err) {
        console.error(err);
    }
};

export const getAllApprovalsByRegistry = async (
    data: RegistryIdRequestBody
) => {
    try {
        const response = await API.post(`/api/approvals/getAllApprovals`, data);
        return response.data;
    } catch (err) {
        console.error(err);
    }
};

export const resendApproval = async (data: ResendApprovalStatus) => {
    try {
        const response = await API.post(`/api/approvals/resendApproval`, data);
        return response.data;
    } catch (err) {
        console.error(err);
    }
};

// API -> /api/registry

export const getRegistry = async (id: string) => {
    try {
        const response = await API.get(`/api/registry/getRegistry/${id}`);
        return response.data;
    } catch (err) {
        console.error(err);
    }
};

export const createRegistry = async (data: CreateRegistryRequestBody) => {
    try {
        const response = await API.post(`/api/registry/createRegistry`, data);
        return response.data;
    } catch (err) {
        console.error(err);
    }
};

export const updateRegistryProperties = async (data: any) => {
    try {
        const response = await API.post(
            `/api/registry/updateRegistryProperties`,
            data
        );
        return response.data;
    } catch (err) {
        console.error(err);
    }
};

//Risk-Register-API's

export const getRiskRegister = async (id: string) => {
    try {
        const response = await API.get(`/api/risks/getRiskRegister/${id}`);
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const manageRiskRegister = async (
    data: CreateRiskRegisterRequestBody
) => {
    try {
        const response = await API.post(`/api/risks/manageRiskRegister`, data);
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const importRiskMatrix = async (
    riskRegisterId: string,
    selectedMatrixId: string
) => {
    try {
        const response = await API.post(
            `/api/risks/${riskRegisterId}/import-matrix/${selectedMatrixId}`,
            {}
        );
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const updateRiskProcess = async (
    riskId: string,
    processType: string,
    data: any,
    answerType?: string
) => {
    try {
        const finalAnswerType = answerType || 'riskRegister';

        const response = await API.put(
            `/api/risks/${riskId}/process/${processType}/answer/${finalAnswerType}`,
            data
        );
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const addRiskEvidence = async (riskId: string, data: any) => {
    try {
        const response = await API.put(
            `/api/risks/addRiskEvidence/${riskId}`,
            data
        );
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

// API -> /api/files

export const getFileForPreview = async (data: GetFileRequestBody) => {
    try {
        const response = await API.post(
            `/api/files/getFileForPreview`,
            data,
            { responseType: 'blob' }
        );
        return response;
    } catch (err) {
        console.error(err);
    }
};

interface UploadFilesOptions {
    data: FormData;
    onProgress?: (percentCompleted: number) => void;
}

export const uploadFiles = async ({ data, onProgress }: UploadFilesOptions) => {
    try {
        const response = await API.post('/api/files/upload', data, {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: (progressEvent) => {
                if (onProgress) {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) /
                            (progressEvent.total ?? 1)
                    );
                    onProgress(percentCompleted);
                }
            },
        });
        return response;
    } catch (error) {
        console.error('Upload failed', error);
        throw error;
    }
};

export const deleteFileFromGCP = async (data: any) => {
    try {
        const response = await API.post(`/api/files/deleteFile`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (err) {
        console.error(err);
    }
};

export const downloadFileFromGCP = async (data: any) => {
    try {
        const response = await API.post(`/api/files/downloadFile`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: 'blob',
        });
        return response;
    } catch (err) {
        console.error(err);
    }
};

// API -> Doucments

export const getRiskRegisterDocuments = async (id: string) => {
    try {
        const response = await API.get(
            `/api/document/getRiskRegisterDocuments/${id}`
        );
        return response.data;
    } catch (err) {
        console.error(err);
    }
};

export const getVendorDocuments = async (
    id: string,
    pageNumber?: number,
    pageSize?: number
) => {
    try {
        const response = await API.get(
            `/api/document/getVendorDocuments/${id}?p=${pageNumber}&s=${pageSize}`
        );
        return response.data;
    } catch (err) {
        console.error(err);
    }
};

//Forms

export const createForm = async (data: any) => {
    try {
        const response = await API.post(`/api/forms/createForm`, data);
        return response.data;
    } catch (err) {
        console.error(err);
    }
};

export const getAllForms = async () => {
    try {
        const response = await API.get(`/api/forms/getAllForms`);
        return response.data;
    } catch (err) {
        console.error(err);
    }
};

export const addQuestion = async (id: string, data: any) => {
    try {
        const response = await API.post(`/api/forms/${id}/questions`, data);
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const updateQuestion = async (
    formId: string,
    questionId: string,
    data: any
) => {
    try {
        const response = await API.put(
            `/api/forms/${formId}/questions/${questionId}/updateQuestion`,
            data
        );
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const updateForm = async (
    formId: string,
    data: any
) => {
    try {
        const response = await API.put(
            `/api/forms/updateForm/${formId}`,
            data
        );
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const deleteQuestion = async (formId: string, questionId: string) => {
    try {
        const response = await API.post(
            `/api/forms/${formId}/questions/${questionId}/deleteQuestion`
        );
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const getFormSubmission = async (formSubmissionId: string) => {
    try {
        const response = await API.get(
            `/api/formSubmissions/${formSubmissionId}`
        );
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const getForm = async (formId: string) => {
    try {
        const response = await API.get(`/api/forms/${formId}`);
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const getAnswersForFormSubmission = async (formSubmissionId: string) => {
    const response = await API.get(
        `/api/formSubmissions/${formSubmissionId}/get-answers`
    );
    return response.data;
};

export const submitAnswer = async (data: any) => {
    try {
        const response = await API.post(
            `/api/formSubmissions/submitAnswers`,
            data
        );
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const addCommentToAnswer = async (data: any) => {
    try {
        const response = await API.post(
            `/api/formSubmissions/addComment`,
            data
        );
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const evaluateAnswers = async (data: any) => {
    try {
        const response = await API.post(
            `/api/formSubmissions/evaluateAnswers`,
            data
        );
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const submitFormSubmission = async (formSubmissionId: string, status: string) => {
    try {
        const response = await API.put(
            `/api/formSubmissions/${formSubmissionId}/status/${status}/submit`
        );
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

//auth

export const ssoLogin = async () => {
    try {
        const response = await API.get(`/auth/url`);
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const getTokenWithCode = async (code: string) => {
    try {
        const response = await API.get(`/auth/callback?code=${code}`);
        return response.data;
    } catch (error) {
        //do nothing
    }
};

export const vendorLoginRequest = async (data: { userEmail: string }) => {
    try {
        const response = await API.post(`/auth/vendor`, data);
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};
export const vendorGetAccessToken = async (data: { magicToken: string }) => {
    try {
        const response = await API.post(`/auth/vendor/authenticate`, data);
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

//vendor
export const createVendor = async (data: any) => {
    try {
        const response = await API.post(`/api/vendors/createVendor`, data);
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const updateVendorProperties = async (data: any) => {
    try {
        const response = await API.post(
            `/api/vendors/updateVendorProperties`,
            data
        );
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const createFormSubmission = async (data: any) => {
    try {
        const response = await API.post(
            `/api/formSubmissions/createFormSubmission`,
            data
        );
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const getAllFormSubmissions = async (vendorId: string) => {
    try {
        const response = await API.get(
            `/api/formSubmissions/getAllFormSubmissions/${vendorId}`
        );
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const getFormSubmissionsByUserId = async (vendorId: string) => {
    try {
        const response = await API.get(
            `/api/formSubmissions/getFormSubmissionsByUserId?vendorId=${vendorId}`
        );
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const removeFileFromAnswer = async (data: any) => {
    try {
        const response = await API.post(
            `/api/formSubmissions/removeFileFromAnswer`,{data}
        );
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const markCommentAsViewedApi = async (answerId: string) => {
    try {
        const response = await API.post(
            `/api/formSubmissions/${answerId}/viewed`,
            {}
        );
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const updateFormSubmission = async (data: any) => {
    try {
        const response = await API.post(
            `/api/formSubmissions/updateFormSubmission`,
            data
        );
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const getVendorById = async (vendorId: any) => {
    try {
        const response = await API.get(`/api/vendors/getVendor/${vendorId}`);
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const createVendorUser = async (data: any) => {
    try {
        const response = await API.post(`/api/vendors/createVendorUser`, data);
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const getAllVendorUser = async (vendorId: string) => {
    try {
        const response = await API.get(
            `/api/vendors/getAllVendorUser/${vendorId}`
        );
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

// API -> /api/dashboard

export const getAllRiskDashboard = async () => {
    try {
        const response = await API.get(`/api/dashboard/riskSummary`);
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const getAllRegistryDashboard = async () => {
    try {
        const response = await API.get(`/api/dashboard/registrySummary`);
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

// API -> /api/activities

export const getActivitiesById = async (id: string, activitySource: string) => {
    try {
        const response = await API.post(`/api/activities/${id}/type/${activitySource}`,{});
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

// API -> /api/email

export const sendCustomEmail = async (data: any) => {
    try {
        const response = await API.post(`/api/email/sendCustomEmail`, data);
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const getEmailTemplates = async () => {
    try {
        const response = await API.get(`/api/email/getEmailTemplates`);
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const emailTemplateAction = async (data: any) => {
    try {
        const response = await API.post(`/api/email/emailTemplate`, data);
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const saveTemplateMapping = async (data: any) => {
    try {
        const response = await API.post(`/api/email/saveTemplateMapping`, data);
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};

export const searchEntries = async (
    name: string,
    type: string,
    status: string[],
    registryType?: string,
    page: number = 0,
    size: number = 10,
    sort: string = 'status'
) => {
    try {
        const encodedName = encodeURIComponent(name);
        const response = await API.get(
            `/api/dashboard/search?name=${encodedName}&type=${type}&status=${status}&registryType=${registryType}&page=${page}&size=${size}&sort=${sort}`
        );
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        return axiosError.response?.data;
    }
};
