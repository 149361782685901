import { Stack, TextFieldProps, styled } from '@mui/material';
import { ReactNode } from 'react';
import Typography from '../../atoms/Typography';
import InputField from '../../atoms/InputField';
import theme from '../../../theme';

interface TextInputFieldProps {
    labelvalue: string;
    manditoryfield?: string;
    startAdornment?: ReactNode;
    endAdornment?: ReactNode;
    typoelement?: ReactNode;
}
const StyledStack = styled(Stack)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
});

const StyledTextField = styled(InputField)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.structuralColors.lightGray,
        },
        '&:hover fieldset': {
            borderColor: theme.palette.structuralColors.gray,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary[500],
            boxShadow: `0px 0px 8px 2px ${theme.palette.primary[400]}`,
        },
    },
});

const TypoInput = (props: TextFieldProps & TextInputFieldProps) => {
    const manditoryfieldValue = props.manditoryfield;
    let showAsterisk = false;
    if (manditoryfieldValue === 'true') {
        showAsterisk = true;
    }
    return (
        <StyledStack spacing={'6px'}>
            <Stack
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <Typography
                    variant="body1"
                    color={theme.palette.text.highEmphasis}
                >
                    {props.labelvalue}
                    {showAsterisk && (
                        <span style={{ color: 'red' }}>{' *'}</span>
                    )}
                </Typography>
                {props.typoelement}
            </Stack>

            <StyledTextField
                inputProps={{
                    style: {
                        color: theme.palette.text.mediumEmphasis,
                    },
                }}
                height="2.125rem"
                fullWidth
                manditoryfield={props.manditoryfield ? 'true' : 'false'}
                {...props}
            />
        </StyledStack>
    );
};

export default TypoInput;
