import React from 'react';
import PropTypes from 'prop-types';
import {
    Collapse,
    Divider,
    List,
    ListItemIcon,
    ListItemText,
    Stack,
    styled,
    Tooltip,
    tooltipClasses,
    TooltipProps,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AppMenuItemComponent from './AppMenuItemComponent';
import theme from '../../../theme';

export const AppMenuItemPropTypes = {
    name: PropTypes.string.isRequired,
    link: PropTypes.string,
    Icon: PropTypes.elementType,
    SubIcon: PropTypes.elementType,
    items: PropTypes.array,
};

type AppMenuItemPropTypes = PropTypes.InferProps<typeof AppMenuItemPropTypes>;
type AppMenuItemPropsWithoutItems = Omit<AppMenuItemPropTypes, 'items'>;

export type AppMenuItemProps = AppMenuItemPropsWithoutItems & {
    items?: AppMenuItemProps[];
    openState?: boolean;
};

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));

const MenuItemIconStyle = styled(ListItemIcon)({
    color: theme.palette.structuralColors.gray,
    minWidth: '45px',
});
const MenuItemStyle = styled(AppMenuItemComponent)({
    '&.active': {
        background: theme.palette.accentColors.lightBlue,
        '& .MuiListItemIcon-root': {
            color: theme.palette.accentColors.blue,
        },
        '& .MuiTypography-root': {
            color: theme.palette.accentColors.blue,
        },
    },
});

const AppMenuItem: React.FC<AppMenuItemProps> = (props) => {
    const { name, link, Icon, SubIcon, items = [] } = props;
    const isExpandable = items && items.length > 0;
    const [open, setOpen] = React.useState(false);

    function handleClick() {
        setOpen(!open);
    }

    const MenuItemRoot = (
        <MenuItemStyle link={link} onClick={handleClick}>
            {!!Icon && (
                <BootstrapTooltip
                    title={name}
                    placement="right"
                    slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, -7],
                                    },
                                },
                            ],
                        },
                    }}
                >
                    <MenuItemIconStyle>
                        <Stack direction={'row'}>
                            <Icon />
                            {!props.openState && SubIcon && (
                                <SubIcon style={{ width: 15 }} />
                            )}
                        </Stack>
                    </MenuItemIconStyle>
                </BootstrapTooltip>
            )}
            <ListItemText primary={name} inset={!Icon} />
            {/* Display the expand menu if the item has children */}
            {isExpandable && !open && <ExpandMoreIcon />}
            {isExpandable && open && <ExpandLessIcon />}
        </MenuItemStyle>
    );

    const MenuItemChildren = isExpandable ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider />
            <List component="div" disablePadding>
                {items.map((item, index) => (
                    <AppMenuItem {...item} key={index} />
                ))}
            </List>
            <Divider />
        </Collapse>
    ) : null;

    return (
        <>
            {MenuItemRoot}
            {MenuItemChildren}
        </>
    );
};

export default AppMenuItem;
