import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
    GridColDef,
    GridColumnVisibilityModel,
    GridRenderCellParams,
} from '@mui/x-data-grid';
import {
    Stack,
    Chip,
    Box,
    IconButton,
    Menu,
    MenuItem,
    Drawer,
    Divider,
    Autocomplete,
    TextField,
    styled,
} from '@mui/material';
import dayjs from 'dayjs';
import {
    getAllFormSubmissions,
    updateFormSubmission,
} from '../../../services/api';
import ClientSideDataTable from '../ClientSideDataTable';
import { StyledGridOverlay } from '../../../utils/helper';
import theme from '../../../theme';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '../../atoms/Button';
import { useNavigate } from 'react-router-dom';
import Typography from '../../atoms/Typography';
import Icon from '../../atoms/Icon';
import CrossIcon from '../../../../public/assets/icons/cross.svg';
import InputField from '../../atoms/InputField';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import useSnackBar from '../../../utils/hooks';
import CustomSnackbar from '../../molecules/CustomSnackbar';

interface AllAssessmentsTabProps {
    vendorId: string;
    vendorUsers: any;
    internalUsers: any;
}

const TableRowStyleStack = styled(Stack)({
    justifyContent: 'center',
    height: '100%',
});

const AllAssessmentsTab = (props: AllAssessmentsTabProps) => {
    const navigate = useNavigate();
    const [formSubmissions, setFormSubmissions] = useState<any[]>([]);
    const [filteredSubmissions, setFilteredSubmissions] = useState<any[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<string>('Active');
    const [isLoading, setIsLoading] = useState(true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [editDrawerOpen, setEditDrawerOpen] = useState(false);
    const [selectedSubmission, setSelectedSubmission] = useState<any>(null);
    const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
    const [initialFormData, setInitialFormData] = useState({
        assessmentName: '',
        endDate: null,
        status: '',
        respondentId: '',
        formType: '',
    });
    const [formData, setFormData] = useState({
        assessmentName: '',
        endDate: null,
        status: '',
        respondentId: '',
        formType: '',
    });
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [columnVisibilityModel, setColumnVisibilityModel] =
        React.useState<GridColumnVisibilityModel>({
            riskScore: false,
        });
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const { openSnackBar, handleClick, handleClose } = useSnackBar();

    const vendorUsers = props.vendorUsers || [];
    const internalUsers = props.internalUsers || [];

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleMenuClick = (
        event: React.MouseEvent<HTMLButtonElement>,
        rowId: string
    ) => {
        setAnchorEl(event.currentTarget);
        setSelectedRowId(rowId); // Store the selected row id
    };

    const handleModalClose = () => {
        setAnchorEl(null);
    };

    const fetchData = async () => {
        const response = await getAllFormSubmissions(props.vendorId);
        if (response) {
            setFormSubmissions(response);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [props.vendorId]);

    useEffect(() => {
        const filtered = formSubmissions.filter(
            (submission) => mapStatus(submission.status) === selectedStatus
        );
        setFilteredSubmissions(filtered);
    }, [selectedStatus, formSubmissions]);

    const handleFormClick = useCallback(
        (formSubmissionsId: string, formId: string) => () => {
            navigate(`/u/formSubmission?id=${formSubmissionsId}`);
        },
        []
    );

    const handleDateChange = (date: Dayjs | null) => {
        setFormData({
            ...formData,
            endDate: date,
        });
    };

    const handleEditDrawerOpen = () => {
        if (selectedRowId) {
            // Find the full form submission data from the formSubmissions state using the selectedRowId
            const fullSubmission = formSubmissions.find(
                (submission) => submission.id === selectedRowId
            );

            if (fullSubmission) {
                // Extract respondentId if respondents exist
                const respondentId =
                    fullSubmission.respondents &&
                    fullSubmission.respondents.length > 0
                        ? fullSubmission.respondents[0].id
                        : ''; // Default to empty if no respondents

                // Set form data based on the full form submission data
                const initialData = {
                    assessmentName: fullSubmission.assessmentName,
                    startDate: dayjs(fullSubmission.startDate).format(
                        'DD/MM/YYYY'
                    ),
                    status: fullSubmission.status,
                    endDate: dayjs(fullSubmission.dueDate),
                    respondentId,
                    formType: fullSubmission.formType,
                };

                setInitialFormData(initialData);
                setFormData(initialData);
                setSelectedSubmission(fullSubmission); // Store the full submission for further operations
                setEditDrawerOpen(true);
            }
        }
    };

    const handleEditDrawerClose = () => {
        setEditDrawerOpen(false);
        setIsSaveEnabled(false);
    };

    const handleRespondentChange = (event: any, value: any) => {
        setFormData({ ...formData, respondentId: value?.id || '' });
    };

    const handleFormSubmissionUpdate = async () => {
        const data = {
            formSubmissionId: selectedSubmission.id,
            assessmentName: formData.assessmentName,
            newDate: formData.endDate,
            status: formData.status,
            respondentId: formData.respondentId,
        };
        const response = await updateFormSubmission(data);
        if (response) {
            setMessage('Form details updated successfully.');
            handleClick();
            setSuccess(true);
            fetchData();
        } else {
            setMessage('Error updating the form details.');
            handleClick();
            setSuccess(false);
        }
        handleEditDrawerClose();
    };

    const columns = useMemo<GridColDef[]>(
        () => [
            {
                field: 'assessmentName',
                headerName: 'Assessment Name',
                flex: 1.5,
                renderCell: (params: GridRenderCellParams) => (
                    <TableRowStyleStack>
                        <Button
                            onClick={handleFormClick(
                                params.row.id,
                                params.row.formId
                            )}
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'inherit',
                                },
                                textWrap: 'wrap',
                                width: '100%',
                                justifyContent: 'flex-start',
                                padding: '0px',
                            }}
                            disableElevation
                            disableRipple
                        >
                            <Typography
                                variant="body2"
                                color={theme.palette.accentColors.blue}
                                sx={{ textTransform: 'none' }}
                            >
                                <u>{params.value}</u>
                            </Typography>
                        </Button>
                    </TableRowStyleStack>
                ),
            },
            {
                field: 'formName',
                headerName: 'Form Name',
                flex: 1,
                renderCell: (params) => (
                    <TableRowStyleStack>
                        <Typography variant="body2">{params.value}</Typography>
                    </TableRowStyleStack>
                ),
            },
            {
                field: 'formType',
                headerName: 'Form Type',
                flex: 0.7,
                renderCell: (params) => (
                    <TableRowStyleStack>
                        <Typography variant="body2">{params.value}</Typography>
                    </TableRowStyleStack>
                ),
            },
            {
                field: 'startDate',
                headerName: 'Start Date',
                flex: 0.8,
                renderCell: (params) => (
                    <TableRowStyleStack>
                        <Typography variant="body2">{params.value}</Typography>
                    </TableRowStyleStack>
                ),
            },
            {
                field: 'endDate',
                headerName: 'End Date',
                flex: 0.8,
                renderCell: (params) => (
                    <TableRowStyleStack>
                        <Typography variant="body2">{params.value}</Typography>
                    </TableRowStyleStack>
                ),
            },
            {
                field: 'status',
                headerName: 'Status',
                flex: 0.7,
                renderCell: (params: GridRenderCellParams) => {
                    const formatStatus = (status: string) => {
                        if (!status) return '';

                        return status
                            .toLowerCase()
                            .replace(/_/g, ' ')
                            .replace(/\b\w/g, (char) => char.toUpperCase());
                    };
                    return (
                        <TableRowStyleStack>
                            <Typography variant="body2">
                                {formatStatus(params.value)}
                            </Typography>
                        </TableRowStyleStack>
                    );
                },
            },
            {
                field: 'progress',
                headerName: 'Progress',
                flex: 1,
                renderCell: (params) => (
                    <Stack
                        direction="row"
                        sx={{
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            height: '100%',
                        }}
                    >
                        <Typography variant="body2">
                            {`${params.row.totalAnswerValue} of ${params.row.totalQuestion}`}
                        </Typography>
                        <progress
                            value={params.row.totalAnswerValue}
                            max={params.row.totalQuestion}
                            style={{ minWidth: '60px', maxWidth: '60px' }}
                        />
                    </Stack>
                ),
            },
            {
                field: 'contact',
                headerName: 'Contact',
                flex: 1.8,
                renderCell: (params) => (
                    <TableRowStyleStack>
                        <Typography variant="body2">{params.value}</Typography>
                    </TableRowStyleStack>
                ),
            },
            {
                field: 'riskScore',
                headerName: 'Score',
                flex: 0.5,
                align: 'center',
                renderCell: (params) => (
                    <TableRowStyleStack>
                        <Typography variant="body2">{params.value}</Typography>
                    </TableRowStyleStack>
                ),
            },
            {
                field: 'actions',
                type: 'actions',
                flex: 0.1,
                getActions: (params) => [
                    <IconButton
                        key={params.id}
                        onClick={(event) =>
                            handleMenuClick(event, params.row.id)
                        }
                    >
                        <MoreVertIcon />
                    </IconButton>,
                ],
            },
        ],
        [handleFormClick, handleMenuClick]
    );

    const mapStatus = (status: string) => {
        switch (status) {
            case 'IN_PROGRESS':
                return 'Active';
            case 'COMPLETED':
                return 'Completed';
            case 'OVERDUE':
                return 'Overdue';
            case 'REOPENED':
                return 'Reopened';
            case 'SUBMITTED':
                return 'Submitted';
            default:
                return status;
        }
    };

    const handleStatusChange = (event: any) => {
        setFormData({ ...formData, status: event.target.value });
    };

    useEffect(() => {
        const isFormDataChanged =
            JSON.stringify(initialFormData) !== JSON.stringify(formData);

        const isEndDateValid = formData.endDate
            ? dayjs(formData.endDate).isAfter(dayjs())
            : false;

        if (
            initialFormData.status === 'SUBMITTED' &&
            formData.status === 'REOPENED' &&
            isEndDateValid
        ) {
            setIsSaveEnabled(true);
        } else if (
            initialFormData.status === 'OVERDUE' &&
            isEndDateValid &&
            isFormDataChanged
        ) {
            setIsSaveEnabled(true);
        } else if (
            (initialFormData.status === 'IN_PROGRESS' ||
                initialFormData.status === 'REOPENED') &&
            isFormDataChanged
        ) {
            setIsSaveEnabled(true);
        } else if (
            initialFormData.status === 'COMPLETED' &&
            formData.status === 'REOPENED' &&
            isEndDateValid
        ) {
            setIsSaveEnabled(true);
        }
    }, [formData, initialFormData]);

    const rows = filteredSubmissions.map((submission) => ({
        id: submission.id,
        formName: submission.formName,
        assessmentName: submission.assessmentName,
        formType: submission.formType,
        startDate: dayjs(submission.startDate).format('DD/MM/YYYY'),
        endDate: dayjs(submission.dueDate).format('DD/MM/YYYY'),
        status: submission.status,
        totalAnswerValue: submission.totalAnswerValue,
        totalQuestion: submission.totalQuestion,
        contact: submission.respondents
            .map((resp: any) => `${resp.name}\n(${resp.email})`)
            .join(', '),
        formId: submission.formId,
        riskScore: submission.sumOfAnswerValues,
    }));

    const handleChipClick = (status: string) => {
        if (status === 'Completed') {
            setColumnVisibilityModel({
                riskScore: true,
            });
        } else {
            setColumnVisibilityModel({
                riskScore: false,
            });
        }
        setSelectedStatus(status);
    };

    const countByStatus = (status: string) => {
        return formSubmissions.filter(
            (submission) => mapStatus(submission.status) === status
        ).length;
    };

    function CustomNoRowsOverlay() {
        return (
            <StyledGridOverlay>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="body1">
                        {'No Assessments Found'}
                    </Typography>
                </Box>
            </StyledGridOverlay>
        );
    }

    return (
        <Stack spacing={'20px'} padding={'10px'}>
            <Stack
                spacing={'15px'}
                sx={{
                    border: `1px solid ${theme.palette.structuralColors.gray}`,
                    padding: '15px 10px',
                    borderRadius: '3px',
                }}
            >
                <Stack direction="row" spacing={'4px'}>
                    <Chip
                        label={`Active (${countByStatus('Active')})`}
                        onClick={() => handleChipClick('Active')}
                        color={
                            selectedStatus === 'Active' ? 'primary' : 'default'
                        }
                    />
                    <Chip
                        label={`Reopened (${countByStatus('Reopened')})`}
                        onClick={() => handleChipClick('Reopened')}
                        color={
                            selectedStatus === 'Reopened'
                                ? 'primary'
                                : 'default'
                        }
                    />
                    <Chip
                        label={`Completed (${countByStatus('Completed')})`}
                        onClick={() => handleChipClick('Completed')}
                        color={
                            selectedStatus === 'Completed'
                                ? 'primary'
                                : 'default'
                        }
                    />
                    <Chip
                        label={`Overdue (${countByStatus('Overdue')})`}
                        onClick={() => handleChipClick('Overdue')}
                        color={
                            selectedStatus === 'Overdue' ? 'primary' : 'default'
                        }
                    />
                    <Chip
                        label={`Submitted (${countByStatus('Submitted')})`}
                        onClick={() => handleChipClick('Submitted')}
                        color={
                            selectedStatus === 'Submitted'
                                ? 'primary'
                                : 'default'
                        }
                    />
                </Stack>
                {filteredSubmissions.length > 0 ? (
                    <ClientSideDataTable
                        columns={columns}
                        rowsData={rows}
                        isLoading={isLoading}
                        checkboxSelection={false}
                        hideFooter={false}
                        slotProps={{
                            loadingOverlay: {
                                variant: 'skeleton',
                                noRowsVariant: 'skeleton',
                            },
                        }}
                        slots={{
                            noRowsOverlay: CustomNoRowsOverlay,
                        }}
                        resizeThrottleMs={190}
                        pageSizeOptions={[5, 10, 25, 50, 75, 100]}
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 10, page: 0 },
                            },
                        }}
                        getRowHeight={() => 'auto'}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                    />
                ) : (
                    <Stack
                        sx={{
                            border: '1px dashed #D3D3D3',
                            backgroundColor: '#F8F9FA',
                        }}
                    >
                        <Typography
                            variant="subtitle1"
                            align="center"
                            sx={{ mt: 4 }}
                            color={theme.palette.text.mediumEmphasis}
                        >
                            No Assessments Found
                        </Typography>
                    </Stack>
                )}
            </Stack>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleModalClose}
                MenuListProps={{ 'aria-labelledby': 'basic-button' }}
            >
                <MenuItem
                    onClick={() => {
                        handleEditDrawerOpen();
                        handleModalClose();
                    }}
                >
                    Edit
                </MenuItem>
            </Menu>
            <Drawer
                open={editDrawerOpen}
                anchor={'right'}
                onClose={handleEditDrawerClose}
            >
                <Stack sx={{ width: '45vw', height: '100%' }}>
                    <Stack
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '15px 10px',
                        }}
                    >
                        <Box sx={{ paddingTop: '5px' }}>
                            <Typography variant="h3">{'Edit Form'}</Typography>
                        </Box>
                        <IconButton onClick={handleEditDrawerClose}>
                            <Icon src={CrossIcon} alt={'Close Icon'}></Icon>
                        </IconButton>
                    </Stack>
                    <Divider
                        style={{
                            borderColor: theme.palette.structuralColors.gray,
                        }}
                    />
                    <Stack sx={{ padding: '15px 10px' }} spacing={'15px'}>
                        <InputField
                            size="small"
                            label="Assessment Name"
                            name="assessmentName"
                            value={formData.assessmentName}
                            onChange={handleInputChange}
                            fullWidth
                        />
                        {(selectedSubmission?.status === 'SUBMITTED' ||
                            selectedSubmission?.status === 'COMPLETED') && (
                            <TextField
                                size="small"
                                label="Status"
                                name="status"
                                value={formData.status}
                                onChange={handleStatusChange}
                                fullWidth
                                select
                            >
                                <MenuItem
                                    value={selectedSubmission?.status}
                                    disabled
                                >
                                    {selectedSubmission?.status}
                                </MenuItem>
                                <MenuItem value="REOPENED">REOPENED</MenuItem>
                            </TextField>
                        )}
                        <Stack
                            direction={'row'}
                            sx={{
                                justifyContent: 'space-between',
                                width: '100%',
                            }}
                            spacing={'10px'}
                        >
                            <InputField
                                size="small"
                                label="Start Date"
                                name="startDate"
                                value={formData.startDate}
                                sx={{ width: '50%' }}
                                disabled
                            />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="End Date"
                                    value={formData.endDate}
                                    onChange={(newValue) =>
                                        handleDateChange(newValue)
                                    }
                                    slotProps={{
                                        textField: {
                                            size: 'small',
                                            sx: { width: '50%' },
                                        },
                                    }}
                                    format="DD/MM/YY"
                                    disablePast
                                />
                            </LocalizationProvider>
                        </Stack>
                        <Autocomplete
                            size="small"
                            options={
                                formData.formType === 'EXTERNAL'
                                    ? vendorUsers
                                    : internalUsers
                            }
                            getOptionLabel={(option: any) =>
                                `${option.name} (${option.email})`
                            }
                            value={
                                (formData.formType === 'EXTERNAL'
                                    ? vendorUsers
                                    : internalUsers
                                ).find(
                                    (user: any) =>
                                        user.id === formData.respondentId
                                ) || null
                            }
                            onChange={handleRespondentChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Respondent"
                                    placeholder="Select Respondent"
                                />
                            )}
                        />
                        <Button
                            onClick={handleFormSubmissionUpdate}
                            variant="outlined"
                            disabled={!isSaveEnabled}
                        >
                            Save
                        </Button>
                    </Stack>
                </Stack>
            </Drawer>
            <CustomSnackbar
                open={openSnackBar}
                message={message}
                success={success}
                onClose={handleClose}
                autoHideDuration={1800}
            />
        </Stack>
    );
};

export default AllAssessmentsTab;
