import { IconButton, Stack, styled, Typography } from '@mui/material';
import TableHeader from '../../components/organisms/TableHeader';
import MainTemplate from '../../components/templates/MainTemplate';
import { manageRiskRegister, searchEntries } from '../../services/api';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import useSnackBar from '../../utils/hooks';
import TypoInput from '../../components/molecules/TypoInput';
import GenericModal from '../../components/organisms/GenericModal';
import theme from '../../theme';
import { validateTitleFormat } from '../../utils/validations';
import Button from '../../components/atoms/Button';
import CloseIcon from '@mui/icons-material/Close';
import { ProcessedRiskData, RiskData } from '../../utils/types';
import { getRiskRegisterColumns } from '../../utils/helper';
import { RiskFilterFieldValues } from '../../utils/constants';
import { debounce } from 'lodash';
import DataTable from '../../components/organisms/DataTable';
import CustomSnackbar from '../../components/molecules/CustomSnackbar';

const StyledButton = styled(Button)({
    padding: '5px 8px',
    width: '100px',
});

const RiskRegistersPage = () => {
    const navigate = useNavigate();
    const [reportList, setReportList] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [riskRegisterName, setRiskRegisterName] = useState('');
    const [errors, setErrors] = useState({ riskRegisterName: '' });
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const { openSnackBar, handleClick, handleClose } = useSnackBar();
    const [searchQuery, setSearchQuery] = useState('');
    const [statusList, setStatusList] = useState<string[]>([]);
    const [fetchNewData, setFetchNewData] = useState(false);

    const fetchData = async (query: string, statusFilter: string[]) => {
        setIsLoading(true);
        const response = await searchEntries(
            query,
            'riskregister',
            statusFilter,
            '',
            paginationModel.page,
            paginationModel.pageSize,
            'createdAt'
        );
        if (response) {
            setReportList(response.content);
            setRowCount(response.totalElements);
        }
        setIsLoading(false);
    };

    const debouncedFetchData = useCallback(
        debounce((query: string, statusFilter: string[]) => {
            fetchData(query, statusFilter);
        }, 1000),
        [paginationModel.page, paginationModel.pageSize]
    );

    useEffect(() => {
        debouncedFetchData(searchQuery, statusList);
    }, [searchQuery, statusList, debouncedFetchData, fetchNewData]);

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => {
        setModalOpen(false);
        setRiskRegisterName('');
        setErrors({ riskRegisterName: '' });
    };

    const handleValueChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { value } = e.target;
        setRiskRegisterName(value);

        const charCount = value.length;

        if ((value && !validateTitleFormat(value)) || charCount > 200) {
            setErrors({ riskRegisterName: 'Invalid Input' });
        } else {
            setErrors({ riskRegisterName: '' });
        }
    };

    const handleAddRiskRegister = async () => {
        if (riskRegisterName.trim().length < 2) {
            setErrors({
                riskRegisterName: 'Name must have atleast least 2 characters',
            });
            return;
        }

        const response = await manageRiskRegister({
            name: riskRegisterName,
            type: 'CREATE',
        });
        if (response) {
            setMessage('Risk created successfully');
            handleClick();
            setSuccess(true);
            setFetchNewData(!fetchNewData);
        } else {
            setMessage('Failed to create risk. Please try again.');
            handleClick();
            setSuccess(false);
        }
        handleModalClose();
    };

    const ProfileTableHeaderOption = [
        {
            id: 1,
            label: 'Add Risk',
            icon: <ControlPointIcon />,
            onClick: () => handleModalOpen(),
        },
    ];

    const processRiskData = (data: RiskData[]): ProcessedRiskData[] => {
        return data.map((item) => ({
            id: item.id,
            referenceId: item.referenceId,
            name: item.name,
            updatedAt: item.updatedAt,
            status: item.status,
            riskCategory: item.riskCategory,
            riskSubCategory: item.riskSubCategory,
            beforeRisk:
                item.beforeRTPRValue !== null &&
                item.beforeRTPRiskRating !== null
                    ? `${item.beforeRTPRiskRating} (${item.beforeRTPRValue})`
                    : ' ---',
            afterRisk:
                item.afterRTPRValue !== null && item.afterRTPRiskRating !== null
                    ? `${item.afterRTPRiskRating} (${item.afterRTPRValue})`
                    : ' ---',
        }));
    };

    const handleFilterChange = (list: string[]) => {
        setStatusList(list);
    };

    return (
        <MainTemplate
            main={
                <Stack
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <TableHeader
                        headerName={'List of Risk'}
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        handleFilterChange={handleFilterChange}
                        filterList={RiskFilterFieldValues}
                        menuItems={ProfileTableHeaderOption}
                        searchVisible={true}
                    />
                    <DataTable
                        columns={getRiskRegisterColumns((row) => {
                            navigate(`/u/riskregisterdetails?id=${row.id}`);
                        })}
                        rowsData={processRiskData(reportList) || []}
                        rowCount={rowCount}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        isLoading={isLoading}
                        checkboxSelection={false}
                        sx={{ flexGrow: 1 }}
                        resizeThrottleMs={190}
                    />
                    <GenericModal
                        open={modalOpen}
                        style={{
                            backgroundColor:
                                theme.palette.structuralColors.white,
                            padding: '10px',
                            borderRadius: '5px',
                            width: '25vw',
                        }}
                    >
                        <Stack spacing={'10px'}>
                            <Stack
                                sx={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography variant="subtitle1">
                                    {'Create a new Risk'}
                                </Typography>
                                <IconButton onClick={handleModalClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Stack>
                            <TypoInput
                                labelvalue="Risk Name"
                                placeholder={'Risk Name'}
                                value={riskRegisterName}
                                onChange={(e) => handleValueChange(e)}
                                manditoryfield="true"
                            />
                            {errors.riskRegisterName && (
                                <Typography variant="caption2" color={'red'}>
                                    {errors.riskRegisterName}
                                </Typography>
                            )}
                            <Stack
                                direction={'row'}
                                spacing={'10px'}
                                sx={{ paddingTop: '20px' }}
                            >
                                <StyledButton
                                    variant="outlined"
                                    onClick={handleModalClose}
                                >
                                    <Typography variant="caption1">
                                        {'Cancel'}
                                    </Typography>
                                </StyledButton>
                                <StyledButton
                                    variant="outlined"
                                    onClick={handleAddRiskRegister}
                                    disabled={
                                        errors.riskRegisterName !== '' ||
                                        riskRegisterName.trim().length < 2
                                    }
                                >
                                    <Typography variant="caption1">
                                        {'Save'}
                                    </Typography>
                                </StyledButton>
                            </Stack>
                        </Stack>
                    </GenericModal>
                    <CustomSnackbar
                        open={openSnackBar}
                        message={message}
                        success={success}
                        onClose={handleClose}
                        autoHideDuration={2500}
                    />
                </Stack>
            }
        />
    );
};

export default RiskRegistersPage;
