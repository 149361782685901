import React, { useEffect, useState } from 'react';
import {
    Typography,
    Stack,
    styled,
    TextField,
    Chip,
    Autocomplete,
    Divider,
} from '@mui/material';
import InputField from '../../atoms/InputField';
import theme from '../../../theme';
import Button from '../../atoms/Button';
import { Teams } from '../../../utils/constants';
import { updateRiskProcess } from '../../../services/api';
import useSnackBar from '../../../utils/hooks';
import CustomSnackbar from '../../molecules/CustomSnackbar';

const StyledButton = styled(Button)({
    padding: '5px 8px',
    width: '160px',
});

interface FormData {
    isoControlMapping: string;
    riskCategory: string;
    riskSubCategory: string;
    riskOwnersTeam: string[];
    riskEvent: string;
    causes: string;
    consequences: string;
}

interface RiskIdentificationComponentProps {
    riskId: string;
    riskData: any;
    fetchData: () => void;
}

const RiskIdentificationComponent = (
    props: RiskIdentificationComponentProps
) => {
    const [formData, setFormData] = useState<FormData>({
        isoControlMapping: '',
        riskCategory: '',
        riskSubCategory: '',
        riskOwnersTeam: [],
        riskEvent: '',
        causes: '',
        consequences: '',
    });
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const { openSnackBar, handleClick, handleClose } = useSnackBar();
    const riskFormData = props.riskData || [];

    const fetchedData = () => {
        const response = riskFormData;
        if (response) {
            if (response.riskIdentification != null) {
                const data = response.riskIdentification;
                const formattedData = {
                    isoControlMapping: data?.isoControlMapping || '',
                    riskCategory: data?.riskCategory || '',
                    riskSubCategory: data?.riskSubCategory || '',
                    riskOwnersTeam: data?.riskOwnersTeam || [],
                    riskEvent: data?.riskEvent || '', //new
                    causes: data?.causes || '',
                    consequences: data?.consequences || '',
                };
                setFormData(formattedData);
            }
        } else {
            setMessage('Failed to fetch data from server.');
            handleClick();
            setSuccess(false);
        }
    };

    useEffect(() => {
        fetchedData();
    }, []);

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleAutocompleteChange = (
        event: React.ChangeEvent<unknown>,
        value: string[]
    ) => {
        setFormData({
            ...formData,
            riskOwnersTeam: value,
        });
    };

    const handleSave = async () => {
        const response = await updateRiskProcess(
            props.riskId,
            'IDENTIFICATION',
            formData
        );
        if (response && response.success) {
            setMessage(response.message);
            handleClick();
            setSuccess(true);
            props.fetchData();
        } else {
            setMessage('Please fill in all the fields before saving.');
            handleClick();
            setSuccess(false);
        }
    };

    return (
        <Stack spacing={'20px'} padding={'10px'}>
            <Stack
                spacing={'15px'}
                sx={{
                    border: `1px solid ${theme.palette.structuralColors.gray}`,
                    padding: '15px 10px',
                    borderRadius: '3px',
                }}
            >
                <Typography
                    variant="subtitle1"
                    color={theme.palette.text.highEmphasis}
                >
                    {'Risk Identification'}
                </Typography>
                <Divider />
                <InputField
                    size="small"
                    label="ISO Control Mapping"
                    name="isoControlMapping"
                    value={formData.isoControlMapping}
                    onChange={handleInputChange}
                    fullWidth
                />
                <InputField
                    size="small"
                    label="Risk Category"
                    name="riskCategory"
                    value={formData.riskCategory}
                    onChange={handleInputChange}
                    fullWidth
                />
                <InputField
                    size="small"
                    label="Risk Sub-Category"
                    name="riskSubCategory"
                    value={formData.riskSubCategory}
                    onChange={handleInputChange}
                    fullWidth
                />
                <Autocomplete
                    size="small"
                    multiple
                    options={Teams}
                    value={formData.riskOwnersTeam}
                    onChange={handleAutocompleteChange}
                    renderTags={(value: string[], getTagProps) =>
                        value.map((option: string, index: number) => (
                            <div key={option}>
                                <Chip
                                    label={option}
                                    {...getTagProps({ index })}
                                />
                            </div>
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Risk Owner(s) - Team"
                            placeholder="Select teams"
                        />
                    )}
                    fullWidth
                />
                <Stack spacing={'5px'}>
                    <Typography
                        variant="caption1"
                        color={theme.palette.text.mediumEmphasis}
                    >
                        {'Risk Event'}
                    </Typography>
                    <textarea
                        placeholder="Risk Event"
                        name="riskEvent"
                        value={formData.riskEvent}
                        onChange={handleInputChange}
                        style={{
                            fontFamily: 'Mier-Book',
                            fontSize: '16px',
                            padding: '5px',
                            width: '99%',
                            border: `1px solid ${theme.palette.structuralColors.stroke1}`,
                            minHeight: '120px',
                        }}
                    />
                </Stack>
                <Stack spacing={'5px'}>
                    <Typography
                        variant="caption1"
                        color={theme.palette.text.mediumEmphasis}
                    >
                        {'Cause'}
                    </Typography>
                    <textarea
                        placeholder="Cause"
                        name="causes"
                        value={formData.causes}
                        onChange={handleInputChange}
                        style={{
                            fontFamily: 'Mier-Book',
                            fontSize: '16px',
                            padding: '5px',
                            width: '99%',
                            border: `1px solid ${theme.palette.structuralColors.stroke1}`,
                            minHeight: '120px',
                        }}
                    />
                </Stack>
                <Stack spacing={'5px'}>
                    <Typography
                        variant="caption1"
                        color={theme.palette.text.mediumEmphasis}
                    >
                        {'Consequence'}
                    </Typography>
                    <textarea
                        placeholder="Consequence"
                        name="consequences"
                        value={formData.consequences}
                        onChange={handleInputChange}
                        style={{
                            fontFamily: 'Mier-Book',
                            fontSize: '16px',
                            padding: '5px',
                            width: '99%',
                            border: `1px solid ${theme.palette.structuralColors.stroke1}`,
                            minHeight: '120px',
                        }}
                    />
                </Stack>
                <StyledButton variant="outlined" onClick={handleSave}>
                    <Typography variant="caption1">{'Save'}</Typography>
                </StyledButton>
            </Stack>
            <CustomSnackbar
                open={openSnackBar}
                message={message}
                success={success}
                onClose={handleClose}
                autoHideDuration={1800}
            />
        </Stack>
    );
};

export default RiskIdentificationComponent;
