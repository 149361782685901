import React, { useState } from 'react';
import { Box, Divider, Drawer, IconButton, Stack } from '@mui/material';
import Typography from '../../atoms/Typography';
import theme from '../../../theme';
import Icon from '../../atoms/Icon';
import CrossIcon from '../../../../public/assets/icons/cross.svg';
import FileUpload from './FileUploadComponent';

interface FileUploadModalProps {
    open: boolean;
    onToggle: (isOpen: boolean) => void;
    uploadFrom: string;
    id: string;
    onUploadComplete: (response: any) => void;
    currentFileCount?: number;
}

const FileUploadModal = ({
    open,
    onToggle,
    uploadFrom,
    id,
    onUploadComplete,
    currentFileCount,
}: FileUploadModalProps) => {
    const [isUploading, setIsUploading] = useState(false);

    const handleClose = () => {
        if (!isUploading) {
            onToggle(false);
        }
    };

    const handleUploadComplete = (response: any) => {
        setIsUploading(false);
        onUploadComplete(response);
    };

    return (
        <React.Fragment key={'right'}>
            <Drawer open={open} anchor={'right'} onClose={handleClose}>
                <Stack sx={{ width: '45vw', height: '100%' }}>
                    <Stack
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '15px 10px',
                        }}
                    >
                        <Box sx={{ paddingTop: '5px' }}>
                            <Typography variant="h3">
                                {'Upload Documents'}
                            </Typography>
                        </Box>
                        <IconButton
                            onClick={handleClose}
                            disabled={isUploading}
                        >
                            <Icon src={CrossIcon} alt={'Close Icon'}></Icon>
                        </IconButton>
                    </Stack>
                    <Divider
                        style={{
                            borderColor: theme.palette.structuralColors.gray,
                        }}
                    />
                    <Stack sx={{ padding: '10px' }}>
                        <FileUpload
                            uploadFrom={uploadFrom}
                            id={id}
                            onUploadComplete={handleUploadComplete}
                            currentFileCount={currentFileCount}
                        />
                    </Stack>
                </Stack>
            </Drawer>
        </React.Fragment>
    );
};

export default FileUploadModal;
