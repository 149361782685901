import { Box, Stack, styled } from '@mui/material';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    getFormSubmission,
    getFormSubmissionsByUserId,
} from '../../../services/api';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import Button from '../../atoms/Button';
import theme from '../../../theme';
import ClientSideDataTable from '../ClientSideDataTable';
import { StyledGridOverlay } from '../../../utils/helper';
import Typography from '../../atoms/Typography';
import useSnackBar from '../../../utils/hooks';
import CustomSnackbar from '../../molecules/CustomSnackbar';

interface FillFormProps {
    vendorId: string;
}

const TableRowStyleStack = styled(Stack)({
    justifyContent: 'center',
    height: '100%',
});

const FillForm = (props: FillFormProps) => {
    const navigate = useNavigate();
    const [formSubmissions, setFormSubmissions] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const { openSnackBar, handleClick, handleClose } = useSnackBar();

    useEffect(() => {
        const fetchData = async () => {
            const response = await getFormSubmissionsByUserId(props.vendorId);

            if (response) {
                setFormSubmissions(response);
                setIsLoading(false);
            } else {
                console.log('error');
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleFormClick = useCallback(
        (formSubmissionId: string, formId: string) => async () => {
            const response = await getFormSubmission(formSubmissionId);

            if (response.message === 'success') {
                navigate(`/u/formSubmission?id=${formSubmissionId}`);
            } else {
                if (response.message === 'Overdue') {
                    setMessage(
                        'The due date for this form has passed. Please contact the administrator.'
                    );
                    handleClick();
                    setSuccess(false);
                } else if (response.message === 'Unauthorised') {
                    setMessage('Unauthorised to access the form.');
                    handleClick();
                    setSuccess(false);
                }
            }
        },
        []
    );

    const columns = useMemo<GridColDef[]>(
        () => [
            {
                field: 'assessmentName',
                headerName: 'Assessment Name',
                flex: 2,
                renderCell: (params: GridRenderCellParams) => {
                    return (
                        <Stack
                            sx={{ justifyContent: 'center', height: '100%' }}
                        >
                            <Button
                                onClick={handleFormClick(
                                    params.row.id,
                                    params.row.formId
                                )}
                                sx={{
                                    '&:hover': {
                                        backgroundColor: 'inherit',
                                    },
                                    textWrap: 'wrap',
                                    width: '100%',
                                    justifyContent: 'flex-start',
                                    padding: '0px',
                                }}
                                disableElevation
                                disableRipple
                            >
                                <Typography
                                    variant="body2"
                                    color={theme.palette.accentColors.blue}
                                    sx={{ textTransform: 'none' }}
                                >
                                    <u>{params.value}</u>
                                </Typography>
                            </Button>
                        </Stack>
                    );
                },
            },
            {
                field: 'status',
                headerName: 'Status',
                flex: 1,
                renderCell: (params: GridRenderCellParams) => {
                    const formatStatus = (status: string) => {
                        if (!status) return '';

                        return status
                            .toLowerCase()
                            .replace(/_/g, ' ')
                            .replace(/\b\w/g, (char) => char.toUpperCase());
                    };
                    return (
                        <TableRowStyleStack>
                            <Typography variant="body2">
                                {formatStatus(params.value)}
                            </Typography>
                        </TableRowStyleStack>
                    );
                },
            },
            {
                field: 'endDate',
                headerName: 'End Date',
                flex: 1,
                renderCell: (params) => (
                    <TableRowStyleStack>
                        <Typography variant="body2">{params.value}</Typography>
                    </TableRowStyleStack>
                ),
            },
            {
                field: 'progress',
                headerName: 'Progress',
                flex: 1,
                renderCell: (params) => (
                    <Stack
                        direction="row"
                        spacing={'5px'}
                        sx={{
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            height: '100%',
                        }}
                    >
                        <Typography variant="body2">{`${params.row.totalAnswerValue} of ${params.row.totalQuestion}`}</Typography>
                        <progress
                            value={params.row.totalAnswerValue}
                            max={params.row.totalQuestion}
                            style={{ width: '100px' }}
                        />
                    </Stack>
                ),
            },
            {
                field: 'contact',
                headerName: 'User',
                flex: 2,
                renderCell: (params) => (
                    <TableRowStyleStack>
                        <Typography variant="body2">{params.value}</Typography>
                    </TableRowStyleStack>
                ),
            },
        ],
        [handleFormClick]
    );

    const rows = formSubmissions.map((submission) => ({
        id: submission.id,
        assessmentName: submission.assessmentName,
        startDate: dayjs(submission.startDate).format('DD/MM/YYYY'),
        endDate: dayjs(submission.dueDate).format('DD/MM/YYYY'),
        totalAnswerValue: submission.totalAnswerValue,
        status: submission.status,
        totalQuestion: submission.totalQuestion,
        contact: submission.respondents
            .map((resp: any) => `${resp.name}\r\n(${resp.email})`)
            .join(', '),
        formId: submission.formId,
    }));

    function CustomNoRowsOverlay() {
        return (
            <StyledGridOverlay>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="body1">
                        {'No Assessments Found'}
                    </Typography>
                </Box>
            </StyledGridOverlay>
        );
    }

    return (
        <Stack>
            <ClientSideDataTable
                columns={columns}
                rowsData={rows}
                isLoading={isLoading}
                checkboxSelection={false}
                hideFooter={false}
                slotProps={{
                    loadingOverlay: {
                        variant: 'skeleton',
                        noRowsVariant: 'skeleton',
                    },
                }}
                slots={{
                    noRowsOverlay: CustomNoRowsOverlay,
                }}
                resizeThrottleMs={190}
                pageSizeOptions={[5, 10, 25, 50, 75, 100]}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 10, page: 0 },
                    },
                }}
            />
            <CustomSnackbar
                open={openSnackBar}
                message={message}
                success={success}
                onClose={handleClose}
                autoHideDuration={2000}
            />
        </Stack>
    );
};

export default FillForm;
