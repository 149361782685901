// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea {
    width: 100%;
    border: 1px solid #cecede;
    background-color: #ffffff;
    border-radius: 5px;
    min-height: 120px;
}

textarea:focus {
    outline: none !important;
    border: 2px solid #9f2089;
    box-shadow: 0px 0px 8px 2px #d9a6d0;
}

textarea:hover {
    border-color: #666666;
}

textarea:focus:hover {
    border-color: #9f2089;
}

textarea::placeholder {
    color: #bdbdc4;
}
`, "",{"version":3,"sources":["webpack://./src/components/organisms/ApproveTab/index.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;IACzB,yBAAyB;IACzB,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,wBAAwB;IACxB,yBAAyB;IACzB,mCAAmC;AACvC;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,cAAc;AAClB","sourcesContent":["textarea {\n    width: 100%;\n    border: 1px solid #cecede;\n    background-color: #ffffff;\n    border-radius: 5px;\n    min-height: 120px;\n}\n\ntextarea:focus {\n    outline: none !important;\n    border: 2px solid #9f2089;\n    box-shadow: 0px 0px 8px 2px #d9a6d0;\n}\n\ntextarea:hover {\n    border-color: #666666;\n}\n\ntextarea:focus:hover {\n    border-color: #9f2089;\n}\n\ntextarea::placeholder {\n    color: #bdbdc4;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
