import { Autocomplete, Stack, styled, TextField } from '@mui/material';
import Typography from '../../atoms/Typography';
import { useEffect, useState } from 'react';
import GenericModal from '../GenericModal';
import InputField from '../../atoms/InputField';
import theme from '../../../theme';
import Button from '../../atoms/Button';
import ConfirmationModal from '../../molecules/ConfirmationModal';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { getEmailTemplates } from '../../../services/api';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '38vw',
    bgcolor: 'background.paper',
    border: '1px solid #D4D4D4',
    boxShadow: 24,
    padding: '15px',
    borderRadius: '7px',
};

const StyledButton = styled(Button)({
    padding: '5px 8px',
    width: '100px',
});

export interface EmailFormData {
    fromEmail: string;
    toEmail: string;
    emailSubject: string;
    emailBody: string;
    attachmentLink: string;
    contactName: string;
    contactEmail: string;
}

interface EmailSenderProps {
    open: boolean;
    onClose: () => void;
    handleEmailSend: (formData: EmailFormData) => void;
}

interface EmailTemplate {
    id: string;
    templateName: string;
    subject: string;
    emailBody: string;
    contactName: string;
    contactEmail: string;
}

const EmailSender = (props: EmailSenderProps) => {
    const [emailTemplates, setEmailTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] =
        useState<EmailTemplate | null>(null);
    const [confirmationOpen, setConfirmationOpen] = useState(false);

    const handleConfirmationModel = () => {
        setConfirmationOpen(!confirmationOpen);
    };

    const [formData, setFormData] = useState({
        fromEmail: 'compliance-team@meesho.com',
        toEmail: '',
        emailSubject: '',
        emailBody: '',
        attachmentLink: '',
        contactName: '',
        contactEmail: '',
    });

    const handleTemplateChange = (
        event: any,
        newValue: EmailTemplate | null
    ) => {
        setSelectedTemplate(newValue);
        if (newValue) {
            setFormData({
                ...formData,
                emailSubject: newValue.subject,
                emailBody: newValue.emailBody,
                contactName: newValue.contactName,
                contactEmail: newValue.contactEmail,
            });
        } else {
            setFormData({
                ...formData,
                emailSubject: '',
                emailBody: '',
                contactName: '',
                contactEmail: '',
            });
        }
    };

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFormReset = () => {
        setFormData({
            fromEmail: 'compliance-team@meesho.com',
            toEmail: '',
            emailSubject: '',
            emailBody: '',
            attachmentLink: '',
            contactName: '',
            contactEmail: '',
        });
        setSelectedTemplate(null);
    };

    const disableSend = () => {
        return (
            formData.fromEmail &&
            formData.toEmail &&
            formData.emailSubject &&
            formData.emailBody &&
            formData.contactName &&
            formData.contactEmail
        );
    };

    const fetchEmailTemplates = async () => {
        const response = await getEmailTemplates();
        if (response) {
            setEmailTemplates(response.emailTemplates);
        } else {
            console.log('EmailTemplates');
        }
    };

    useEffect(() => {
        fetchEmailTemplates();
    }, []);

    return (
        <GenericModal open={props.open}>
            <Stack sx={style} spacing={'12px'}>
                <Stack
                    direction={'row'}
                    sx={{ justifyContent: 'space-between' }}
                >
                    <Typography variant="subtitle1">{'Draft Email'}</Typography>
                    <StyledButton
                        startIcon={<RestartAltIcon />}
                        onClick={handleFormReset}
                        variant="outlined"
                    >
                        {'Reset'}
                    </StyledButton>
                </Stack>
                <Autocomplete
                    size="small"
                    options={emailTemplates}
                    getOptionLabel={(option) => option.templateName}
                    value={selectedTemplate}
                    onChange={handleTemplateChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select Template"
                            placeholder="Templates"
                        />
                    )}
                    ListboxProps={{
                        style: {
                            maxHeight: '180px',
                        },
                    }}
                />
                <Stack
                    direction={'row'}
                    sx={{ justifyContent: 'space-between' }}
                >
                    <Stack
                        direction={'row'}
                        sx={{
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                            width: '49%',
                        }}
                        spacing={'3px'}
                    >
                        <Typography
                            variant="body1"
                            sx={{ paddingBottom: '6px' }}
                        >
                            {'From: '}
                        </Typography>
                        <InputField
                            size="small"
                            label="From Email"
                            name="fromEmail"
                            value={formData.fromEmail}
                            onChange={handleInputChange}
                            fullWidth
                            variant="standard"
                            disabled={true}
                        />
                    </Stack>
                    <Stack
                        direction={'row'}
                        sx={{
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                            width: '49%',
                        }}
                        spacing={'3px'}
                    >
                        <Typography variant="body1">{'To: '}</Typography>
                        <InputField
                            size="small"
                            label="To Email"
                            name="toEmail"
                            value={formData.toEmail}
                            onChange={handleInputChange}
                            variant="standard"
                            fullWidth
                        />
                    </Stack>
                </Stack>
                <InputField
                    size="small"
                    label="Email Subject"
                    name="emailSubject"
                    value={formData.emailSubject}
                    onChange={handleInputChange}
                    fullWidth
                />
                <textarea
                    name="emailBody"
                    placeholder="Email Body"
                    value={formData.emailBody}
                    onChange={handleInputChange}
                    style={{
                        maxWidth: '98%',
                        padding: '5px',
                        border: '1px solid #cecede',
                        color: theme.palette.text.highEmphasis,
                        fontFamily: 'Mier-Book',
                        fontSize: '16px',
                        height: '200px',
                        maxHeight: '200px',
                    }}
                />
                <InputField
                    size="small"
                    label="Attachment Link"
                    name="attachmentLink"
                    value={formData.attachmentLink}
                    onChange={handleInputChange}
                    fullWidth
                />
                <Stack
                    sx={{
                        border: '1px solid #D4D4D4',
                        padding: '10px',
                        borderRadius: '5px',
                    }}
                    spacing={'10px'}
                >
                    <Stack>
                        <Typography variant="body1">
                            {'Contact person details'}
                        </Typography>
                        <Typography
                            variant="body2"
                            color={theme.palette.text.lowEmphasis}
                        >
                            {
                                "Update / provide details of one of your company's personnel to whom the recipient can send queries."
                            }
                        </Typography>
                    </Stack>
                    <InputField
                        size="small"
                        label="Full Name"
                        name="contactName"
                        value={formData.contactName}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <InputField
                        size="small"
                        label="Email"
                        name="contactEmail"
                        value={formData.contactEmail}
                        onChange={handleInputChange}
                        fullWidth
                    />
                </Stack>
                <Stack
                    direction={'row'}
                    spacing={'10px'}
                    sx={{ justifyContent: 'flex-end', alignItems: 'center' }}
                >
                    <StyledButton variant="outlined" onClick={props.onClose}>
                        <Typography variant="caption1">{'Close'}</Typography>
                    </StyledButton>
                    <StyledButton
                        variant="outlined"
                        onClick={() => setConfirmationOpen(true)}
                        disabled={!disableSend()}
                    >
                        <Typography variant="caption1">{'Send'}</Typography>
                    </StyledButton>
                </Stack>
            </Stack>
            <ConfirmationModal
                open={confirmationOpen}
                onClose={handleConfirmationModel}
                onConfirm={() => {
                    const formattedFormData = {
                        ...formData,
                        emailBody: formData.emailBody,
                    };
                    props.handleEmailSend(formattedFormData);
                }}
                onCancel={handleConfirmationModel}
                title={'Are you sure you want to send the email?'}
                confirmText={'Send Email'}
                cancelText={'Back'}
            />
        </GenericModal>
    );
};

export default EmailSender;
