import { TextField, styled } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';
import React, { ReactNode } from 'react';
import theme from '../../../theme';

interface InputFieldProps
    extends Omit<TextFieldProps, 'startAdornment' | 'endAdornment'> {
    startAdornment?: ReactNode;
    endAdornment?: ReactNode;
    width?: string;
    height?: string;
}

const StyledTextField = styled(TextField)({
    color: theme.palette.text.highEmphasis,
});

const InputField = ({
    startAdornment,
    endAdornment,
    ...props
}: InputFieldProps) => {
    return (
        <StyledTextField
            {...props}
            autoComplete={'off'}
            InputProps={{
                startAdornment,
                endAdornment,
                sx: {
                    width: props.width,
                    height: props.height,
                },
            }}
        ></StyledTextField>
    );
};

export default InputField;
