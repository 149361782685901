// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .riskDashboard {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .charts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .chart {
    width: calc(50% - 20px);
    margin-bottom: 40px;
  }
  
  @media (max-width: 768px) {
    .chart {
      width: 100%;
    }
  }
  
  h1, h2, h3 {
    text-align: center;
  }
  
  .total-risks {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
  } */
`, "",{"version":3,"sources":["webpack://./src/pages/DashboardPage/Dashboard.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;KAgCK","sourcesContent":["/* .riskDashboard {\n    max-width: 1200px;\n    margin: 0 auto;\n    padding: 20px;\n  }\n  \n  .charts {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n  }\n  \n  .chart {\n    width: calc(50% - 20px);\n    margin-bottom: 40px;\n  }\n  \n  @media (max-width: 768px) {\n    .chart {\n      width: 100%;\n    }\n  }\n  \n  h1, h2, h3 {\n    text-align: center;\n  }\n  \n  .total-risks {\n    background-color: #f0f0f0;\n    padding: 20px;\n    border-radius: 5px;\n    margin-bottom: 20px;\n  } */\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
