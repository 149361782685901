// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./../public/assets/font/MierB-Book.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Mier-Book';
    src:
        local('Mier-Book'),
        url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('opentype');
}

* {
    margin: 0;
    padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/style.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB;;kEAEoE;AACxE;;AAEA;IACI,SAAS;IACT,UAAU;AACd","sourcesContent":["@font-face {\n    font-family: 'Mier-Book';\n    src:\n        local('Mier-Book'),\n        url('./../public/assets/font/MierB-Book.ttf') format('opentype');\n}\n\n* {\n    margin: 0;\n    padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
