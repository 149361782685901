import { Divider, Stack, styled } from '@mui/material';
import Typography from '../../atoms/Typography';
import Button from '../../atoms/Button';
import theme from '../../../theme';
import { LoginButton, LoginHeading } from '../../../utils/constants';
import { useNavigate } from 'react-router-dom';
import CustomSnackbar from '../CustomSnackbar';
import useSnackBar from '../../../utils/hooks';
import { useEffect, useState } from 'react';

const StyledStack = styled(Stack)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    paddingTop: '20vh',
});
interface LoginTabProps {
    loginLink?: string;
    showMessage?: boolean;
}
const LoginTab = (props: LoginTabProps) => {
    const navigate = useNavigate();
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const { openSnackBar, handleClick, handleClose } = useSnackBar();

    useEffect(() => {
        if (props.showMessage) {
            setMessage(
                'User is unauthorised. For access connect with the administrator.'
            );
            handleClick();
            setSuccess(false);
        }
    }, [props.showMessage]);

    return (
        <StyledStack>
            <Typography variant="h1" color={theme.palette.primary[500]}>
                {LoginHeading}
            </Typography>
            <Button
                sx={{
                    marginTop: '5vh',
                    width: '30%',
                    padding: '8px 16px',
                    backgroundColor: theme.palette.primary[400],
                    '&:hover': {
                        backgroundColor:
                            theme.palette.accentColors.orangeYellow,
                    },
                }}
                href={props.loginLink}
            >
                <Typography
                    variant="subtitle1"
                    color={theme.palette.primary[700]}
                >
                    {LoginButton}
                </Typography>
            </Button>
            <Stack
                sx={{
                    marginTop: '15px',
                    width: '30%',
                }}
            >
                <Divider />
            </Stack>
            <Stack
                sx={{
                    width: '30%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <Typography
                    variant="body1"
                    color={theme.palette.structuralColors.blackgray}
                >
                    {'For vendor login click here -'}
                    <Button
                        onClick={() => navigate('/vendorlogin')}
                        variant="text"
                        sx={{
                            '&:hover': {
                                background: 'none',
                            },
                        }}
                    >
                        <Typography variant="body2">
                            {'Vendor Login'}
                        </Typography>
                    </Button>
                </Typography>
            </Stack>
            <CustomSnackbar
                open={openSnackBar}
                message={message}
                success={success}
                onClose={handleClose}
                autoHideDuration={1800}
            />
        </StyledStack>
    );
};

export default LoginTab;
