import {
    Box,
    Dialog,
    DialogContent,
    Divider,
    IconButton,
    Stack,
    styled,
} from '@mui/material';
import FileUploadModal from '../FileUploadModal';
import { ChangeEvent, useEffect, useState } from 'react';
import Typography from '../../atoms/Typography';
import theme from '../../../theme';
import Button from '../../atoms/Button';
import ClientSideDataTable from '../ClientSideDataTable';
import {
    StyledGridOverlay,
    useRiskDocumentInfoColumns,
} from '../../../utils/helper';
import {
    addRiskEvidence,
    editFileUpload,
    getFileForPreview,
    getRiskRegister,
    getRiskRegisterDocuments,
    sendCustomEmail,
} from '../../../services/api';
import DocumentViewer from '../DocumentViewer';
import useSnackBar from '../../../utils/hooks';
import { DocValues } from '../DocumentTab';
import GenericModal from '../GenericModal';
import TypoInput from '../../molecules/TypoInput';
import CloseIcon from '@mui/icons-material/Close';
import EmailSender, { EmailFormData } from '../EmailSender';
import CustomSnackbar from '../../molecules/CustomSnackbar';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const StyledButton = styled(Button)({
    padding: '5px 8px',
    width: '160px',
});

const GenericModalStyle = {
    backgroundColor: theme.palette.structuralColors.white,
    padding: '10px',
    borderRadius: '5px',
    width: '25vw',
};

const NotesModalStyle = {
    backgroundColor: theme.palette.structuralColors.white,
    padding: '10px',
    borderRadius: '5px',
    width: '50vw',
    height: '50vh',
};

interface RiskEvidenceProps {
    onDocumentIdChange: (docValues: DocValues) => void;
    setRemarkTab: () => void;
    riskId: string;
    userRole: string;
}

const RiskEvidence = (props: RiskEvidenceProps) => {
    const [formData, setFormData] = useState({
        remarks: '',
    });
    const [comments, setComments] = useState([]);
    const [fileUploadOpen, setFileUploadOpen] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [riskEvidenceDocument, setRiskEvidenceDocument] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [fileUrl, setFileUrl] = useState<string | null>(null);
    const [fileNameValue, setFileNameValue] = useState<string | null>(null);
    const [fileModal, setFileModal] = useState(false);
    const [isDocViewerLoading, setIsDocViewerLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const { openSnackBar, handleClick, handleClose } = useSnackBar();
    const [errors, setErrors] = useState({
        linkTitle: '',
        linkUrl: '',
        displayName: '',
    });
    const [editFileData, setEditFileData] = useState({
        displayName: '',
    });
    const [documentId, setDocumentId] = useState('');
    const [documentType, setDocumentType] = useState('');
    const [editFileModalOpen, setEditFileModalOpen] = useState(false);
    const [editDocumentId, setEditDocumentId] = useState('');
    const [openEmailModel, setOpenEmailModel] = useState(false);
    const [notesModalOpen, setNotesModalOpen] = useState(false);
    const [messageQueue, setMessageQueue] = useState<string[]>([]);
    const [currentMessage, setCurrentMessage] = useState<string>('');

    const handleNotesModelOpen = () => {
        setNotesModalOpen(!notesModalOpen);
    };

    const handleEmailModelOpen = () => {
        setOpenEmailModel(true);
    };
    const handleEmailModelClose = () => {
        setOpenEmailModel(false);
    };

    const handleEditFileModalOpen = () => {
        setEditFileModalOpen(!editFileModalOpen);
    };

    useEffect(() => {
        const currentValue: DocValues = {
            docId: documentId,
            type: documentType,
        };
        props.onDocumentIdChange(currentValue);
    }, [documentId, documentType]);

    useEffect(() => {
        if (messageQueue.length > 0 && !openSnackBar) {
            const nextMessage = messageQueue[0];
            setCurrentMessage(nextMessage);
            setMessageQueue((prev) => prev.slice(1));
            handleClick();
        }
    }, [messageQueue, openSnackBar]);

    const showMessage = (message: string, isSuccess: boolean) => {
        setSuccess(isSuccess);
        setMessageQueue((prev) => [...prev, message]);
    };
    const fetchData = async () => {
        const response = await getRiskRegister(props.riskId);

        if (response) {
            const riskResponse = response.riskRegister;
            if (riskResponse.riskEvidence != null) {
                const data = riskResponse.riskEvidence;
                const remarks = data?.remarks || [];
                setComments(remarks);
            }
        } else {
            showMessage('Failed to fetch data from server.', false);
        }
    };

    const fetchDocumentData = async () => {
        const response = await getRiskRegisterDocuments(props.riskId);
        if (response) {
            setRiskEvidenceDocument(response);
            setIsLoading(false);
        } else {
            showMessage('Failed to fetch documents from server.', false);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
        fetchDocumentData();
    }, []);

    const handleFileModalClose = () => {
        setFileUrl(null);
        setFileModal(false);
    };

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handlePreviewOpen = async (id: string, title: string) => {
        const response = await getFileForPreview({ fileId: id });
        if (response) {
            const fileResource = new Blob([response.data], {
                type: 'application/pdf',
            });
            const fileUrl = URL.createObjectURL(fileResource);
            setFileModal(!fileModal);
            setFileNameValue(title);
            setFileUrl(fileUrl);
            setIsDocViewerLoading(false);
        } else {
            showMessage('Error occurred while fetching file', false);
        }
    };

    const handleFileUploadToggle = (isOpen: boolean) => {
        setFileUploadOpen(isOpen);
    };

    const handleUploadComplete = (response: any) => {
        setUploadedFiles(response);
        fetchDocumentData();
    };

    const handleFileNameChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        fieldName: string
    ) => {
        const { value } = event.target;

        setEditFileData((prevState) => ({
            ...prevState,
            [fieldName]: value,
        }));

        const charCount = value.length;

        if (value && charCount > 250) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [fieldName]: 'Invalid URL Input',
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [fieldName]: '',
            }));
        }
    };

    const handleEditCompOpen = (id: string, type: string, title: string) => {
        if (type === 'FILE') {
            setEditFileData({
                displayName: title,
            });
            setEditDocumentId(id);
            handleEditFileModalOpen();
        }
    };

    const handleEditButtonClick = async (
        e: React.MouseEvent<HTMLButtonElement>,
        id: string,
        type: string
    ) => {
        e.stopPropagation();
        if (type === 'FILE') {
            const response = await editFileUpload({
                id: id,
                displayName: editFileData.displayName,
            });
            if (response) {
                showMessage('File name changed successfully.', true);
                fetchDocumentData();
            } else {
                showMessage('Failed to edit file name.', false);
            }
            handleEditFileModalOpen();
        }
    };

    const handleSave = async () => {
        const response = await addRiskEvidence(props.riskId, {
            remarks: formData.remarks,
        });
        if (response && response.success) {
            showMessage(response.message, true);
            setFormData({
                remarks: '',
            });
            fetchData();
        } else {
            showMessage('Please fill in all the fields before saving.', false);
        }
    };

    function CustomNoRowsOverlay() {
        return (
            <StyledGridOverlay>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="body1">
                        {'No Documents Found'}
                    </Typography>
                </Box>
            </StyledGridOverlay>
        );
    }

    const handleEmailSend = async (formData: EmailFormData) => {
        const data = {
            recipientEmail: formData.toEmail,
            attachmentLink: formData.attachmentLink,
            subject: formData.emailSubject,
            emailBody: formData.emailBody,
            contactName: formData.contactName,
            contactEmail: formData.contactEmail,
        };
        const response = await sendCustomEmail(data);
        if (response.success) {
            showMessage(response.message, true);
        } else {
            showMessage('Failed to send email', false);
        }
    };

    const convertUTCtoIST = (utcTimestamp: string) => {
        const utcDate = new Date(utcTimestamp);
        const istDate = new Date(utcDate.getTime());

        const formattedDate = istDate.toLocaleString('en-IN', {
            timeZone: 'Asia/Kolkata',
        });

        return formattedDate;
    };

    return (
        <Stack spacing={'20px'} padding={'10px'}>
            <Stack
                spacing={'15px'}
                sx={{
                    border: `1px solid ${theme.palette.structuralColors.gray}`,
                    padding: '15px 10px',
                    borderRadius: '3px',
                }}
            >
                <Stack
                    sx={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant="subtitle1"
                        color={theme.palette.text.highEmphasis}
                    >
                        {'Risk Evidence'}
                    </Typography>
                    <StyledButton
                        variant="outlined"
                        onClick={() => handleFileUploadToggle(true)}
                        startIcon={<CloudUploadIcon />}
                    >
                        <Typography variant="caption1">
                            {'Upload Evidence'}
                        </Typography>
                    </StyledButton>
                </Stack>
                <Divider />
                <Stack
                    sx={{
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="body1">
                        {'Uploaded Document'}
                    </Typography>
                </Stack>
                <ClientSideDataTable
                    columns={useRiskDocumentInfoColumns(
                        setDocumentId,
                        setDocumentType,
                        props,
                        handleEditCompOpen,
                        handlePreviewOpen
                    )}
                    rowsData={riskEvidenceDocument || []}
                    isLoading={isLoading}
                    checkboxSelection={false}
                    hideFooter={false}
                    slotProps={{
                        loadingOverlay: {
                            variant: 'skeleton',
                            noRowsVariant: 'skeleton',
                        },
                    }}
                    slots={{
                        noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    resizeThrottleMs={190}
                    pageSizeOptions={[5, 10, 25, 50, 75, 100]}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 10, page: 0 },
                        },
                    }}
                />
                <Stack spacing={'5px'}>
                    <Typography
                        variant="caption1"
                        color={theme.palette.text.mediumEmphasis}
                    >
                        {'Notes'}
                    </Typography>
                    <textarea
                        placeholder="Notes"
                        name="remarks"
                        value={formData.remarks}
                        onChange={handleInputChange}
                        style={{
                            fontFamily: 'Mier-Book',
                            fontSize: '16px',
                            padding: '5px',
                            width: '99%',
                            border: `1px solid ${theme.palette.structuralColors.stroke1}`,
                            minHeight: '120px',
                        }}
                    />
                </Stack>
                <Stack
                    direction={'row'}
                    sx={{ justifyContent: 'space-between' }}
                >
                    <div>
                        <StyledButton variant="outlined" onClick={handleSave}>
                            <Typography variant="caption1">
                                {'Add Notes'}
                            </Typography>
                        </StyledButton>
                        <StyledButton
                            variant="outlined"
                            onClick={handleNotesModelOpen}
                            sx={{ marginLeft: '10px' }}
                        >
                            <Typography variant="caption1">
                                {'View Previous Notes'}
                            </Typography>
                        </StyledButton>
                    </div>
                    <div>
                        {props.userRole === 'ADMIN' ? (
                            <StyledButton
                                variant="contained"
                                onClick={handleEmailModelOpen}
                            >
                                <Typography variant="caption1">
                                    {'Send Email'}
                                </Typography>
                            </StyledButton>
                        ) : (
                            <></>
                        )}
                    </div>
                </Stack>
                <EmailSender
                    open={openEmailModel}
                    handleEmailSend={(formData) => handleEmailSend(formData)}
                    onClose={handleEmailModelClose}
                />
                <GenericModal open={notesModalOpen} style={NotesModalStyle}>
                    <Stack
                        sx={{ justifyContent: 'space-between' }}
                        direction={'row'}
                    >
                        <Typography variant="body1">
                            {'Previous Notes'}
                        </Typography>
                        <IconButton onClick={handleNotesModelOpen}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Stack
                        spacing={'10px'}
                        sx={{
                            overflow: 'scroll',
                        }}
                    >
                        {comments &&
                            comments.length > 0 &&
                            comments
                                .sort((a, b) => {
                                    const dateA = new Date(a.addedAt).getTime();
                                    const dateB = new Date(b.addedAt).getTime();
                                    return dateB - dateA;
                                })
                                .map((data) => (
                                    <Stack
                                        key={data.id}
                                        sx={{
                                            borderRadius: '10px',
                                            border: '1px solid #EFEFEF',
                                        }}
                                    >
                                        <Stack
                                            sx={{
                                                padding: '5px 5px 3px 5px',
                                            }}
                                        >
                                            <textarea
                                                readOnly
                                                style={{
                                                    fontFamily: 'Mier-Book',
                                                    fontSize: '16px',
                                                    padding: '5px',
                                                    width: '99%',
                                                    border: 'none',
                                                    minHeight: '120px',
                                                }}
                                                value={data.text}
                                            />
                                        </Stack>
                                        <Stack
                                            direction={'row'}
                                            sx={{
                                                justifyContent: 'space-between',
                                                backgroundColor: '#EFEFEF',
                                                padding: '3px 5px 3px 5px',
                                                borderBottomLeftRadius: '9px',
                                                borderBottomRightRadius: '9px',
                                            }}
                                            spacing={'10px'}
                                        >
                                            <Typography
                                                variant="caption2"
                                                color={
                                                    theme.palette.text
                                                        .mediumEmphasis
                                                }
                                            >
                                                {'Added By: ' + data.addedBy}
                                            </Typography>
                                            <Box
                                                sx={{
                                                    width: '12vw',
                                                }}
                                            >
                                                <Typography
                                                    variant="caption2"
                                                    color={
                                                        theme.palette.text
                                                            .mediumEmphasis
                                                    }
                                                >
                                                    {'Added At: ' +
                                                        convertUTCtoIST(
                                                            data.addedAt
                                                        )}
                                                </Typography>
                                            </Box>
                                        </Stack>
                                    </Stack>
                                ))}
                    </Stack>
                </GenericModal>
            </Stack>

            <FileUploadModal
                open={fileUploadOpen}
                onToggle={handleFileUploadToggle}
                id={props.riskId}
                onUploadComplete={handleUploadComplete}
                uploadFrom={'RiskRegister'}
            />
            <Dialog
                open={fileModal}
                onClose={handleFileModalClose}
                maxWidth="lg"
                fullWidth
            >
                <DialogContent>
                    {fileUrl ? (
                        <DocumentViewer
                            filePreview={{ fileUrl, fileName: fileNameValue }}
                            loading={isDocViewerLoading}
                        />
                    ) : null}
                </DialogContent>
            </Dialog>
            <CustomSnackbar
                open={openSnackBar}
                message={currentMessage}
                success={success}
                onClose={handleClose}
                autoHideDuration={1800}
            />
            <GenericModal open={editFileModalOpen} style={GenericModalStyle}>
                <Stack spacing={'10px'}>
                    <Stack
                        sx={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="subtitle1">
                            {'Edit File Name'}
                        </Typography>
                        <IconButton onClick={handleEditFileModalOpen}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <TypoInput
                        labelvalue="File Name"
                        placeholder={'File Name'}
                        value={editFileData.displayName}
                        onChange={(e) => handleFileNameChange(e, 'displayName')}
                        manditoryfield="true"
                    />
                    {errors.displayName && (
                        <Typography variant="caption2" color={'red'}>
                            {errors.displayName}
                        </Typography>
                    )}

                    <Button
                        variant="outlined"
                        onClick={(e) =>
                            handleEditButtonClick(e, editDocumentId, 'FILE')
                        }
                    >
                        <Typography variant="caption1">{'Save'}</Typography>
                    </Button>
                </Stack>
            </GenericModal>
        </Stack>
    );
};

export default RiskEvidence;
