import { Autocomplete, Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { DocumentData, UserData } from '../../../utils/types';
import { createApproval, getAllRegistryDocuments } from '../../../services/api';
import styled from '@emotion/styled';
import Button from '../../atoms/Button';
import Typography from '../../atoms/Typography';
import useSnackBar from '../../../utils/hooks';
import CustomSnackbar from '../../molecules/CustomSnackbar';

const Container = styled(Stack)({
    height: '100%',
    padding: '10px',
    justifyContent: 'space-between',
    flexDirection: 'row',
});

interface CreateApprovalTabProps {
    registryId: string;
    userData: any;
}

const CreateApprovalTab = (props: CreateApprovalTabProps) => {
    const registryId = props.registryId;
    const [selectedDocuments, setSelectedDocuments] = useState<DocumentData[]>(
        []
    );
    const [selectedApprovers, setSelectedApprovers] = useState<{
        L1: UserData[];
        L2: UserData[];
        L3: UserData[];
        L4: UserData[];
    }>({
        L1: [],
        L2: [],
        L3: [],
        L4: [],
    });
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const { openSnackBar, handleClick, handleClose } = useSnackBar();
    const userData = props.userData || [];
    const [documentData, setDocumentData] = useState<DocumentData[]>([]);

    const fetchDocumentData = async () => {
        try {
            const response = await getAllRegistryDocuments({
                registryId: registryId,
            });
            setDocumentData(response);
        } catch (e) {
            setMessage('Error getting all documents.');
            handleClick();
            setSuccess(false);
        }
    };

    useEffect(() => {
        fetchDocumentData();
    }, []);

    const handleDocumentChange = (
        event: React.SyntheticEvent,
        value: DocumentData[]
    ) => {
        setSelectedDocuments(value);
    };

    const resetForm = () => {
        setSelectedApprovers({
            L1: [],
            L2: [],
            L3: [],
            L4: [],
        });
        setSelectedDocuments([]);
    };

    const handleApproverChange =
        (level: 'L1' | 'L2' | 'L3' | 'L4') =>
        (event: React.SyntheticEvent, value: UserData[]) => {
            setSelectedApprovers((prev) => ({ ...prev, [level]: value }));
        };

    const createRequestBody = async () => {
        const documentLinkIds = selectedDocuments
            .filter((doc) => doc.type === 'LINK')
            .map((doc) => doc.id);
        const fileUploadIds = selectedDocuments
            .filter((doc) => doc.type === 'FILE')
            .map((doc) => doc.id);

        const approversByLevel = Object.keys(selectedApprovers).reduce(
            (acc, level) => {
                const typedLevel = level as keyof typeof selectedApprovers;
                const approvers = selectedApprovers[typedLevel];
                acc[typedLevel] = approvers.map((approver) => approver.id);
                return acc;
            },
            {} as Record<'L1' | 'L2' | 'L3' | 'L4', string[]>
        );

        const requestBody = {
            registryId: registryId,
            documentLinkIds,
            fileUploadIds,
            approversByLevel,
        };

        const response = await createApproval(requestBody);
        if (response) {
            if (response.success) {
                setMessage(response.message);
                setSuccess(true);
            } else {
                setMessage(response.message);
                setSuccess(false);
            }
            handleClick();
            resetForm();
        } else {
            setMessage('Failed to created approval');
            handleClick();
            setSuccess(false);
        }
        setSelectedApprovers({
            L1: [],
            L2: [],
            L3: [],
            L4: [],
        });
        setSelectedDocuments([]);
    };

    const isFormComplete = (): boolean => {
        return (
            selectedDocuments.length > 0 &&
            selectedApprovers.L1.length > 0 &&
            selectedApprovers.L2.length > 0 &&
            selectedApprovers.L3.length > 0 &&
            selectedApprovers.L4.length > 0
        );
    };

    return (
        <Container>
            <Stack spacing={'16px'} sx={{ width: 500 }}>
                <Stack spacing={'6px'}>
                    <Typography variant="body1">
                        {'Select Documents'}
                    </Typography>
                    <Autocomplete
                        multiple
                        size="small"
                        value={selectedDocuments}
                        options={documentData}
                        getOptionLabel={(option) => option.title}
                        onChange={handleDocumentChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Documents"
                                placeholder="Documents"
                            />
                        )}
                    />
                </Stack>
                {['L1', 'L2', 'L3', 'L4'].map((level) => (
                    <Stack key={level} spacing={'6px'}>
                        <Typography variant="body1">
                            {level + ' Approvers'}
                        </Typography>
                        <Autocomplete
                            multiple
                            size="small"
                            value={selectedApprovers[level]}
                            options={userData}
                            getOptionLabel={(option) => option.name}
                            onChange={handleApproverChange(
                                level as 'L1' | 'L2' | 'L3' | 'L4'
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={`${level} Approvers`}
                                    placeholder={`${level} Approvers`}
                                />
                            )}
                        />
                    </Stack>
                ))}
                <Button
                    onClick={createRequestBody}
                    variant="contained"
                    disabled={!isFormComplete()}
                >
                    <Typography variant="body1">{'Create Approval'}</Typography>
                </Button>
            </Stack>
            <CustomSnackbar
                open={openSnackBar}
                message={message}
                success={success}
                onClose={handleClose}
                autoHideDuration={2500}
            />
        </Container>
    );
};

export default CreateApprovalTab;
