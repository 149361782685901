import { useEffect, useState } from 'react';
import LoginTab from '../../components/molecules/LoginTab';
import { getTokenWithCode, ssoLogin } from '../../services/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/authContext';
import AuthTemplate from '../../components/templates/AuthTemplate';

const LoginPage = () => {
    const { login } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [url, setUrl] = useState('');
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const response = await ssoLogin();
            if (response) {
                setUrl(response.url);
            } else {
                alert('Google Login Error, please Refresh the Page');
            }
        };

        fetchData();
    }, []);

    const getToken = async (code: string) => {
        const response = await getTokenWithCode(code);
        if (response && response.jwt !== null) {
            const data = response.jwt;
            const refreshToken = response.refreshToken;
            login(data, refreshToken);
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const code = params.get('code');
        if (code !== null) {
            getToken(code).then((result) => {
                if (result) {
                    navigate('/u/dashboard');
                } else {
                    setShowMessage(true);
                    navigate('/');
                }
            });
        }
    }, [location, navigate]);

    return (
        <AuthTemplate
            main={<LoginTab showMessage={showMessage} loginLink={url} />}
        ></AuthTemplate>
    );
};

export default LoginPage;
