import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Divider,
    Stack,
    styled,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    getAllApprovalsByRegistry,
    resendApproval,
} from '../../../services/api';
import Typography from '../../atoms/Typography';
import Button from '../../atoms/Button';
import ClientSideDataTable from '../ClientSideDataTable';
import theme from '../../../theme';
import {
    GridColDef,
    GridColumnVisibilityModel,
    GridRenderCellParams,
} from '@mui/x-data-grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useSnackBar from '../../../utils/hooks';
import CustomSnackbar from '../../molecules/CustomSnackbar';
import ConfirmationModal from '../../molecules/ConfirmationModal';
import Chip from '../../atoms/Chip';

const Container = styled(Stack)({
    height: '100%',
    padding: '10px',
    justifyContent: 'space-between',
    flexDirection: 'column',
});

interface AllApproveTabProps {
    registryId: string;
}

const AllApproveTab = (props: AllApproveTabProps) => {
    const registryId = props.registryId;
    const [approvalData, setApprovalData] = useState([]);
    const [columnVisibilityModel, setColumnVisibilityModel] =
        useState<GridColumnVisibilityModel>({
            approvalId: false,
            userId: false,
        });
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const { openSnackBar, handleClick, handleClose } = useSnackBar();
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [resendData, setResendData] = useState({
        userId: '',
        approvalId: '',
        level: '',
    });
    const [filter, setFilter] = useState<'IN_PROGRESS' | 'COMPLETED'>(
        'IN_PROGRESS'
    );

    const handleConfirmationModel = () => {
        setConfirmationOpen(!confirmationOpen);
    };

    const fetchApprovalData = async () => {
        const data = await getAllApprovalsByRegistry({
            registryId: registryId,
        });
        if (data) {
            setApprovalData(data);
        } else {
            setMessage('Failed to fetch approvals.');
            handleClick();
            setSuccess(false);
        }
    };

    useEffect(() => {
        fetchApprovalData();
    }, []);

    const handleResendApproval = async () => {
        const response = await resendApproval(resendData);
        if (response) {
            setMessage('Approval resend successfully');
            handleClick();
            setSuccess(true);
            fetchApprovalData();
        } else {
            setMessage('Failed to resend approval. Please try again.');
            handleClick();
            setSuccess(false);
        }
    };

    const handleResendApprovalConfirmation = useCallback(
        (userId: string, approvalId: string, level: string) => () => {
            setConfirmationOpen(true);
            setResendData({
                userId: userId,
                approvalId: approvalId,
                level: level,
            });
        },
        []
    );

    const flattenData = (data) => {
        const flattened = [];

        data.approvalLevel.forEach((level) => {
            level.approvers.forEach((approver) => {
                flattened.push({
                    id: approver.id, // Unique ID for each row
                    approvalId: data.id,
                    userId: approver.user.id,
                    username: approver.user.name,
                    email: approver.user.email,
                    status: approver.status,
                    level: level.level,
                    comment: approver.comment,
                    approvalStatus: data.status,
                });
            });
        });

        return flattened;
    };

    const getApprovalColumns = useMemo<GridColDef[]>(
        () => [
            {
                field: 'username',
                headerName: 'User Details',
                flex: 1.5,
                renderCell: (params) => (
                    <Stack sx={{ justifyContent: 'center', height: '100%' }}>
                        <Typography variant="body2">
                            {'Name: ' + params.row.username}
                        </Typography>
                        <Typography variant="body2">
                            {'Email: ' + params.row.email}
                        </Typography>
                    </Stack>
                ),
            },
            {
                field: 'approvalId',
            },
            {
                field: 'userId',
            },
            {
                field: 'status',
                headerName: 'Status',
                flex: 1,
                renderCell: (params: GridRenderCellParams) => {
                    const formattedStatus = params.value
                        .split('_')
                        .map(
                            (word: string) =>
                                word.charAt(0) + word.slice(1).toLowerCase()
                        )
                        .join(' ');
                    return (
                        <Stack
                            sx={{
                                height: '100%',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography
                                variant="body2"
                                color={theme.palette.text.highEmphasis}
                            >
                                {formattedStatus}
                            </Typography>
                        </Stack>
                    );
                },
            },
            {
                field: 'level',
                headerName: 'Level',
                flex: 0.5,
                renderCell: (params) => (
                    <Stack sx={{ justifyContent: 'center', height: '100%' }}>
                        <Typography variant="body2">{params.value}</Typography>
                    </Stack>
                ),
            },
            {
                field: 'comment',
                headerName: 'Comment',
                flex: 1.5,
                align: 'center',
                headerAlign: 'center',
                renderCell: (params) => (
                    <Stack sx={{ justifyContent: 'center', height: '100%' }}>
                        {params.row.comment != null ? (
                            <>
                                {params.row.comment.map((data, index) => (
                                    <Stack key={index}>
                                        <Typography variant="body2">
                                            {data}
                                        </Typography>
                                        {index ===
                                        params.row.comment.length - 1 ? (
                                            ''
                                        ) : (
                                            <Divider />
                                        )}
                                    </Stack>
                                ))}
                            </>
                        ) : (
                            <Typography variant="body2">{'---'}</Typography>
                        )}
                    </Stack>
                ),
            },
            {
                field: 'actions',
                headerName: 'Action',
                type: 'actions',
                flex: 1,
                getActions: (params) => [
                    <Button
                        key={params.id}
                        variant="outlined"
                        color="primary"
                        onClick={handleResendApprovalConfirmation(
                            params.row.userId,
                            params.row.approvalId,
                            params.row.level
                        )}
                        disabled={params.row.approvalStatus == 'COMPLETED'}
                    >
                        Resend Approval
                    </Button>,
                ],
            },
        ],
        [handleResendApprovalConfirmation]
    );

    const formatStatus = (status: string) => {
        if (!status) return '';

        return status
            .toLowerCase()
            .replace(/_/g, ' ')
            .replace(/\b\w/g, (char) => char.toUpperCase());
    };

    function convertUTCtoIST(utcTimestamp: string) {
        const utcDate = new Date(utcTimestamp);
        const istDate = new Date(utcDate.getTime());

        const formattedDate = istDate.toLocaleString('en-IN', {
            timeZone: 'Asia/Kolkata',
        });

        return formattedDate;
    }

    const countByStatus = (status: string) => {
        return approvalData.filter((approval) => approval.status === status)
            .length;
    };

    return (
        <Container spacing={'10px'}>
            <Typography variant="h3">
                {'Approvals'}
                <Divider />
            </Typography>

            <Stack direction="row" spacing={2}>
                <Chip
                    label={`In Progress (${countByStatus('IN_PROGRESS')})`}
                    color={filter === 'IN_PROGRESS' ? 'primary' : 'default'}
                    onClick={() => setFilter('IN_PROGRESS')}
                />
                <Chip
                    label={`Completed (${countByStatus('COMPLETED')})`}
                    color={filter === 'COMPLETED' ? 'primary' : 'default'}
                    onClick={() => setFilter('COMPLETED')}
                />
            </Stack>

            <Stack spacing={'20px'}>
                {approvalData.filter((approval) => approval.status === filter) // Filter data based on the selected chip
                    .length > 0 ? (
                    approvalData
                        .filter((approval) => approval.status === filter)
                        .map((approval) => (
                            <Stack key={approval.id}>
                                <Accordion elevation={1}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        sx={{
                                            backgroundColor:
                                                approval.status === 'COMPLETED'
                                                    ? '#73AF6D'
                                                    : '#F5D398',
                                        }}
                                    >
                                        <Stack
                                            direction={'row'}
                                            sx={{
                                                justifyContent: 'space-between',
                                                width: '100%',
                                            }}
                                        >
                                            <Stack direction={'row'}>
                                                <Box
                                                    sx={{
                                                        width: '14vw',
                                                    }}
                                                >
                                                    <Typography variant="body1">
                                                        {'Approval Status: ' +
                                                            formatStatus(
                                                                approval.status
                                                            )}
                                                    </Typography>
                                                </Box>
                                                <Typography variant="body1">
                                                    {'Current Level: ' +
                                                        approval.currentLevel}
                                                </Typography>
                                            </Stack>
                                            <Box
                                                sx={{
                                                    width: '15vw',
                                                }}
                                            >
                                                <Typography variant="body1">
                                                    {'Updated: ' +
                                                        convertUTCtoIST(
                                                            approval.updatedAt
                                                        )}
                                                </Typography>
                                            </Box>
                                        </Stack>
                                    </AccordionSummary>
                                    <AccordionDetails
                                        sx={{ borderTop: '1px solid grey' }}
                                    >
                                        <Stack
                                            sx={{
                                                padding: '5px',
                                                borderRadius: '3px',
                                            }}
                                        >
                                            <Typography variant="subtitle1">
                                                {'Associated Documents'}
                                            </Typography>
                                            {approval.documents.map((d) => (
                                                <Typography
                                                    variant="body1"
                                                    key={d.id}
                                                    color={
                                                        theme.palette.text
                                                            .mediumEmphasis
                                                    }
                                                >
                                                    {d.title}
                                                </Typography>
                                            ))}
                                            <ClientSideDataTable
                                                columns={getApprovalColumns}
                                                rowsData={flattenData(approval)}
                                                isLoading={false}
                                                checkboxSelection={false}
                                                hideFooter={true}
                                                columnVisibilityModel={
                                                    columnVisibilityModel
                                                }
                                                onColumnVisibilityModelChange={
                                                    setColumnVisibilityModel
                                                }
                                                slotProps={{
                                                    loadingOverlay: {
                                                        variant: 'skeleton',
                                                        noRowsVariant:
                                                            'skeleton',
                                                    },
                                                }}
                                                resizeThrottleMs={190}
                                                getRowHeight={() => 'auto'}
                                            />
                                        </Stack>
                                    </AccordionDetails>
                                </Accordion>
                            </Stack>
                        ))
                ) : (
                    <Stack
                        sx={{
                            border: '1px dashed #D3D3D3',
                            backgroundColor: '#F8F9FA',
                            padding: '5px 0',
                        }}
                    >
                        <Typography variant="body1" align="center">
                            {'No ongoing approvals'}
                        </Typography>
                    </Stack>
                )}
            </Stack>
            <CustomSnackbar
                open={openSnackBar}
                message={message}
                success={success}
                onClose={handleClose}
                autoHideDuration={1800}
            />
            <ConfirmationModal
                open={confirmationOpen}
                onClose={handleConfirmationModel}
                onConfirm={() => handleResendApproval()}
                onCancel={handleConfirmationModel}
                title={'Are you sure you want to resend the approval?'}
                confirmText={'Confirm'}
                cancelText={'Cancel'}
            />
        </Container>
    );
};

export default AllApproveTab;
