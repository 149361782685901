import {
    Typography as MuiTypography,
    TypographyProps,
    TypographyTypeMap,
} from '@mui/material';
import React from 'react';

export interface CustomTypographyProps extends TypographyProps {
    variant: TypographyTypeMap['props']['variant'];
    children: React.ReactNode;
    style?: React.CSSProperties;
}

const Typography = (props: CustomTypographyProps) => {
    return <MuiTypography {...props}>{props.children}</MuiTypography>;
};

export default Typography;
