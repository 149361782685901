import { useLocation, useNavigate } from 'react-router-dom';
import Typography from '../../components/atoms/Typography';
import { useEffect } from 'react';
import { useVendorAuth } from '../../context/vendorAuthContext';
import { vendorGetAccessToken } from '../../services/api';

const VendorVerifyPage = () => {
    const { login } = useVendorAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const getToken = async (code: string) => {
        const response = await vendorGetAccessToken({
            magicToken: code,
        });
        if (response && response.jwt !== null) {
            const data = response.jwt;
            login(data);
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const code = params.get('code');

        if (code !== null) {
            getToken(code).then((result) => {
                if (result) {
                    navigate('/v/vendordashboard');
                } else {
                    navigate('/vendorlogin');
                }
            });
        }
    }, [location, navigate]);

    return <Typography variant="body1">{'Verifying...'}</Typography>;
};

export default VendorVerifyPage;
