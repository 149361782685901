import React, { useState, useEffect } from 'react';
import {
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Box,
    Tabs,
    Tab,
    Stack,
    Divider,
    styled,
    IconButton,
    Dialog,
    Autocomplete,
    Switch,
} from '@mui/material';
import {
    getRiskRegister,
    importRiskMatrix,
    updateRiskProcess,
} from '../../../services/api';
import theme from '../../../theme';
import Button from '../../atoms/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import Typography from '../../atoms/Typography';
import GetAppIcon from '@mui/icons-material/GetApp';

interface Option {
    text: string;
    value: number;
}

interface Question {
    text: string;
    options: Option[];
}

interface RiskRange {
    min: number;
    max: number;
    text: string;
}

interface RiskAnalysisMatrix {
    likelihood: Question;
    impact: Question[];
    assetClassification: Question | null;
    riskRanges: RiskRange[];
}

interface RiskAnalysisState {
    likelihoodValue: number;
    impactValues: number[];
    assetValue: number;
    riskValue: number;
    riskRating: string;
}

interface RiskAnalysisConfig {
    matrix: RiskAnalysisMatrix;
    beforeRTP: RiskAnalysisState;
    afterRTP: RiskAnalysisState;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const StyledButton = styled(Button)({
    padding: '5px 8px',
    width: '160px',
});

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Stack>{children}</Stack>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface RiskMatrixOption {
    id: string;
    name: string;
}

const RiskAnalysisComponent: React.FC<{
    riskRegisterId: string;
}> = ({ riskRegisterId }) => {
    const [config, setConfig] = useState<RiskAnalysisConfig>({
        matrix: {
            likelihood: { text: '', options: [] },
            impact: [],
            assetClassification: { text: '', options: [] } || null,
            riskRanges: [],
        },
        beforeRTP: {
            likelihoodValue: 0,
            impactValues: [],
            assetValue: 1,
            riskValue: 0,
            riskRating: '',
        },
        afterRTP: {
            likelihoodValue: 0,
            impactValues: [],
            assetValue: 1,
            riskValue: 0,
            riskRating: '',
        },
    });
    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const [importDialogOpen, setImportDialogOpen] = useState(false);
    const [selectedMatrix, setSelectedMatrix] =
        useState<RiskMatrixOption | null>(null);
    const [availableMatrices, setAvailableMatrices] = useState<
        RiskMatrixOption[]
    >([]);
    const [useAssetClassification, setUseAssetClassification] = useState(false);

    const fetchConfig = async () => {
        const response = await getRiskRegister(riskRegisterId);
        if (response && response.riskRegister.riskAnalysisResults != null) {
            const data = response.riskRegister;
            
            const riskAnalysisData = data.riskAnalysisResults;
            if (
                riskAnalysisData.matrix != null &&
                riskAnalysisData.beforeRTP != null &&
                riskAnalysisData.afterRTP != null
            ) {
                setConfig(riskAnalysisData);
            }
            if (
                riskAnalysisData.matrix != null &&
                riskAnalysisData.beforeRTP == null &&
                riskAnalysisData.afterRTP == null
            ) {
                setConfig({
                    matrix: riskAnalysisData.matrix,
                    beforeRTP: {
                        likelihoodValue: 0,
                        impactValues: [],
                        riskValue: 0,
                        riskRating: '',
                        assetValue: 0,
                    },
                    afterRTP: {
                        likelihoodValue: 0,
                        impactValues: [],
                        riskValue: 0,
                        riskRating: '',
                        assetValue: 0,
                    },
                });
            }
            if (
                riskAnalysisData.matrix != null &&
                riskAnalysisData.matrix.assetClassification != null
            ) {
                setUseAssetClassification(true);
            }
        } else {
            console.log('error');
        }

        if (response) {
            const analysisData = response.riskAnalysisMatrices;
            setAvailableMatrices(
                analysisData.map((matrix) => ({
                    id: matrix.riskId,
                    name: matrix.referenceId + ': ' + matrix.riskRegisterName,
                }))
            );
        }
    };

    useEffect(() => {
        fetchConfig();
    }, []);

    const saveConfig = async () => {
        const response = await updateRiskProcess(
            riskRegisterId,
            'ANALYSIS_CONFIG',
            {
                riskAnalysisResults: config,
            }
        );
        if (response) {
            setConfig(response.riskAnalysisResults);
        } else {
            console.error('Error saving config:', error);
        }
    };

    const addAssetOption = () => {
        setConfig((prevConfig) => {
            const updatedConfig = { ...prevConfig };
            if (updatedConfig.matrix.assetClassification) {
                const newOption: Option = {
                    text: '',
                    value:
                        updatedConfig.matrix.assetClassification.options
                            .length + 1,
                };
                updatedConfig.matrix.assetClassification.options.push(
                    newOption
                );
            }
            return updatedConfig;
        });
    };

    const addLikelihoodOption = () => {
        setConfig((prevConfig) => {
            const updatedConfig = { ...prevConfig };
            const newOption: Option = {
                text: '',
                value: updatedConfig.matrix.likelihood.options.length + 1,
            };
            updatedConfig.matrix.likelihood.options.push(newOption);
            return updatedConfig;
        });
    };

    const addImpactQuestion = () => {
        setConfig((prevConfig) => {
            const updatedConfig = { ...prevConfig };
            const newQuestion: Question = { text: '', options: [] };
            updatedConfig.matrix.impact.push(newQuestion);
            updatedConfig.beforeRTP.impactValues.push(0);
            updatedConfig.afterRTP.impactValues.push(0);
            return updatedConfig;
        });
    };

    const addImpactOption = (questionIndex: number) => {
        setConfig((prevConfig) => {
            const updatedConfig = { ...prevConfig };
            const newOption: Option = {
                text: '',
                value:
                    updatedConfig.matrix.impact[questionIndex].options.length +
                    1,
            };
            updatedConfig.matrix.impact[questionIndex].options.push(newOption);
            return updatedConfig;
        });
    };

    const addRiskRange = () => {
        setConfig((prevConfig) => {
            const updatedConfig = { ...prevConfig };
            const newRange: RiskRange = { min: 0, max: 0, text: '' };
            updatedConfig.matrix.riskRanges.push(newRange);
            return updatedConfig;
        });
    };

    const calculateRisk = (state: RiskAnalysisState): RiskAnalysisState => {
        const lValue = state.likelihoodValue;
        const rValue = Math.max(...state.impactValues);
        const aValue = state.assetValue || 1;
        const riskValue = lValue * rValue * aValue;

        const riskRating =
            config.matrix.riskRanges.find(
                (range) => riskValue >= range.min && riskValue <= range.max
            )?.text || 'Unknown';

        return {
            ...state,
            riskValue,
            riskRating,
        };
    };

    const saveAnswers = async (type: 'before' | 'after') => {
        const answers = type === 'before' ? config.beforeRTP : config.afterRTP;
        const response = await updateRiskProcess(
            riskRegisterId,
            'ANALYSIS_ANSWER',
            answers,
            type
        );
        if (response) {
            setConfig((prevConfig) => ({
                ...prevConfig,
                [type === 'before' ? 'beforeRTP' : 'afterRTP']: response,
            }));
            alert(
                `${type.charAt(0).toUpperCase() + type.slice(1)} RTP answers saved successfully!`
            );
        } else {
            console.error(`Error saving ${type} RTP answers:`, error);
            alert(`Error saving ${type} RTP answers. Please try again.`);
        }
    };

    const handleLikelihoodChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        type: 'before' | 'after'
    ) => {
        const updatedConfig = { ...config };
        if (type === 'before') {
            updatedConfig.beforeRTP.likelihoodValue = parseInt(e.target.value);
            updatedConfig.beforeRTP = calculateRisk(updatedConfig.beforeRTP);
        } else {
            updatedConfig.afterRTP.likelihoodValue = parseInt(e.target.value);
            updatedConfig.afterRTP = calculateRisk(updatedConfig.afterRTP);
        }
        setConfig(updatedConfig);
    };

    const handleAssetChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        type: 'before' | 'after'
    ) => {
        const updatedConfig = { ...config };
        if (type === 'before') {
            updatedConfig.beforeRTP.assetValue = parseInt(e.target.value);
            updatedConfig.beforeRTP = calculateRisk(updatedConfig.beforeRTP);
        } else {
            updatedConfig.afterRTP.assetValue = parseInt(e.target.value);
            updatedConfig.afterRTP = calculateRisk(updatedConfig.afterRTP);
        }
        setConfig(updatedConfig);
    };

    const handleImpactChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        qIndex: number,
        type: 'before' | 'after'
    ) => {
        const updatedConfig = { ...config };
        if (type === 'before') {
            updatedConfig.beforeRTP.impactValues[qIndex] = parseInt(
                e.target.value
            );
            updatedConfig.beforeRTP = calculateRisk(updatedConfig.beforeRTP);
        } else {
            updatedConfig.afterRTP.impactValues[qIndex] = parseInt(
                e.target.value
            );
            updatedConfig.afterRTP = calculateRisk(updatedConfig.afterRTP);
        }
        setConfig(updatedConfig);
    };

    const importMatrix = async () => {
        const response = await importRiskMatrix(
            riskRegisterId,
            selectedMatrix.id
        );
        if (response) {
            fetchConfig();
            setImportDialogOpen(false);
            alert('Matrix imported successfully!');
        } else {
            console.error('Error importing matrix:', error);
            alert('Error importing matrix. Please try again.');
        }
    };

    const renderImportMatrixDialog = () => (
        <Dialog
            open={importDialogOpen}
            onClose={() => setImportDialogOpen(false)}
        >
            <Stack sx={{ width: '25vw', padding: '10px' }} spacing={'15px'}>
                <Typography variant="subtitle1">
                    {'Import Risk Matrix'}
                </Typography>
                <FormControl fullWidth>
                    <Autocomplete
                        size="small"
                        fullWidth
                        options={availableMatrices}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select a Matrix"
                                variant="outlined"
                            />
                        )}
                        value={selectedMatrix}
                        onChange={(event, newValue) => {
                            setSelectedMatrix(newValue);
                        }}
                        ListboxProps={{
                            style: {
                                maxHeight: '200px',
                            },
                        }}
                        noOptionsText="No matrices available"
                    />
                </FormControl>
                <Stack
                    spacing={'10px'}
                    direction={'row'}
                    sx={{ justifyContent: 'flex-end' }}
                >
                    <Button
                        onClick={() => setImportDialogOpen(false)}
                        variant="outlined"
                    >
                        <Typography variant="caption1">{'Cancel'}</Typography>
                    </Button>
                    <Button onClick={importMatrix} variant="outlined">
                        <Typography variant="caption1">{'Import'}</Typography>
                    </Button>
                </Stack>
            </Stack>
        </Dialog>
    );

    const isLikelihoodValid = config.matrix.likelihood.options.length > 0;
    const areImpactQuestionsValid = config.matrix.impact.every(
        (question) => question.options.length > 0
    );
    const isRiskRangeValid = config.matrix.riskRanges.length > 0;

    const invalidImpactQuestions = config.matrix.impact.map(
        (question) => question.options.length === 0
    );

    const isFormValid =
        isLikelihoodValid && areImpactQuestionsValid && isRiskRangeValid;

    const renderAnalysisMatrixForm = () => (
        <Stack>
            <Stack direction={'row'} sx={{ justifyContent: 'space-between' }}>
                <Typography variant="subtitle1">
                    {'Risk Analysis Matrix Configuration'}
                </Typography>
                <StyledButton
                    variant="contained"
                    onClick={() => setImportDialogOpen(true)}
                    style={{ marginBottom: '1rem' }}
                    disableElevation
                    startIcon={<GetAppIcon />}
                >
                    Import Matrix
                </StyledButton>
            </Stack>
            <FormControl fullWidth margin="normal">
                <Stack spacing={'10px'}>
                    <FormLabel>Likelihood</FormLabel>

                    <TextField
                        size="small"
                        value={config.matrix.likelihood.text}
                        onChange={(e) => {
                            const updatedConfig = { ...config };
                            updatedConfig.matrix.likelihood.text =
                                e.target.value;
                            setConfig(updatedConfig);
                        }}
                        label="Question"
                    />
                    {config.matrix.likelihood.options.map((option, index) => (
                        <Stack
                            key={index}
                            direction={'row'}
                            alignItems="center"
                            justifyContent="flex-start"
                            spacing={'10px'}
                        >
                            <TextField
                                size="small"
                                value={option.text}
                                onChange={(e) => {
                                    const updatedConfig = { ...config };
                                    updatedConfig.matrix.likelihood.options[
                                        index
                                    ].text = e.target.value;
                                    setConfig(updatedConfig);
                                }}
                                label={`Option ${index + 1}`}
                            />
                            <TextField
                                size="small"
                                type="number"
                                value={option.value}
                                onChange={(e) => {
                                    const updatedConfig = { ...config };
                                    updatedConfig.matrix.likelihood.options[
                                        index
                                    ].value = parseInt(e.target.value);
                                    setConfig(updatedConfig);
                                }}
                                label="Value"
                            />
                            <IconButton
                                aria-label="delete"
                                onClick={() => {
                                    setConfig((prevConfig) => {
                                        const updatedConfig = { ...prevConfig };
                                        updatedConfig.matrix.likelihood.options.splice(
                                            index,
                                            1
                                        );
                                        return updatedConfig;
                                    });
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Stack>
                    ))}
                    {!isLikelihoodValid && (
                        <Typography color="error" variant="caption1">
                            {'At least one likelihood option is required.'}
                        </Typography>
                    )}
                    <StyledButton
                        onClick={addLikelihoodOption}
                        variant="outlined"
                    >
                        Add Likelihood Option
                    </StyledButton>
                </Stack>
            </FormControl>
            <Stack direction={'row'} sx={{ justifyContent: 'space-between' }}>
                <Typography variant="h6">Impact</Typography>
                <StyledButton
                    onClick={addImpactQuestion}
                    variant="contained"
                    disableElevation
                >
                    Add Impact Question
                </StyledButton>
            </Stack>
            {config.matrix.impact.map((question, qIndex) => (
                <FormControl key={qIndex} fullWidth margin="normal">
                    <Stack spacing={'10px'}>
                        <Stack
                            direction={'row'}
                            sx={{ justifyContent: 'space-between' }}
                        >
                            <FormLabel>Impact Question {qIndex + 1}</FormLabel>
                            <IconButton
                                aria-label="delete"
                                onClick={() => {
                                    setConfig((prevConfig) => {
                                        const updatedConfig = { ...prevConfig };
                                        updatedConfig.matrix.impact.splice(
                                            qIndex,
                                            1
                                        );
                                        updatedConfig.beforeRTP.impactValues.splice(
                                            qIndex,
                                            1
                                        );
                                        updatedConfig.afterRTP.impactValues.splice(
                                            qIndex,
                                            1
                                        );
                                        return updatedConfig;
                                    });
                                }}
                            >
                                <RemoveCircleOutlineOutlinedIcon />
                            </IconButton>
                        </Stack>
                        <TextField
                            size="small"
                            value={question.text}
                            onChange={(e) => {
                                const updatedConfig = { ...config };
                                updatedConfig.matrix.impact[qIndex].text =
                                    e.target.value;
                                setConfig(updatedConfig);
                            }}
                            label="Question"
                        />
                        {question.options.map((option, oIndex) => (
                            <Stack
                                key={oIndex}
                                direction={'row'}
                                alignItems="center"
                                justifyContent="flex-start"
                                spacing={'10px'}
                            >
                                <TextField
                                    size="small"
                                    value={option.text}
                                    onChange={(e) => {
                                        const updatedConfig = { ...config };
                                        updatedConfig.matrix.impact[
                                            qIndex
                                        ].options[oIndex].text = e.target.value;
                                        setConfig(updatedConfig);
                                    }}
                                    label={`Option ${oIndex + 1}`}
                                />
                                <TextField
                                    size="small"
                                    type="number"
                                    value={option.value}
                                    onChange={(e) => {
                                        const updatedConfig = { ...config };
                                        updatedConfig.matrix.impact[
                                            qIndex
                                        ].options[oIndex].value = parseInt(
                                            e.target.value
                                        );
                                        setConfig(updatedConfig);
                                    }}
                                    label="Value"
                                />
                                <IconButton
                                    aria-label="delete"
                                    onClick={() => {
                                        setConfig((prevConfig) => {
                                            const updatedConfig = {
                                                ...prevConfig,
                                            };
                                            updatedConfig.matrix.impact[
                                                qIndex
                                            ].options.splice(oIndex, 1);
                                            return updatedConfig;
                                        });
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Stack>
                        ))}
                        {invalidImpactQuestions[qIndex] && (
                            <Typography color="error" variant="caption1">
                                {'At least one Impact option is required.'}
                            </Typography>
                        )}
                        <StyledButton
                            onClick={() => addImpactOption(qIndex)}
                            variant="outlined"
                        >
                            Add Impact Option
                        </StyledButton>
                    </Stack>
                </FormControl>
            ))}

            <FormControlLabel
                control={
                    <Switch
                        checked={useAssetClassification}
                        onChange={(e) => {
                            setUseAssetClassification(e.target.checked);
                            if (
                                e.target.checked &&
                                !config.matrix.assetClassification
                            ) {
                                setConfig((prevConfig) => ({
                                    ...prevConfig,
                                    matrix: {
                                        ...prevConfig.matrix,
                                        assetClassification: {
                                            text: '',
                                            options: [],
                                        },
                                    },
                                }));
                            } else if (!e.target.checked) {
                                setConfig((prevConfig) => ({
                                    ...prevConfig,
                                    matrix: {
                                        ...prevConfig.matrix,
                                        assetClassification: null,
                                    },
                                }));
                            }
                        }}
                    />
                }
                label="Use Asset Classification"
            />

            {useAssetClassification && (
                <FormControl fullWidth margin="normal">
                    <Stack spacing={'10px'}>
                        <FormLabel>Asset Classification</FormLabel>
                        <TextField
                            size="small"
                            value={
                                config.matrix.assetClassification?.text || ''
                            }
                            onChange={(e) => {
                                const updatedConfig = { ...config };
                                if (updatedConfig.matrix.assetClassification) {
                                    updatedConfig.matrix.assetClassification.text =
                                        e.target.value;
                                }
                                setConfig(updatedConfig);
                            }}
                            label="Question"
                        />
                        {config.matrix.assetClassification?.options.map(
                            (option, index) => (
                                <Stack
                                    key={index}
                                    direction={'row'}
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    spacing={'10px'}
                                >
                                    <TextField
                                        size="small"
                                        value={option.text}
                                        onChange={(e) => {
                                            const updatedConfig = { ...config };
                                            if (
                                                updatedConfig.matrix
                                                    .assetClassification
                                            ) {
                                                updatedConfig.matrix.assetClassification.options[
                                                    index
                                                ].text = e.target.value;
                                            }
                                            setConfig(updatedConfig);
                                        }}
                                        label={`Option ${index + 1}`}
                                    />
                                    <TextField
                                        size="small"
                                        type="number"
                                        value={option.value}
                                        onChange={(e) => {
                                            const updatedConfig = { ...config };
                                            if (
                                                updatedConfig.matrix
                                                    .assetClassification
                                            ) {
                                                updatedConfig.matrix.assetClassification.options[
                                                    index
                                                ].value = parseInt(
                                                    e.target.value
                                                );
                                            }
                                            setConfig(updatedConfig);
                                        }}
                                        label="Value"
                                    />
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => {
                                            setConfig((prevConfig) => {
                                                const updatedConfig = {
                                                    ...prevConfig,
                                                };
                                                if (
                                                    updatedConfig.matrix
                                                        .assetClassification
                                                ) {
                                                    updatedConfig.matrix.assetClassification.options.splice(
                                                        index,
                                                        1
                                                    );
                                                }
                                                return updatedConfig;
                                            });
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Stack>
                            )
                        )}
                        <StyledButton
                            onClick={addAssetOption}
                            variant="outlined"
                        >
                            Add Asset Classification Option
                        </StyledButton>
                    </Stack>
                </FormControl>
            )}

            <Stack spacing={'10px'}>
                <Typography variant="h6">Risk Ranges</Typography>
                {config.matrix.riskRanges.map((range, index) => (
                    <Stack
                        key={index}
                        direction={'row'}
                        alignItems="center"
                        justifyContent="flex-start"
                        spacing={'10px'}
                    >
                        <TextField
                            size="small"
                            type="number"
                            value={range.min}
                            onChange={(e) => {
                                const updatedConfig = { ...config };
                                updatedConfig.matrix.riskRanges[index].min =
                                    parseInt(e.target.value);
                                setConfig(updatedConfig);
                            }}
                            label="Min"
                        />
                        <TextField
                            size="small"
                            type="number"
                            value={range.max}
                            onChange={(e) => {
                                const updatedConfig = { ...config };
                                updatedConfig.matrix.riskRanges[index].max =
                                    parseInt(e.target.value);
                                setConfig(updatedConfig);
                            }}
                            label="Max"
                        />
                        <TextField
                            size="small"
                            value={range.text}
                            onChange={(e) => {
                                const updatedConfig = { ...config };
                                updatedConfig.matrix.riskRanges[index].text =
                                    e.target.value;
                                setConfig(updatedConfig);
                            }}
                            label="Rating"
                        />
                        <IconButton
                            aria-label="delete"
                            onClick={() => {
                                setConfig((prevConfig) => {
                                    const updatedConfig = { ...prevConfig };
                                    updatedConfig.matrix.riskRanges.splice(
                                        index,
                                        1
                                    );
                                    return updatedConfig;
                                });
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Stack>
                ))}
                {!isRiskRangeValid && (
                    <Typography color="error" variant="caption1">
                        {'At least one risk range is required.'}
                    </Typography>
                )}
                <StyledButton onClick={addRiskRange} variant="outlined">
                    Add Risk Range
                </StyledButton>
            </Stack>
        </Stack>
    );

    const renderAnalysisDisplay = (
        state: RiskAnalysisState,
        type: 'before' | 'after'
    ) => (
        <Stack spacing={'8px'}>
            <Typography variant="subtitle1">{'Likelihood'}</Typography>
            <Stack spacing={'3px'}>
                <FormControl component="fieldset" margin="normal">
                    <FormLabel component="legend">
                        <Typography
                            variant="body2"
                            color={theme.palette.text.mediumEmphasis}
                        >
                            {config.matrix.likelihood.text}
                        </Typography>
                    </FormLabel>
                    <RadioGroup
                        row
                        value={
                            state.likelihoodValue
                                ? state.likelihoodValue.toString()
                                : ''
                        }
                        onChange={(e) => handleLikelihoodChange(e, type)}
                    >
                        {config.matrix.likelihood.options.map(
                            (option, index) => (
                                <FormControlLabel
                                    key={index}
                                    value={option.value.toString()}
                                    control={<Radio />}
                                    label={
                                        <Typography
                                            variant="caption1"
                                            color={
                                                theme.palette.text.highEmphasis
                                            }
                                        >
                                            {option.text}
                                        </Typography>
                                    }
                                />
                            )
                        )}
                    </RadioGroup>
                </FormControl>
            </Stack>
            <Typography variant="subtitle1">Impact</Typography>
            <Stack spacing={'3px'}>
                {config.matrix.impact.map((question, qIndex) => (
                    <FormControl
                        key={qIndex}
                        component="fieldset"
                        margin="normal"
                    >
                        <FormLabel component="legend">
                            <Typography
                                variant="body2"
                                color={theme.palette.text.mediumEmphasis}
                            >
                                {question.text}
                            </Typography>
                        </FormLabel>
                        <RadioGroup
                            row
                            value={
                                state.impactValues &&
                                state.impactValues[qIndex] !== undefined
                                    ? state.impactValues[qIndex].toString()
                                    : '0'
                            }
                            onChange={(e) =>
                                handleImpactChange(e, qIndex, type)
                            }
                        >
                            {question.options.map((option, oIndex) => (
                                <FormControlLabel
                                    key={oIndex}
                                    value={option.value.toString()}
                                    control={<Radio />}
                                    label={
                                        <Typography
                                            variant="caption1"
                                            color={
                                                theme.palette.text.highEmphasis
                                            }
                                        >
                                            {option.text}
                                        </Typography>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                ))}
            </Stack>
            {useAssetClassification && config.matrix.assetClassification && (
                <Stack spacing={'3px'}>
                    <Typography variant="subtitle1">
                        Asset Category/Classification
                    </Typography>
                    <FormControl component="fieldset" margin="normal">
                        <FormLabel component="legend">
                            <Typography
                                variant="body2"
                                color={theme.palette.text.mediumEmphasis}
                            >
                                {config.matrix.assetClassification.text}
                            </Typography>
                        </FormLabel>
                        <RadioGroup
                            row
                            value={
                                state.assetValue
                                    ? state.assetValue.toString()
                                    : ''
                            }
                            onChange={(e) => handleAssetChange(e, type)}
                        >
                            {config.matrix.assetClassification.options.map(
                                (option, index) => (
                                    <FormControlLabel
                                        key={index}
                                        value={option.value.toString()}
                                        control={<Radio />}
                                        label={
                                            <Typography
                                                variant="caption1"
                                                color={
                                                    theme.palette.text
                                                        .highEmphasis
                                                }
                                            >
                                                {option.text}
                                            </Typography>
                                        }
                                    />
                                )
                            )}
                        </RadioGroup>
                    </FormControl>
                </Stack>
            )}
            <Typography variant="subtitle1">
                {'Final Value Calculation - ' +
                    type.charAt(0).toUpperCase() +
                    type.slice(1) +
                    ' RTP'}
            </Typography>
            <Stack width={'50%'}>
                <Stack direction={'row'} spacing={'10px'}>
                    <TextField
                        size="small"
                        label="L-value (Likelihood)"
                        value={state.likelihoodValue}
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        size="small"
                        label="I-value (Highest Impact Value)"
                        value={
                            state.impactValues.length > 0
                                ? Math.max(...Object.values(state.impactValues))
                                : 0
                        }
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                        margin="normal"
                    />
                    {useAssetClassification && (
                        <TextField
                            size="small"
                            label="A-value (Asset Classification)"
                            value={state.assetValue}
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                            margin="normal"
                        />
                    )}
                </Stack>
                <TextField
                    size="small"
                    label={
                        useAssetClassification
                            ? 'R-value (L-value * I-value * A-value)'
                            : 'R-value (L-value * I-value)'
                    }
                    value={state.riskValue}
                    InputProps={{
                        readOnly: true,
                    }}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    size="small"
                    label="Risk Rating"
                    value={state.riskRating}
                    InputProps={{
                        readOnly: true,
                    }}
                    fullWidth
                    margin="normal"
                />
            </Stack>
            <StyledButton
                variant="outlined"
                onClick={() => saveAnswers(type)}
                disabled={
                    !state.likelihoodValue ||
                    state.impactValues.length === 0 ||
                    (useAssetClassification && !state.assetValue)
                }
            >
                <Typography variant="caption1">{'Save'}</Typography>
            </StyledButton>
        </Stack>
    );

    return (
        <Stack spacing={'20px'} padding={'10px'}>
            <Stack
                spacing={'15px'}
                sx={{
                    border: `1px solid ${theme.palette.structuralColors.gray}`,
                    padding: '15px 10px',
                    borderRadius: '3px',
                }}
            >
                <Stack
                    direction={'row'}
                    sx={{ justifyContent: 'space-between' }}
                >
                    <Typography
                        variant="subtitle1"
                        color={theme.palette.text.highEmphasis}
                    >
                        {'Risk Analysis'}
                    </Typography>
                    <Button
                        onClick={() => setIsEditing(!isEditing)}
                        variant="outlined"
                    >
                        {isEditing ? 'View Analysis' : 'Edit Analysis Matrix'}
                    </Button>
                </Stack>
                <Divider />
                {isEditing ? (
                    renderAnalysisMatrixForm()
                ) : (
                    <>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="inherit"
                                variant="fullWidth"
                            >
                                <Tab
                                    label="Risk (Before RTP)"
                                    {...a11yProps(0)}
                                    sx={{
                                        backgroundColor:
                                            value === 0
                                                ? `${theme.palette.primary[300]}`
                                                : 'transparent',
                                        color:
                                            value === 0
                                                ? `${theme.palette.primary[500]}`
                                                : 'black',
                                        textTransform: 'none',
                                    }}
                                />
                                <Tab
                                    label="Risk (After RTP)"
                                    {...a11yProps(1)}
                                    sx={{
                                        backgroundColor:
                                            value === 0
                                                ? 'transparent'
                                                : `${theme.palette.primary[300]}`,
                                        color:
                                            value === 0
                                                ? 'black'
                                                : `${theme.palette.primary[500]}`,
                                        textTransform: 'none',
                                    }}
                                />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            {renderAnalysisDisplay(config.beforeRTP, 'before')}
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            {renderAnalysisDisplay(config.afterRTP, 'after')}
                        </CustomTabPanel>
                    </>
                )}
                {isEditing && (
                    <Stack direction={'row'} sx={{ justifyContent: 'center' }}>
                        <StyledButton
                            onClick={saveConfig}
                            variant="contained"
                            disabled={!isFormValid}
                        >
                            Save Configuration
                        </StyledButton>
                    </Stack>
                )}
                {renderImportMatrixDialog()}
            </Stack>
        </Stack>
    );
};

export default RiskAnalysisComponent;
