import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    Radio,
    RadioGroup,
    FormControlLabel,
    Checkbox,
    Stack,
    IconButton,
    Select,
    FormControl,
    MenuItem,
    Tooltip,
    Badge,
    Chip,
} from '@mui/material';
import { Answer, Question } from '../../utils/types';
import theme from '../../theme';
import Typography from '../../components/atoms/Typography';
import FileUploadModal from '../../components/organisms/FileUploadModal';
import { DocumentUpload, AnswerEditorModal } from './HelperComponents';
import {
    downloadFileFromGCP,
    markCommentAsViewedApi,
    removeFileFromAnswer,
} from '../../services/api';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddFormComments from '../../components/organisms/AddFormComments';
import { isEqual } from 'lodash';
import useSnackBar from '../../utils/hooks';
import CustomSnackbar from '../../components/molecules/CustomSnackbar';

interface QuestionComponentProps {
    question: Question;
    answer: Answer;
    onChange: (updatedAnswer: Partial<Answer>) => void;
    refreshAnswers: () => void;
    role: string;
    vendorId: string;
}

const statusOptions = [
    { label: 'Not Started', color: '#B2B2B2' },
    { label: 'Need More Info', color: '#0178D4' },
    { label: 'Response Accepted', color: '#24A149' },
    { label: 'Response Not Accepted', color: '#E05A4A' },
    { label: 'In Progress', color: '#FCBA08' },
];

const riskOptions = [
    { label: 'Not Started', color: '#B2B2B2' },
    { label: 'Low', color: '#F8B63B' },
    { label: 'Medium', color: '#EE741E' },
    { label: 'High', color: '#E50921' },
];

const QuestionComponent: React.FC<QuestionComponentProps> = ({
    question,
    answer = {
        formSubmissionId: '',
        questionId: question.id,
        answered: false,
        textAnswer: '',
        selectedOptions: [],
        note: '',
        documentIds: [],
        documents: [],
        internalReviewStatus: '',
        internalNotes: '',
        riskValue: '',
        comments: [],
        commentStatus: '',
    },
    onChange,
    refreshAnswers,
    role,
    vendorId,
}) => {
    const [localTextAnswer, setLocalTextAnswer] = useState(
        answer.textAnswer || ''
    );
    const [localNoteAnswer, setLocalNoteAnswer] = useState(answer.note || '');
    const [localInternalNote, setLocalInternalNote] = useState(
        answer.internalNotes || ''
    );
    const [fileUploadOpen, setFileUploadOpen] = useState(false);
    const [openAnswerModal, setOpenAnswerModal] = useState(false);
    const [openCompositeModal, setOpenCompositeModal] = useState(false);
    const [openInternalNoteModal, setOpenInternalNoteModal] = useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const { openSnackBar, handleClick, handleClose } = useSnackBar();

    const [openCommentsDrawer, setOpenCommentsDrawer] = React.useState(false);

    useEffect(() => {
        setLocalTextAnswer(answer.textAnswer || '');
    }, [answer.textAnswer]);

    useEffect(() => {
        setLocalNoteAnswer(answer.note || '');
    }, [answer.note]);

    useEffect(() => {
        setLocalInternalNote(answer.internalNotes || '');
    }, [answer.internalNotes]);

    const handleTextChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setLocalTextAnswer(event.target.value);
    };

    const handleSend = () => {
        onChange({ textAnswer: localTextAnswer, answered: true });
        handleAnswerModalClose();
    };
    const handleAnswerModalOpen = () => setOpenAnswerModal(true);
    const handleAnswerModalClose = () => setOpenAnswerModal(false);

    const handleNoteTextChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setLocalNoteAnswer(event.target.value);
    };

    const handleNoteSend = () => {
        onChange({ note: localNoteAnswer });
        handleCompositeModalClose();
    };
    const handleCompositeModalOpen = () => setOpenCompositeModal(true);
    const handleCompositeModalClose = () => setOpenCompositeModal(false);

    const handleInternalNoteChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setLocalInternalNote(event.target.value);
    };

    const handleInternalNoteSend = () => {
        onChange({ internalNotes: localInternalNote });
        handleInternalNoteModalClose();
    };
    const handleInternalNoteModalOpen = () => setOpenInternalNoteModal(true);
    const handleInternalNoteModalClose = () => setOpenInternalNoteModal(false);

    const handleInternalStatusChange = (
        event: React.ChangeEvent<{ value: string }>
    ) => {
        onChange({ internalReviewStatus: event.target.value });
    };

    const handleRiskValueChange = (
        event: React.ChangeEvent<{ value: string }>
    ) => {
        onChange({ riskValue: event.target.value });
    };

    const markCommentAsResloved = async (answerId) => {
        const response = await markCommentAsViewedApi(answerId);
        if (response) {
            refreshAnswers();
        } else {
            console.error('Error marking comment as viewed:', error);
        }
    };

    const handleCommentsDrawerOpen = () => setOpenCommentsDrawer(true);

    const handleCommentsDrawerClose = () => {
        setOpenCommentsDrawer(false);
    };

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({ selectedOptions: [event.target.value] });
    };

    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const value = event.target.value;
        const newSelectedOptions = answer.selectedOptions
            ? [...answer.selectedOptions]
            : [];
        if (event.target.checked) {
            newSelectedOptions.push(value);
        } else {
            const index = newSelectedOptions.indexOf(value);
            if (index > -1) {
                newSelectedOptions.splice(index, 1);
            }
        }
        onChange({ selectedOptions: newSelectedOptions });
    };

    const handleFileUploadToggle = (isOpen: boolean) => {
        setFileUploadOpen(isOpen);
    };

    const handleUploadComplete = (response: any) => {
        if (response && response.length > 0) {
            try {
                onChange({
                    documentIds: response,
                });
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    };

    const handleFileDownload = async (fileId: string, fileName: string) => {
        const response = await downloadFileFromGCP(fileId);
        if (response) {
            const blob = new Blob([response.data]);

            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            link.remove();

            window.URL.revokeObjectURL(downloadUrl);

            setMessage('File downloaded Successfully.');
            handleClick();
            setSuccess(true);
        } else {
            setMessage('Failed to download file.');
            handleClick();
            setSuccess(false);
        }
    };

    const handleFileDelete = async (ansId: string, fileId: string) => {
        const response = await removeFileFromAnswer({
            formSubmissionId: answer.formSubmissionId,
            answerId: ansId,
            fileId: fileId,
        });
        if (response) {
            refreshAnswers();
        } else {
            console.log('error');
        }
    };

    const commentBadgeVisible = answer.commentStatus === 'NOT_RESOLVED';

    const memoizedQuestion = useMemo(() => question, [question]);

    const [textareaHeight, setTextareaHeight] = useState('auto');
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            setTextareaHeight(`${textareaRef.current.scrollHeight}px`);
        }
    }, [question.text]);

    const [evidenceTextareaHeight, setEvidenceTextareaHeight] =
        useState('auto');
    const evidenceTextareaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (evidenceTextareaRef.current) {
            evidenceTextareaRef.current.style.height = 'auto';
            setEvidenceTextareaHeight(
                `${evidenceTextareaRef.current.scrollHeight}px`
            );
        }
    }, [question.evidenceRequirement]);

    const showInternalNote = () => {
        if (answer.internalNotes !== null) {
            return answer.internalNotes?.length > 0;
        } else {
            return false;
        }
    };

    return (
        <Stack>
            <Stack
                direction={'row'}
                sx={{
                    width: '100%',
                    height: '100%',
                }}
                spacing={'1.5px'}
            >
                <Stack
                    sx={{
                        width: '22%',
                        height: '100%',
                        alignItems: 'flex-start',
                    }}
                >
                    <textarea
                        value={memoizedQuestion.text}
                        ref={textareaRef}
                        readOnly
                        style={{
                            width: '99%',
                            overflow: 'hidden',
                            resize: 'none',
                            fontFamily: 'Mier-Book',
                            fontSize: '0.85rem',
                            fontWeight: '400',
                            lineHeight: '1.36rem',
                            border: 'none',
                            height: textareaHeight,
                        }}
                    />
                    {memoizedQuestion.domain.length > 0 && (
                        <Chip
                            label={memoizedQuestion.domain}
                            sx={{
                                color: theme.palette.text.highEmphasis,
                                backgroundColor: '#CBCBFF',
                            }}
                        />
                    )}
                </Stack>
                <Stack
                    sx={{
                        width: '18%',
                        height: '100%',
                    }}
                >
                    <textarea
                        value={memoizedQuestion.evidenceRequirement}
                        readOnly
                        style={{
                            width: '99%',
                            overflow: 'hidden',
                            resize: 'none',
                            fontFamily: 'Mier-Book',
                            fontSize: '0.85rem',
                            fontWeight: '400',
                            lineHeight: '1.36rem',
                            border: 'none',
                            height: evidenceTextareaHeight,
                        }}
                        ref={evidenceTextareaRef}
                    />
                </Stack>
                <Stack
                    sx={{
                        minHeight: '140px',
                        width: '25%',
                    }}
                    direction={'row'}
                >
                    {question.answerType === 'TEXT' && (
                        <Stack
                            sx={{
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            <Stack
                                sx={{
                                    width: '60%',
                                    height: '100%',
                                    paddingTop: '7px',
                                }}
                            >
                                <AnswerEditorModal
                                    answer={answer}
                                    modelOpenState={openAnswerModal}
                                    answerValue={localTextAnswer}
                                    handleModalOpening={handleAnswerModalOpen}
                                    handleModalClosing={handleAnswerModalClose}
                                    handleAnswerChange={handleTextChange}
                                    handleAnswerSend={handleSend}
                                    editText="Edit Answer"
                                    addText="Add Answer"
                                    isInternalNote={false}
                                    displayedText={answer.textAnswer}
                                />
                            </Stack>
                        </Stack>
                    )}
                    {question.answerType === 'RADIO' && (
                        <Stack
                            sx={{
                                height: '100%',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                width: '100%',
                            }}
                        >
                            <RadioGroup
                                value={answer.selectedOptions?.[0] || ''}
                                onChange={handleRadioChange}
                            >
                                {question.options?.map((option, index) => (
                                    <FormControlLabel
                                        key={index}
                                        value={option.value}
                                        control={<Radio />}
                                        label={
                                            <Typography variant="caption2">
                                                {option.text}
                                            </Typography>
                                        }
                                    />
                                ))}
                            </RadioGroup>
                        </Stack>
                    )}
                    {question.answerType === 'CHECKBOX' && (
                        <Stack
                            sx={{
                                height: '100%',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                width: '100%',
                            }}
                        >
                            {question.options?.map((option, index) => (
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox
                                            checked={
                                                answer.selectedOptions?.includes(
                                                    option.value
                                                ) || false
                                            }
                                            onChange={handleCheckboxChange}
                                            value={option.value}
                                        />
                                    }
                                    label={
                                        <Typography variant="caption2">
                                            {option.text}
                                        </Typography>
                                    }
                                />
                            ))}
                        </Stack>
                    )}
                    {question.answerType === 'FILE_UPLOAD' && (
                        <Stack
                            sx={{
                                height: '100%',
                                width: '100%',
                            }}
                        >
                            <DocumentUpload
                                documents={answer.documents}
                                onUploadToggle={handleFileUploadToggle}
                                handleFileDownload={(fileId, fileName) =>
                                    handleFileDownload(fileId, fileName)
                                }
                                handleFileDelete={(ansId, fileId) =>
                                    handleFileDelete(ansId, fileId)
                                }
                                ansId={answer.id}
                            />
                        </Stack>
                    )}
                    {question.answerType === 'COMPOSITE' && (
                        <Stack
                            direction={'row'}
                            sx={{
                                justifyContent: 'flex-start',
                                height: '100%',
                                width: '100%',
                            }}
                        >
                            <Stack
                                sx={{
                                    height: '100%',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    width: '40%',
                                }}
                            >
                                <RadioGroup
                                    value={answer.selectedOptions?.[0] || ''}
                                    onChange={handleRadioChange}
                                >
                                    {question.options?.map((option, index) => (
                                        <FormControlLabel
                                            key={index}
                                            value={option.value}
                                            control={<Radio />}
                                            label={
                                                <Typography variant="caption2">
                                                    {option.text}
                                                </Typography>
                                            }
                                        />
                                    ))}
                                </RadioGroup>
                            </Stack>
                            {question.noteAllowed && (
                                <Stack
                                    sx={{
                                        width: '60%',
                                        height: '100%',
                                        paddingTop: '7px',
                                    }}
                                >
                                    <AnswerEditorModal
                                        answer={answer}
                                        modelOpenState={openCompositeModal}
                                        answerValue={localNoteAnswer}
                                        handleModalOpening={
                                            handleCompositeModalOpen
                                        }
                                        handleModalClosing={
                                            handleCompositeModalClose
                                        }
                                        handleAnswerChange={
                                            handleNoteTextChange
                                        }
                                        handleAnswerSend={handleNoteSend}
                                        editText="Edit Answer"
                                        addText="Add Answer"
                                        isInternalNote={false}
                                        displayedText={answer.note}
                                    />
                                </Stack>
                            )}
                        </Stack>
                    )}
                </Stack>
                <Stack
                    sx={{
                        width: '5%',
                    }}
                >
                    <IconButton onClick={handleCommentsDrawerOpen}>
                        <Badge
                            color={commentBadgeVisible ? 'warning' : 'success'}
                            badgeContent={
                                answer.comments != null
                                    ? answer.comments.length
                                    : ''
                            }
                        >
                            <QuestionAnswerOutlinedIcon />
                        </Badge>
                    </IconButton>
                </Stack>
                <Stack
                    sx={{
                        width: '20%',
                        height: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexDirection: 'row',
                    }}
                >
                    <Stack
                        sx={{
                            height: '100%',
                            justifyContent: 'flex-start',
                            width: '90%',
                        }}
                    >
                        {role === 'ADMIN' ? (
                            <Stack
                                sx={{
                                    paddingTop: '7px',
                                    width: '100%',
                                }}
                                spacing={'2px'}
                            >
                                <FormControl>
                                    <Select
                                        size="small"
                                        value={
                                            answer.internalReviewStatus ||
                                            'Not Started'
                                        }
                                        onChange={(e) =>
                                            handleInternalStatusChange(e)
                                        }
                                        displayEmpty
                                        inputProps={{
                                            'aria-label': 'Status Select',
                                        }}
                                        sx={{
                                            width: '15vw',
                                        }}
                                        defaultValue="Not Started"
                                    >
                                        {statusOptions.map((option, index) => (
                                            <MenuItem
                                                key={index}
                                                value={option.label}
                                                style={{
                                                    color: option.color,
                                                }}
                                            >
                                                <Stack
                                                    direction={'row'}
                                                    sx={{
                                                        justifyContent:
                                                            'flex-start',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <CircleIcon
                                                        style={{
                                                            width: 16,
                                                            height: 16,
                                                            borderRadius: '50%',
                                                            color: option.color,
                                                            marginRight: '5px',
                                                        }}
                                                    />
                                                    <Typography
                                                        variant="caption1"
                                                        sx={{
                                                            color: option.color,
                                                        }}
                                                    >
                                                        {option.label}
                                                    </Typography>
                                                </Stack>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Stack
                                    sx={{
                                        width: '100%',
                                    }}
                                >
                                    <Typography
                                        variant="caption2"
                                        color={
                                            theme.palette.text.mediumEmphasis
                                        }
                                    >
                                        {'Internal Notes'}
                                    </Typography>
                                    <AnswerEditorModal
                                        answer={answer}
                                        modelOpenState={openInternalNoteModal}
                                        answerValue={localInternalNote}
                                        handleModalOpening={
                                            handleInternalNoteModalOpen
                                        }
                                        handleModalClosing={
                                            handleInternalNoteModalClose
                                        }
                                        handleAnswerChange={
                                            handleInternalNoteChange
                                        }
                                        handleAnswerSend={
                                            handleInternalNoteSend
                                        }
                                        editText="Edit Note"
                                        addText="Add Note"
                                        isInternalNote={showInternalNote()}
                                        displayedText={answer.internalNotes}
                                    />
                                </Stack>
                            </Stack>
                        ) : (
                            <Stack
                                direction={'row'}
                                sx={{
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    paddingLeft: '10px',
                                }}
                            >
                                <CircleIcon
                                    style={{
                                        width: 16,
                                        height: 16,
                                        borderRadius: '50%',
                                        color:
                                            answer.internalReviewStatus !==
                                                'Not Started' &&
                                            answer.internalReviewStatus !==
                                                null &&
                                            answer.internalReviewStatus !== ''
                                                ? statusOptions.find(
                                                      (option) =>
                                                          option.label ===
                                                          answer.internalReviewStatus
                                                  )?.color
                                                : 'white',
                                        marginRight: '5px',
                                    }}
                                />
                                <Typography
                                    variant="caption1"
                                    sx={{
                                        color:
                                            answer.internalReviewStatus !==
                                                'Not Started' &&
                                            answer.internalReviewStatus !==
                                                null &&
                                            answer.internalReviewStatus !== ''
                                                ? statusOptions.find(
                                                      (option) =>
                                                          option.label ===
                                                          answer.internalReviewStatus
                                                  )?.color
                                                : undefined,
                                    }}
                                >
                                    {answer.internalReviewStatus !==
                                        'Not Started' &&
                                    answer.internalReviewStatus !== null &&
                                    answer.internalReviewStatus !== ''
                                        ? answer.internalReviewStatus
                                        : ''}
                                </Typography>
                                {answer.internalReviewStatus !==
                                    'Not Started' &&
                                answer.internalReviewStatus !== null &&
                                answer.internalReviewStatus !== '' ? (
                                    <Tooltip
                                        title="Please refer to comments for more details."
                                        slotProps={{
                                            popper: {
                                                modifiers: [
                                                    {
                                                        name: 'offset',
                                                        options: {
                                                            offset: [0, -14],
                                                        },
                                                    },
                                                ],
                                            },
                                        }}
                                    >
                                        <InfoOutlinedIcon
                                            style={{
                                                color: '#0178D4',
                                                width: 16,
                                                height: 16,
                                                paddingLeft: '2px',
                                                paddingBottom: '2px',
                                            }}
                                        />
                                    </Tooltip>
                                ) : (
                                    ''
                                )}
                            </Stack>
                        )}
                    </Stack>
                    <AddFormComments
                        open={openCommentsDrawer}
                        onToggle={handleCommentsDrawerClose}
                        formSubmissionId={answer.formSubmissionId}
                        questionId={answer.questionId}
                        refreshAnswers={refreshAnswers}
                        comments={answer.comments}
                        userRole={role}
                        handleMarkAsResloved={() =>
                            markCommentAsResloved(answer.id)
                        }
                    />
                </Stack>
                <Stack
                    sx={{
                        width: '10%',
                        height: '100%',
                    }}
                >
                    {role === 'ADMIN' ? (
                        <Stack sx={{ paddingTop: '7px' }}>
                            <FormControl>
                                <Select
                                    size="small"
                                    value={answer.riskValue || 'Not Started'}
                                    onChange={(e) => handleRiskValueChange(e)}
                                    displayEmpty
                                    inputProps={{
                                        'aria-label': 'Status Select',
                                    }}
                                    sx={{
                                        width: '100%',
                                    }}
                                    defaultValue="Not Started"
                                >
                                    {riskOptions.map((option, index) => (
                                        <MenuItem
                                            key={index}
                                            value={option.label}
                                            style={{
                                                color: option.color,
                                            }}
                                        >
                                            <Stack
                                                direction={'row'}
                                                sx={{
                                                    justifyContent:
                                                        'flex-start',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <CircleIcon
                                                    style={{
                                                        width: 16,
                                                        height: 16,
                                                        borderRadius: '50%',
                                                        color: option.color,
                                                        marginRight: '5px',
                                                    }}
                                                />
                                                <Typography
                                                    variant="caption1"
                                                    sx={{
                                                        color: option.color,
                                                    }}
                                                >
                                                    {option.label}
                                                </Typography>
                                            </Stack>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Stack>
                    ) : (
                        <></>
                    )}
                </Stack>
            </Stack>
            <Stack>
                {question.fileUploadAllowed && (
                    <DocumentUpload
                        documents={answer.documents}
                        onUploadToggle={handleFileUploadToggle}
                        handleFileDownload={(fileId, fileName) =>
                            handleFileDownload(fileId, fileName)
                        }
                        handleFileDelete={(ansId, fileId) =>
                            handleFileDelete(ansId, fileId)
                        }
                        ansId={answer.id}
                    />
                )}
            </Stack>
            <FileUploadModal
                open={fileUploadOpen}
                onToggle={handleFileUploadToggle}
                id={vendorId}
                onUploadComplete={handleUploadComplete}
                uploadFrom={'Vendor'}
                currentFileCount={answer.documents.length || 0}
            />
            <CustomSnackbar
                open={openSnackBar}
                message={message}
                success={success}
                onClose={handleClose}
                autoHideDuration={1800}
            />
        </Stack>
    );
};

export default React.memo(QuestionComponent, (prevProps, nextProps) => {
    // Perform deep equality check for the `answer` and `question` props
    return (
        isEqual(prevProps.answer, nextProps.answer) &&
        isEqual(prevProps.question, nextProps.question)
    );
});
