import {
    Avatar,
    Button,
    Chip,
    IconButton,
    Stack,
    styled,
    Tooltip,
} from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import theme from '../../theme';
import { PolicyInfoRowType, ProcessedRiskData } from '../types';
import React, { useMemo } from 'react';
import Typography from '../../components/atoms/Typography';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ChatIcon from '@mui/icons-material/Chat';
import ArchiveIcon from '@mui/icons-material/Archive';
import DownloadIcon from '@mui/icons-material/Download';

export const getPolicyInfoColumns = (
    onRowClick: (row: PolicyInfoRowType) => void
): GridColDef[] => [
    {
        field: 'referenceId',
        headerName: 'Reference ID',
        flex: 0.5,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Stack sx={{ justifyContent: 'center', height: '100%' }}>
                    <Typography
                        variant="body2"
                        color={theme.palette.text.highEmphasis}
                    >
                        {params.value}
                    </Typography>
                </Stack>
            );
        },
    },
    {
        field: 'name',
        headerName: 'Title',
        flex: 1.5,
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Stack sx={{ justifyContent: 'center', height: '100%' }}>
                    <Button
                        onClick={() => onRowClick(params.row)}
                        sx={{
                            '&:hover': {
                                backgroundColor: 'inherit',
                            },
                            whiteSpace: 'break-spaces',
                            width: '100%',
                            justifyContent: 'flex-start',
                            flexDirection: 'row',
                            padding: '0px',
                        }}
                        disableElevation
                        disableRipple
                    >
                        <Typography
                            variant="body2"
                            color={theme.palette.accentColors.blue}
                            sx={{ textTransform: 'none' }}
                        >
                            <u>{params.value}</u>
                        </Typography>
                    </Button>
                </Stack>
            );
        },
    },
    {
        field: 'registryStatus',
        headerName: 'Status',
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
            const formatStatus = (status: string) => {
                if (!status) return '';

                return status
                    .toLowerCase()
                    .replace(/_/g, ' ')
                    .replace(/\b\w/g, (char) => char.toUpperCase());
            };

            return (
                <Stack sx={{ justifyContent: 'center', height: '100%' }}>
                    <Typography
                        variant="body2"
                        color={theme.palette.text.highEmphasis}
                    >
                        {formatStatus(params.value)}
                    </Typography>
                </Stack>
            );
        },
    },
    {
        field: 'updatedAt',
        headerName: 'Updated Date',
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
            const date = new Date(params.value);
            const formattedDate = date.toISOString().split('T')[0];

            return (
                <Stack
                    sx={{
                        justifyContent: 'center',
                        height: '100%',
                    }}
                >
                    <Typography
                        variant="body2"
                        color={theme.palette.text.highEmphasis}
                    >
                        {formattedDate}
                    </Typography>
                </Stack>
            );
        },
    },
];

export const getRiskRegisterColumns = (
    onRowClick: (row: ProcessedRiskData) => void
): GridColDef[] => [
    {
        field: 'referenceId',
        headerName: 'Reference ID',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Stack sx={{ justifyContent: 'center', height: '100%' }}>
                    <Typography
                        variant="body2"
                        color={theme.palette.text.highEmphasis}
                    >
                        {params.value}
                    </Typography>
                </Stack>
            );
        },
    },
    {
        field: 'name',
        headerName: 'Name',
        flex: 2,
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Stack
                    sx={{
                        justifyContent: 'center',
                        height: '100%',
                        alignItems: 'flex-start',
                    }}
                >
                    <Button
                        onClick={() => onRowClick(params.row)}
                        sx={{
                            '&:hover': {
                                backgroundColor: 'inherit',
                            },
                            textWrap: 'wrap',
                            width: '100%',
                            justifyContent: 'flex-start',
                            padding: '0px',
                        }}
                        disableElevation
                        disableRipple
                    >
                        <Typography
                            variant="body2"
                            color={theme.palette.accentColors.blue}
                            sx={{ textTransform: 'none' }}
                            textAlign={'left'}
                        >
                            <u>{params.value}</u>
                        </Typography>
                    </Button>
                </Stack>
            );
        },
    },
    {
        field: 'status',
        headerName: 'Remediation Status',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params: GridRenderCellParams) => {
            const formatStatus = (status: string) => {
                if (!status) return '';

                return status
                    .toLowerCase()
                    .replace(/_/g, ' ')
                    .replace(/\b\w/g, (char) => char.toUpperCase());
            };
            return (
                <Stack sx={{ justifyContent: 'center', height: '100%' }}>
                    <Typography
                        variant="body2"
                        color={theme.palette.text.highEmphasis}
                    >
                        {formatStatus(params.value)}
                    </Typography>
                </Stack>
            );
        },
    },
    {
        field: 'riskCategory',
        headerName: 'Risk Category',
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Stack sx={{ justifyContent: 'center', height: '100%' }}>
                    <Typography
                        variant="body2"
                        color={theme.palette.text.highEmphasis}
                    >
                        {params.value}
                    </Typography>
                </Stack>
            );
        },
    },
    {
        field: 'riskSubCategory',
        headerName: 'Risk Sub Category',
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Stack sx={{ justifyContent: 'center', height: '100%' }}>
                    <Typography
                        variant="body2"
                        color={theme.palette.text.highEmphasis}
                    >
                        {params.value}
                    </Typography>
                </Stack>
            );
        },
    },
    {
        field: 'beforeRisk',
        headerName: 'Before RTP Risk',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params: GridRenderCellParams) => {
            const [riskRating, riskValue] = params.value?.split(' ') ?? [];

            return (
                <Chip
                    color="warning"
                    variant="outlined"
                    label={
                        <Typography variant="body2" textTransform="uppercase">
                            {`${riskRating.trim()} ${riskValue}`}
                        </Typography>
                    }
                    sx={{
                        borderRadius: '8px',
                        width: '160px',
                    }}
                />
            );
        },
    },
    {
        field: 'afterRisk',
        headerName: 'After RTP Risk',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params: GridRenderCellParams) => {
            const [riskRating, riskValue] = params.value?.split(' ') ?? [];

            return (
                <Chip
                    color="warning"
                    variant="outlined"
                    label={
                        <Typography variant="body2" textTransform="uppercase">
                            {`${riskRating.trim()} ${riskValue}`}
                        </Typography>
                    }
                    sx={{
                        borderRadius: '8px',
                        width: '160px',
                    }}
                />
            );
        },
    },
    {
        field: 'updatedAt',
        headerName: 'Updated Date',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params: GridRenderCellParams) => {
            const date = new Date(params.value);
            const formattedDate = date.toISOString().split('T')[0];

            return (
                <Stack
                    sx={{
                        justifyContent: 'center',
                        height: '100%',
                    }}
                >
                    <Typography
                        variant="body2"
                        color={theme.palette.text.highEmphasis}
                    >
                        {formattedDate}
                    </Typography>
                </Stack>
            );
        },
    },
];

export const getAllFormsColumns = (
    onRowClick: (row: PolicyInfoRowType) => void
): GridColDef[] => [
    {
        field: 'title',
        headerName: 'Title',
        flex: 1.5,
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Stack sx={{ justifyContent: 'center', height: '100%' }}>
                    <Button
                        onClick={() => onRowClick(params.row)}
                        sx={{
                            '&:hover': {
                                backgroundColor: 'inherit',
                            },
                            whiteSpace: 'break-spaces',
                            width: '100%',
                            justifyContent: 'flex-start',
                            flexDirection: 'row',
                            padding: '0px',
                        }}
                        disableElevation
                        disableRipple
                    >
                        <Typography
                            variant="body2"
                            color={theme.palette.accentColors.blue}
                            sx={{ textTransform: 'none' }}
                        >
                            <u>{params.value}</u>
                        </Typography>
                    </Button>
                </Stack>
            );
        },
    },
    {
        field: 'description',
        headerName: 'Description',
        flex: 2,
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Stack sx={{ justifyContent: 'center', height: '100%' }}>
                    <Typography
                        variant="body2"
                        color={theme.palette.text.highEmphasis}
                    >
                        {params.value}
                    </Typography>
                </Stack>
            );
        },
    },
    {
        field: 'updatedAt',
        headerName: 'Updated Date',
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
            const date = new Date(params.value);
            const formattedDate = date.toISOString().split('T')[0];

            return (
                <Stack
                    sx={{
                        justifyContent: 'center',
                        height: '100%',
                    }}
                >
                    <Typography
                        variant="body2"
                        color={theme.palette.text.highEmphasis}
                    >
                        {formattedDate}
                    </Typography>
                </Stack>
            );
        },
    },
];

export const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .no-rows-primary': {
        fill: theme.palette.mode === 'light' ? '#AEB8C2' : '#3D4751',
    },
    '& .no-rows-secondary': {
        fill: theme.palette.mode === 'light' ? '#E8EAED' : '#1D2126',
    },
}));

export const useDocumentInfoColumns = (
    setDocumentId,
    setDocumentType,
    props,
    handleEditCompOpen,
    handlePreviewOpen,
    handleFileDownload
) => {
    return useMemo<GridColDef[]>(
        () => [
            {
                field: 'titleUrl',
                headerName: 'Document Name',
                flex: 3,
                renderCell: (params: GridRenderCellParams) => {
                    return (
                        <Stack
                            sx={{ justifyContent: 'center', height: '100%' }}
                        >
                            {params.row.url === null ? (
                                <Button
                                    onClick={() => {
                                        handlePreviewOpen(
                                            params.row.id,
                                            params.row.title
                                        );
                                    }}
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: 'inherit',
                                        },
                                        whiteSpace: 'break-spaces',
                                        width: '100%',
                                        justifyContent: 'flex-start',
                                        flexDirection: 'row',
                                        padding: '0px',
                                        textTransform: 'none',
                                    }}
                                    disableElevation
                                    disableRipple
                                >
                                    <Typography
                                        variant="body1"
                                        color={theme.palette.accentColors.blue}
                                    >
                                        {params.row.title}
                                    </Typography>
                                </Button>
                            ) : (
                                <Button
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: 'inherit',
                                        },
                                        whiteSpace: 'break-spaces',
                                        width: '100%',
                                        justifyContent: 'flex-start',
                                        flexDirection: 'row',
                                        padding: '0px',
                                        textTransform: 'none',
                                    }}
                                    disableElevation
                                    disableRipple
                                    href={params.row.url}
                                >
                                    <Typography
                                        variant="body1"
                                        color={theme.palette.accentColors.blue}
                                    >
                                        {params.row.title}
                                    </Typography>
                                </Button>
                            )}
                        </Stack>
                    );
                },
            },
            {
                field: 'associatedUsers',
                headerName: 'Associated Users',
                flex: 2,
                renderCell: (params: GridRenderCellParams) => {
                    function stringToColor(string: string) {
                        let hash = 0;
                        let i;

                        for (i = 0; i < string.length; i += 1) {
                            hash = string.charCodeAt(i) + ((hash << 5) - hash);
                        }

                        let color = '#';

                        for (i = 0; i < 3; i += 1) {
                            const value = (hash >> (i * 8)) & 0xff;
                            color += `00${value.toString(16)}`.slice(-2);
                        }

                        return color;
                    }

                    function stringAvatar(name: string) {
                        const nameParts = name.split(' ');
                        let initials = nameParts[0][0].toUpperCase(); // Always take the first letter of the first part

                        // Check if the name has more than one part and add the initial of the second part if available
                        if (nameParts.length > 1) {
                            initials += nameParts[1][0].toUpperCase();
                        }

                        return {
                            sx: {
                                bgcolor: stringToColor(name),
                            },
                            children: initials,
                        };
                    }

                    return (
                        <Stack
                            sx={{ justifyContent: 'center', height: '100%' }}
                        >
                            {params.value.length > 0 ? (
                                <Stack
                                    direction="row"
                                    flexWrap="wrap"
                                    gap={'3px'}
                                    sx={{
                                        height: '100%',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                    }}
                                >
                                    {params.value.map((data) => (
                                        <Tooltip
                                            key={data.id}
                                            slotProps={{
                                                popper: {
                                                    modifiers: [
                                                        {
                                                            name: 'offset',
                                                            options: {
                                                                offset: [0, -8],
                                                            },
                                                        },
                                                    ],
                                                },
                                            }}
                                            title={
                                                <Stack>
                                                    <Typography variant="caption1">
                                                        {'Name: ' + data.name}
                                                    </Typography>
                                                    <Typography variant="caption1">
                                                        {'Email: ' + data.email}
                                                    </Typography>
                                                </Stack>
                                            }
                                            arrow
                                        >
                                            <Avatar
                                                {...stringAvatar(data.name)}
                                                style={{
                                                    height: '34px',
                                                    width: '34px',
                                                    fontSize: '16px',
                                                }}
                                            />
                                        </Tooltip>
                                    ))}
                                </Stack>
                            ) : (
                                <Stack
                                    sx={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        paddingTop: '0.7vh',
                                    }}
                                >
                                    <Typography variant="caption1">
                                        {'---'}
                                    </Typography>
                                </Stack>
                            )}
                        </Stack>
                    );
                },
            },
            {
                field: 'remark',
                headerName: 'Add/View Remarks',
                type: 'actions',
                flex: 1.2,
                getActions: (params) => [
                    <Stack
                        key={params.id}
                        sx={{ justifyContent: 'center', height: '100%' }}
                    >
                        <IconButton
                            onClick={() => {
                                setDocumentId(params.row.id);
                                setDocumentType(params.row.type);
                                props.setRemarkTab();
                            }}
                        >
                            <ChatIcon />
                        </IconButton>
                    </Stack>,
                ],
            },
            {
                field: 'editArchive',
                headerName: 'Edit/Archive',
                type: 'actions',
                flex: 1,
                getActions: (params) => [
                    <Stack
                        key={params.id}
                        sx={{ justifyContent: 'center', height: '100%' }}
                    >
                        <Tooltip
                            key={params.id}
                            title="Edit"
                            slotProps={{
                                popper: {
                                    modifiers: [
                                        {
                                            name: 'offset',
                                            options: {
                                                offset: [0, -14],
                                            },
                                        },
                                    ],
                                },
                            }}
                        >
                            <IconButton
                                onClick={() => {
                                    handleEditCompOpen(
                                        params.row.id,
                                        params.row.type,
                                        params.row.title,
                                        params.row.url
                                    );
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>,
                    <Stack
                        key={params.id}
                        sx={{ justifyContent: 'center', height: '100%' }}
                    >
                        <Tooltip
                            key={params.id}
                            title="Archive"
                            slotProps={{
                                popper: {
                                    modifiers: [
                                        {
                                            name: 'offset',
                                            options: {
                                                offset: [0, -14],
                                            },
                                        },
                                    ],
                                },
                            }}
                        >
                            <IconButton
                                onClick={() => {
                                    console.log('hi');
                                }}
                            >
                                <ArchiveIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>,
                ],
            },
            {
                field: 'download',
                headerName: 'Download',
                type: 'actions',
                flex: 0.8,
                getActions: (params) => [
                    <Stack
                        key={params.id}
                        sx={{ justifyContent: 'center', height: '100%' }}
                    >
                        <Tooltip
                            key={params.id}
                            title="Download"
                            slotProps={{
                                popper: {
                                    modifiers: [
                                        {
                                            name: 'offset',
                                            options: {
                                                offset: [0, -14],
                                            },
                                        },
                                    ],
                                },
                            }}
                        >
                            <IconButton
                                onClick={() => {
                                    handleFileDownload(
                                        params.row.id,
                                        params.row.title
                                    );
                                }}
                            >
                                <DownloadIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>,
                ],
            },
        ],
        [
            setDocumentId,
            setDocumentType,
            props,
            handleEditCompOpen,
            handlePreviewOpen,
            handleFileDownload,
        ]
    );
};

export const useRiskDocumentInfoColumns = (
    setDocumentId,
    setDocumentType,
    props,
    handleEditCompOpen,
    handlePreviewOpen
) => {
    return useMemo<GridColDef[]>(
        () => [
            {
                field: 'titleUrl',
                headerName: 'Document Name',
                flex: 3,
                renderCell: (params: GridRenderCellParams) => {
                    return (
                        <Stack
                            sx={{ justifyContent: 'center', height: '100%' }}
                        >
                            {params.row.url === null ? (
                                <Button
                                    onClick={() => {
                                        handlePreviewOpen(
                                            params.row.id,
                                            params.row.title
                                        );
                                    }}
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: 'inherit',
                                        },
                                        whiteSpace: 'break-spaces',
                                        width: '100%',
                                        justifyContent: 'flex-start',
                                        flexDirection: 'row',
                                        padding: '0px',
                                        textTransform: 'none',
                                    }}
                                    disableElevation
                                    disableRipple
                                >
                                    <Typography
                                        variant="body1"
                                        color={theme.palette.accentColors.blue}
                                    >
                                        {params.row.title}
                                    </Typography>
                                </Button>
                            ) : (
                                <Button
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: 'inherit',
                                        },
                                        whiteSpace: 'break-spaces',
                                        width: '100%',
                                        justifyContent: 'flex-start',
                                        flexDirection: 'row',
                                        padding: '0px',
                                        textTransform: 'none',
                                    }}
                                    disableElevation
                                    disableRipple
                                    href={params.row.url}
                                >
                                    <Typography
                                        variant="body1"
                                        color={theme.palette.accentColors.blue}
                                    >
                                        {params.row.title}
                                    </Typography>
                                </Button>
                            )}
                        </Stack>
                    );
                },
            },
            {
                field: 'remark',
                headerName: 'Add/View Remarks',
                type: 'actions',
                flex: 1,
                getActions: (params) => [
                    <Stack
                        key={params.id}
                        sx={{ justifyContent: 'center', height: '100%' }}
                    >
                        <IconButton
                            onClick={() => {
                                setDocumentId(params.row.id);
                                setDocumentType(params.row.type);
                                props.setRemarkTab();
                            }}
                        >
                            <ChatIcon />
                        </IconButton>
                    </Stack>,
                ],
            },
            {
                field: 'actions',
                headerName: 'Action',
                type: 'actions',
                flex: 1,
                getActions: (params) => [
                    <Stack
                        key={params.id}
                        sx={{ justifyContent: 'center', height: '100%' }}
                    >
                        <Tooltip
                            key={params.id}
                            title="Edit"
                            slotProps={{
                                popper: {
                                    modifiers: [
                                        {
                                            name: 'offset',
                                            options: {
                                                offset: [0, -14],
                                            },
                                        },
                                    ],
                                },
                            }}
                        >
                            <IconButton
                                onClick={() => {
                                    handleEditCompOpen(
                                        params.row.id,
                                        params.row.type,
                                        params.row.title,
                                        params.row.url
                                    );
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>,
                    <Stack
                        key={params.id}
                        sx={{ justifyContent: 'center', height: '100%' }}
                    >
                        <Tooltip
                            key={params.id}
                            title="Archive"
                            slotProps={{
                                popper: {
                                    modifiers: [
                                        {
                                            name: 'offset',
                                            options: {
                                                offset: [0, -14],
                                            },
                                        },
                                    ],
                                },
                            }}
                        >
                            <IconButton
                                onClick={() => {
                                    console.log('hi');
                                }}
                            >
                                <ArchiveIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>,
                ],
            },
        ],
        [
            setDocumentId,
            setDocumentType,
            props,
            handleEditCompOpen,
            handlePreviewOpen,
        ]
    );
};

export const getUsersColumns = (onRowClick: (row) => void): GridColDef[] => [
    {
        field: 'name',
        headerName: 'NAME',
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Stack sx={{ paddingTop: '1.5vh' }}>
                    <Typography
                        variant="body2"
                        color={theme.palette.text.highEmphasis}
                    >
                        {params.value}
                    </Typography>
                </Stack>
            );
        },
    },
    {
        field: 'email',
        headerName: 'EMAIL',
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Stack sx={{ paddingTop: '1.5vh' }}>
                    <Typography
                        variant="body2"
                        color={theme.palette.text.highEmphasis}
                    >
                        {params.value}
                    </Typography>
                </Stack>
            );
        },
    },
    {
        field: 'role',
        headerName: 'ROLE',
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Stack sx={{ paddingTop: '1.5vh' }}>
                    <Typography
                        variant="body2"
                        color={theme.palette.text.highEmphasis}
                    >
                        {params.value}
                    </Typography>
                </Stack>
            );
        },
    },
    {
        field: 'remove',
        headerName: 'REMOVE USER',
        flex: 0.5,
        align: 'center',
        renderCell: (params: GridRenderCellParams) => {
            const userEmail = localStorage.getItem('email');

            if (userEmail === params.row.email) {
                return null;
            }

            return (
                <Button
                    onClick={() => onRowClick(params.row)}
                    sx={{
                        '&:hover': {
                            backgroundColor: 'inherit',
                        },
                        height: '100%',
                        whiteSpace: 'break-spaces',
                        width: '100%',
                        padding: '0px',
                    }}
                    startIcon={<DeleteIcon />}
                    disableElevation
                    disableRipple
                />
            );
        },
    },
];

export function debounce(func: (...args: any[]) => void, wait: number) {
    let timeout: NodeJS.Timeout;
    return function (...args: any[]) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), wait);
    };
}

export const getVendorInfoColumns = (
    onRowClick: (row: PolicyInfoRowType) => void
): GridColDef[] => [
    {
        field: 'referenceId',
        headerName: 'Reference ID',
        flex: 0.5,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Stack sx={{ justifyContent: 'center', height: '100%' }}>
                    <Typography
                        variant="body2"
                        color={theme.palette.text.highEmphasis}
                    >
                        {params.value}
                    </Typography>
                </Stack>
            );
        },
    },
    {
        field: 'name',
        headerName: 'Title',
        flex: 1.5,
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Stack sx={{ justifyContent: 'center', height: '100%' }}>
                    <Button
                        onClick={() => onRowClick(params.row)}
                        sx={{
                            '&:hover': {
                                backgroundColor: 'inherit',
                            },
                            whiteSpace: 'break-spaces',
                            width: '100%',
                            justifyContent: 'flex-start',
                            flexDirection: 'row',
                            padding: '0px',
                        }}
                        disableElevation
                        disableRipple
                    >
                        <Typography
                            variant="body2"
                            color={theme.palette.accentColors.blue}
                            sx={{ textTransform: 'none' }}
                        >
                            <u>{params.value}</u>
                        </Typography>
                    </Button>
                </Stack>
            );
        },
    },
    {
        field: 'vendorStatus',
        headerName: 'Status',
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
            const formatStatus = (status: string) => {
                if (!status) return '';

                return status
                    .toLowerCase()
                    .replace(/_/g, ' ')
                    .replace(/\b\w/g, (char) => char.toUpperCase());
            };

            return (
                <Stack sx={{ justifyContent: 'center', height: '100%' }}>
                    <Typography
                        variant="body2"
                        color={theme.palette.text.highEmphasis}
                    >
                        {formatStatus(params.value)}
                    </Typography>
                </Stack>
            );
        },
    },
    {
        field: 'updatedAt',
        headerName: 'Updated Date',
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
            const date = new Date(params.value);
            const formattedDate = date.toISOString().split('T')[0];

            return (
                <Stack
                    sx={{
                        justifyContent: 'center',
                        height: '100%',
                    }}
                >
                    <Typography
                        variant="body2"
                        color={theme.palette.text.highEmphasis}
                    >
                        {formattedDate}
                    </Typography>
                </Stack>
            );
        },
    },
];
