import { MenuItem, Stack, TextField } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import Typography from '../../atoms/Typography';
import Button from '../../atoms/Button';
import { updateVendorProperties } from '../../../services/api';
import useSnackBar from '../../../utils/hooks';
import CustomSnackbar from '../../molecules/CustomSnackbar';
import { VendorData } from '../../../utils/types';

const VendorStatusValues = [
    {
        id: 1,
        option: 'Planned',
        value: 'PLANNED',
    },
    {
        id: 2,
        option: 'In Progress',
        value: 'IN_PROGRESS',
    },
    {
        id: 3,
        option: 'On Hold',
        value: 'ON_HOLD',
    },
    {
        id: 4,
        option: 'Completed',
        value: 'COMPLETED',
    },
];

interface VendorPropertiesTabProps {
    vendorId: string;
    userRole: string;
    fetchData: () => void;
    vendorData: VendorData;
}

const VendorPropertiesTab = (props: VendorPropertiesTabProps) => {
    const [vendorProperties, setVendorProperties] = useState({
        vendorStatus: '',
        vendorRiskValue: '',
        vendorRiskScore: '',
    });
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const { openSnackBar, handleClick, handleClose } = useSnackBar();
    const [isDisabledByRole, setIsDisabledByRole] = useState(true);

    useEffect(() => {
        if (props.userRole === 'ADMIN') {
            setIsDisabledByRole(false);
        }
        setVendorProperties({
            vendorStatus: props.vendorData.vendorStatus || '',
            vendorRiskValue: props.vendorData.vendorRiskValue || '',
            vendorRiskScore: props.vendorData.vendorRiskScore || '',
        });
    }, [props.vendorData]);

    const handleChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setVendorProperties((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        const data = {
            id: props.vendorId,
            ...vendorProperties,
        };
        const response = await updateVendorProperties(data);
        if (response) {
            setMessage('Properties updated successfully');
            handleClick();
            setSuccess(true);
            props.fetchData();
        } else {
            setMessage('Failed to update properties. Please try again.');
            handleClick();
            setSuccess(false);
        }
    };

    return (
        <Stack sx={{ height: '100%', padding: 2 }}>
            <Stack spacing={'20px'} sx={{ padding: '15px 10px' }}>
                <TextField
                    size="small"
                    select
                    label="Status"
                    name="vendorStatus"
                    value={vendorProperties.vendorStatus}
                    onChange={handleChange}
                    disabled={isDisabledByRole}
                    fullWidth
                >
                    {VendorStatusValues.map((data) => (
                        <MenuItem key={data.id} value={data.value}>
                            <Typography
                                variant="body1"
                                sx={{ textTransform: 'capitalize' }}
                            >
                                {data.option}
                            </Typography>
                        </MenuItem>
                    ))}
                </TextField>
                {props.userRole === 'ADMIN' ? (
                    <Stack spacing={'20px'}>
                        <TextField
                            size="small"
                            label="Risk Value"
                            name="vendorRiskValue"
                            value={vendorProperties.vendorRiskValue}
                            onChange={handleChange}
                            disabled={isDisabledByRole}
                            fullWidth
                        />
                        <TextField
                            size="small"
                            label="Risk Score"
                            name="vendorRiskScore"
                            value={vendorProperties.vendorRiskScore}
                            onChange={handleChange}
                            disabled={isDisabledByRole}
                            fullWidth
                        />
                        <Button
                            variant="contained"
                            disableRipple
                            onClick={handleSubmit}
                        >
                            <Typography variant="caption1">{'Save'}</Typography>
                        </Button>
                    </Stack>
                ) : (
                    <></>
                )}
            </Stack>
            <CustomSnackbar
                open={openSnackBar}
                message={message}
                success={success}
                onClose={handleClose}
                autoHideDuration={1800}
            />
        </Stack>
    );
};

export default VendorPropertiesTab;
