import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import AssignmentIcon from '@mui/icons-material/Assignment';
import InventoryIcon from '@mui/icons-material/Inventory';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import PolicyIcon from '@mui/icons-material/Policy';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ClassIcon from '@mui/icons-material/Class';
import PhoneIcon from '@mui/icons-material/Phone';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import TuneIcon from '@mui/icons-material/Tune';
import HistoryIcon from '@mui/icons-material/History';
import { FilterField, ImpactCategory } from './types';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SettingsIcon from '@mui/icons-material/Settings';
import ConstructionIcon from '@mui/icons-material/Construction';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

export const AppMenuItems = [
    {
        name: 'Dashboard',
        link: '/u/dashboard',
        Icon: SpaceDashboardIcon,
    },
    {
        name: 'Risks',
        Icon: WhatshotIcon,
        SubIcon: MoreVertIcon,
        items: [
            {
                name: 'Vendors',
                link: '/u/vendors',
                Icon: InventoryIcon,
            },
            {
                name: 'Risk Register',
                link: '/u/riskregister',
                Icon: AppRegistrationIcon,
            },
        ],
    },
    {
        name: 'Documents',
        Icon: AssignmentIcon,
        SubIcon: MoreVertIcon,
        items: [
            {
                name: 'Policies',
                link: '/u/policies',
                Icon: PolicyIcon,
            },
            {
                name: 'Procedures',
                link: '/u/procedures',
                Icon: AccountTreeIcon,
            },
            {
                name: 'Mandatory Documents',
                link: '/u/mandatorydocuments',
                Icon: ClassIcon,
            },
        ],
    },
    {
        name: 'Forms',
        link: '/u/formPage',
        Icon: DynamicFormIcon,
    },
    {
        name: 'Settings',
        Icon: SettingsIcon,
        SubIcon: MoreVertIcon,
        items: [
            {
                name: 'Manage Users',
                link: '/u/manageUsers',
                Icon: PeopleAltIcon,
            },
            {
                name: 'Manage Templates',
                link: '/u/manageTemplate',
                Icon: ConstructionIcon,
            },
        ],
    },
];
export const UserProfileOption = [
    {
        id: 1,
        label: 'Profile',
    },
    {
        id: 2,
        label: 'Manage Users',
    },
    {
        id: 3,
        label: 'Logout',
    },
];
export const Filter = 'Filter';
export const Action = 'Action';
export const horizontalTabs = [
    {
        label: 'Horizontal Tab 1',
        icon: <PhoneIcon />,
        content: <div>Content for Horizontal Tab 1</div>,
    },
    {
        label: 'Horizontal Tab 2',
        icon: <FavoriteIcon />,
        content: <div>Content for Horizontal Tab 2</div>,
    },
    {
        label: 'Horizontal Tab 3',
        icon: <PersonPinIcon />,
        content: <div>Content for Horizontal Tab 3</div>,
    },
];

export const verticalTabs = [
    {
        label: 'Properties',
        icon: <TuneIcon />,
        content: <div>Content for Vertical Tab 1</div>,
    },
    {
        label: 'Activities',
        icon: <HistoryIcon />,
        content: <div>Content for Vertical Tab 2</div>,
    },
];

export const buttons = [
    { label: 'Button 1', onClick: () => console.log('Button 1 clicked') },
    { label: 'Button 2', onClick: () => console.log('Button 2 clicked') },
];

export const validateEmailFormat = (value: string): boolean => {
    const emailRegex =
        /^(?!.*\s)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(value);
};

export const steps = [
    { label: 'L1 Approval', level: 'l1' },
    { label: 'L2 Approval', level: 'l2' },
    { label: 'L3 Approval', level: 'l3' },
    { label: 'L4 Approval', level: 'l4' },
];

export const RequestChanges = 'Request Changes';
export const ApprovalsCompleted = 'All Approvals Completed';
export const Approve = 'Approve';
export const ResendApproval = 'Resend Approval';
export const RestartApproval = 'Restart Approval';
export const SendApproval = 'Send for Approval';
export const ImpactCategories: ImpactCategory[] = [
    { key: 'financial', label: 'Financial Cost / Loss' },
    { key: 'reputation', label: 'Reputation' },
    { key: 'legal', label: 'Legal and Regulatory' },
    { key: 'operational', label: 'Operational Impact' },
    { key: 'system', label: 'System Availability' },
];
export const Teams = [
    'IT Team',
    'Security Team',
    'Compliance Team',
    'Cloud Team',
    'Admin Team',
    'SOC Team',
    'Legal Team',
    'DB Team',
    'HR Team',
    'DevOps Team',
];
export const LoginHeading = 'CompliSys Login';
export const LoginButton = 'Login with SSO';
export const FilterFieldValues: FilterField[] = [
    {
        name: 'STATUS',
        values: [
            { value: 'PLANNED', label: 'Planned', active: false },
            { value: 'IN_PROGRESS', label: 'In Progress', active: false },
            { value: 'COMPLETED', label: 'Completed', active: false },
        ],
    },
];
export const RiskFilterFieldValues: FilterField[] = [
    {
        name: 'STATUS',
        values: [
            { value: 'PLANNED', label: 'Planned', active: false },
            { value: 'IN_PROGRESS', label: 'In Progress', active: false },
            { value: 'COMPLETED', label: 'Completed', active: false },
            { value: 'DELAYED', label: 'Delayed', active: false },
        ],
    },
];
export const VendorFilterFieldValues: FilterField[] = [
    {
        name: 'STATUS',
        values: [
            { value: 'PLANNED', label: 'Planned', active: false },
            { value: 'IN_PROGRESS', label: 'In Progress', active: false },
            { value: 'ON_HOLD', label: 'On Hold', active: false },
            { value: 'COMPLETED', label: 'Completed', active: false },
        ],
    },
];
