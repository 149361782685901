import { Outlet, Navigate } from 'react-router-dom';
import { useVendorAuth } from './context/vendorAuthContext';

const VendorPrivateRoutes: React.FC = () => {
    const { isVendorAuthenticated } = useVendorAuth();

    return isVendorAuthenticated ? <Outlet /> : <Navigate to="/vendorlogin" />;
};

export default VendorPrivateRoutes;
