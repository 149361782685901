import React, { useState } from 'react';
import {
    Box,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    LinearProgress,
    Stack,
    Alert,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '../../atoms/Button';
import Typography from '../../atoms/Typography';
import theme from '../../../theme';
import { uploadFiles } from '../../../services/api';
import CustomSnackbar from '../../molecules/CustomSnackbar';
import useSnackBar from '../../../utils/hooks';

interface FileItem {
    file: File;
    name: string;
    size: number;
    progress: number;
    uploading: boolean;
    valid: boolean;
    error: string | null;
    success: boolean;
}

interface FileUploadProps {
    uploadFrom: string;
    id: string;
    onUploadComplete: (response: any) => void;
    currentFileCount?: number;
}

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB
const ALLOWED_FILE_TYPES = [
    'image/png',
    'image/jpeg',
    'application/pdf',
    'text/plain',
    'text/csv',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const FileUpload: React.FC<FileUploadProps> = ({
    uploadFrom,
    id,
    onUploadComplete,
    currentFileCount = 0,
}) => {
    const [files, setFiles] = useState<FileItem[]>([]);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [successFiles, setSuccessFiles] = useState<string[]>([]);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const { openSnackBar, handleClick, handleClose } = useSnackBar();

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const selectedFiles = Array.from(event.target.files);
            const totalSelectedSize = selectedFiles.reduce(
                (acc, file) => acc + file.size,
                0
            );

            if (
                selectedFiles.length + currentFileCount > 15 ||
                selectedFiles.length > 10 ||
                totalSelectedSize > 20 * 1024 * 1024
            ) {
                setMessage(
                    `Upload restrictions:\n- Maximum 15 files in total (including existing files).\n- Maximum 10 files per upload.\n- Total size must not exceed 20 MB.`
                );
                handleClick();
                setSuccess(false);
                return;
            }

            const validFiles = selectedFiles.map((file) => ({
                file,
                name: file.name,
                size: file.size,
                progress: 0,
                uploading: false,
                valid:
                    ALLOWED_FILE_TYPES.includes(file.type) &&
                    file.size <= MAX_FILE_SIZE,
                error: null,
                success: false,
            }));

            setFiles(validFiles.filter((file) => file.valid)); // Filter out invalid files
        }
    };

    const handleRemoveFile = (index: number) => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    const handleUpload = async () => {
        setIsUploading(true);
        const formData = new FormData();
        files.forEach((fileItem) => {
            formData.append('files', fileItem.file);
        });
        formData.append('uploadFrom', uploadFrom);
        formData.append('id', id);

        try {
            const response = await uploadFiles({
                data: formData,
                onProgress: (percentCompleted) => {
                    setFiles((prevFiles) =>
                        prevFiles.map((file) => ({
                            ...file,
                            progress: percentCompleted,
                            uploading: true,
                        }))
                    );
                },
            });

            // Mark files as uploaded and track success
            setFiles((prevFiles) =>
                prevFiles.map((file) => ({
                    ...file,
                    progress: 100,
                    uploading: false,
                    success: true,
                }))
            );
            setSuccessFiles((prev) => [
                ...prev,
                ...files.map((file) => file.name),
            ]); // Add names to success list

            onUploadComplete(response.data);
            // Remove successfully uploaded files from the list
            setFiles([]);
        } catch (error) {
            setFiles((prevFiles) =>
                prevFiles.map((file) => ({
                    ...file,
                    uploading: false,
                    error: 'Upload failed',
                }))
            );
        } finally {
            setIsUploading(false);
        }
    };

    const allValid = files.every((file) => file.valid);

    return (
        <Box>
            <input
                type="file"
                multiple
                onChange={handleFileSelect}
                style={{ display: 'none' }}
                id="file-upload-input"
            />
            <Stack>
                <Stack
                    direction={'row'}
                    spacing={'1rem'}
                    sx={{ marginBottom: '1rem' }}
                >
                    <label htmlFor="file-upload-input">
                        <Button
                            variant="contained"
                            component="span"
                            disabled={isUploading}
                        >
                            {'Select Files'}
                        </Button>
                    </label>
                    <Button
                        variant="contained"
                        onClick={handleUpload}
                        disabled={
                            files.length === 0 || isUploading || !allValid
                        }
                    >
                        {'Upload'}
                    </Button>
                </Stack>
                <Typography variant="caption1">
                    {'Allowed extensions : ' +
                        ' .png, .jpeg, .jpg, .pdf, .txt, .csv, .xlsx'}
                </Typography>
                <Typography variant="caption1">
                    {'Maximum File Size: 10MB, Maximum Number of Files: 10'}
                </Typography>
                <Typography variant="caption1">
                    {'Total Upload Size should not exceed: 20MB'}
                </Typography>
                {successFiles.length > 0 && (
                    <Box mt={2}>
                        <Alert severity="success">
                            Successfully uploaded: {successFiles.join(', ')}
                        </Alert>
                    </Box>
                )}
            </Stack>
            {files.length !== 0 && (
                <TableContainer
                    sx={{
                        height: '80vh',
                        border: `1px solid ${theme.palette.structuralColors.lightGray}`,
                        borderRadius: '5px',
                        mt: 2,
                    }}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>File Name</TableCell>
                                <TableCell>Size (MB)</TableCell>
                                <TableCell>Upload Progress</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {files.map((file, index) => (
                                <TableRow key={index}>
                                    <TableCell>{file.name}</TableCell>
                                    <TableCell>
                                        {(file.size / (1024 * 1024)).toFixed(3)}{' '}
                                        MB
                                    </TableCell>
                                    <TableCell>
                                        <Box display="flex" alignItems="center">
                                            <Box width="100%" mr={1}>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={file.progress}
                                                />
                                            </Box>
                                            <Box minWidth={35}>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                >{`${Math.round(file.progress)}%`}</Typography>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        {file.error ? (
                                            <Typography
                                                variant="body1"
                                                color="error"
                                            >
                                                {file.error}
                                            </Typography>
                                        ) : file.uploading ? (
                                            'Uploading...'
                                        ) : file.progress === 100 ? (
                                            'Uploaded'
                                        ) : (
                                            'Pending'
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={() =>
                                                handleRemoveFile(index)
                                            }
                                            disabled={
                                                isUploading || file.uploading
                                            }
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <CustomSnackbar
                open={openSnackBar}
                message={message}
                success={success}
                onClose={handleClose}
                autoHideDuration={3000}
            />
        </Box>
    );
};

export default FileUpload;
