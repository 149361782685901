import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from './context/authContext';

const PrivateRoutes: React.FC = () => {
    const { isAuthenticated } = useAuth();

    return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutes;
