import { ListItemButton } from '@mui/material';
import React, { forwardRef } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

export interface AppMenuItemComponentProps {
    className?: string;
    link?: string | null;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    children?: React.ReactNode;
}

const AppMenuItemComponent: React.FC<AppMenuItemComponentProps> = (props) => {
    const { className, onClick, link, children } = props;

    if (!link || typeof link !== 'string') {
        return (
            <ListItemButton className={className} onClick={onClick}>
                {children}
            </ListItemButton>
        );
    }

    const CustomNavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
        (props, ref) => <NavLink {...props} ref={ref} />
    );
    CustomNavLink.displayName = 'CustomNavLink';

    return (
        <ListItemButton
            className={className}
            component={CustomNavLink}
            to={link}
        >
            {children}
        </ListItemButton>
    );
};

export default AppMenuItemComponent;
