import { Box, Stack } from '@mui/material';
import Sidebar from '../../organisms/AppNavigation';
import AppHeader from '../../organisms/AppHeader';
import { useState } from 'react';

interface LandingTemplateProps {
    main: React.ReactNode;
}

const MainTemplate: React.FC<LandingTemplateProps> = ({ main }) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const handleSidebarToggle = (isOpen: boolean) => {
        setSidebarOpen(isOpen);
    };

    return (
        <Stack
            direction="row"
            sx={{
                width: '100%',
                height: '100%',
                overflow: 'hidden',
            }}
            data-testid="gridItems"
        >
            <Sidebar open={sidebarOpen} onToggle={handleSidebarToggle} />
            <Stack
                component="main"
                sx={{
                    flexGrow: 1,
                    width: `calc(100% - ${sidebarOpen ? '250px' : '60px'})`,
                    minHeight: '100vh',
                    transition: (theme) =>
                        theme.transitions.create('width', {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.leavingScreen,
                        }),
                    overflow: 'auto',
                }}
            >
                <Stack
                    sx={{
                        zIndex: 50,
                        width: '100%',
                        position: 'sticky',
                        top: 0,
                    }}
                >
                    <AppHeader />
                </Stack>
                <Box sx={{ flex: 1 }}>{main}</Box>
            </Stack>
        </Stack>
    );
};

export default MainTemplate;
