import { styled } from '@mui/material';
import {
    DataGrid,
    GridColumnVisibilityModel,
    GridSlotsComponent,
    GridSlotsComponentsProps,
    GridValidRowModel,
    DataGridProps,
} from '@mui/x-data-grid';
import theme from '../../../theme';

interface DataTableProps {
    rowsData: GridValidRowModel[];
    isLoading: boolean;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: (
        newModel: GridColumnVisibilityModel
    ) => void;
    sx?: object;
    slots?: Partial<GridSlotsComponent>;
    slotProps?: Partial<GridSlotsComponentsProps>;
}

const StyledDataGrid = styled(DataGrid)({
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: theme.palette.primary[300],
        color: theme.palette.text.mediumEmphasis,
    },
});

const ClientSideDataTable = (props: DataTableProps & DataGridProps) => {
    const {
        rowsData,
        isLoading,
        columnVisibilityModel,
        onColumnVisibilityModelChange,
        sx,
        slots,
        slotProps,
    } = props;

    return (
        <StyledDataGrid
            rows={rowsData}
            loading={isLoading}
            disableRowSelectionOnClick
            disableColumnResize
            autoHeight
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={onColumnVisibilityModelChange}
            sx={{
                flex: 1,
                ...sx,
            }}
            // getRowHeight={() => 'auto'}
            slots={slots}
            slotProps={slotProps}
            {...props}
        />
    );
};

export default ClientSideDataTable;
