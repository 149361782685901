import React, { useState } from 'react';
import {
    Stack,
    Popover,
    Menu,
    MenuItem,
    IconButton,
    Modal,
    styled,
} from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import Typography from '../../components/atoms/Typography';
import Button from '../../components/atoms/Button';
import theme from '../../theme';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import EditIcon from '@mui/icons-material/Edit';

interface DocumentUploadProps {
    ansId: string;
    documents: Document[];
    onUploadToggle: (isOpen: boolean) => void;
    handleFileDownload: (fileId: string, fileName: string) => void;
    handleFileDelete: (ansId: string, fileId: string) => void;
}

export const DocumentUpload: React.FC<DocumentUploadProps> = ({
    ansId,
    documents,
    onUploadToggle,
    handleFileDownload,
    handleFileDelete,
}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [anchorMenuEl, setAnchorMenuEl] = useState<HTMLButtonElement | null>(
        null
    );
    const [selectedFileId, setSelectedFileId] = useState<string | null>(null);
    const [selectedFileName, setSelectedFileName] = useState<string | null>(
        null
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuClick = (
        event: React.MouseEvent<HTMLButtonElement>,
        fileId: string,
        fileName: string
    ) => {
        setAnchorMenuEl(event.currentTarget);
        setSelectedFileId(fileId); // Store the fileId of the clicked document
        setSelectedFileName(fileName);
    };

    const handleMenuClose = () => {
        setAnchorMenuEl(null);
    };

    const open = Boolean(anchorEl);
    const openMenu = Boolean(anchorMenuEl);

    return (
        <Stack sx={{ paddingLeft: '4px' }}>
            <Stack
                sx={{
                    marginTop: '3px',
                    alignItems: 'center',
                }}
                direction={'row'}
                spacing={'10px'}
            >
                <Typography variant="caption1">{'Documents: '}</Typography>
                <Button
                    variant="text"
                    startIcon={<FileUploadIcon />}
                    onClick={() => onUploadToggle(true)}
                >
                    Upload File
                </Button>
            </Stack>
            <Stack>
                <Stack direction={'row'}>
                    <Typography
                        variant="caption1"
                        onClick={handleClick}
                        sx={{
                            height: '36px',
                            width: '100px',
                            cursor: 'pointer',
                        }}
                        color="blue"
                    >
                        <u>{'Documents: ' + documents.length || 0}</u>
                    </Typography>
                    <Typography variant="caption2">
                        <strong>{'(Maximum documents: 15)'}</strong>
                    </Typography>
                </Stack>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                >
                    <Stack
                        sx={{
                            width: '20vw',
                            padding: '10px',
                        }}
                        spacing={'5px'}
                    >
                        {documents.map((data) => (
                            <Stack
                                key={data.fileId}
                                direction={'row'}
                                sx={{
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Stack direction={'row'}>
                                    <InsertDriveFileOutlinedIcon
                                        style={{
                                            color: 'blue',
                                            height: '17px',
                                            width: '15px',
                                        }}
                                    />
                                    <Typography variant="caption2" color="blue">
                                        {data.fileName}
                                    </Typography>
                                </Stack>
                                <IconButton
                                    onClick={(event) =>
                                        handleMenuClick(
                                            event,
                                            data.fileId,
                                            data.fileName
                                        )
                                    }
                                    sx={{
                                        height: '18px',
                                        width: '17px',
                                    }}
                                >
                                    <MoreVertOutlinedIcon
                                        style={{
                                            color: 'blue',
                                            height: '17px',
                                            width: '15px',
                                        }}
                                    />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorMenuEl}
                                    open={openMenu}
                                    onClose={handleMenuClose}
                                    sx={{
                                        '& .MuiPaper-root': {
                                            boxShadow:
                                                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
                                        },
                                    }}
                                >
                                    <MenuItem
                                        onClick={() => {
                                            if (
                                                selectedFileId &&
                                                selectedFileName
                                            ) {
                                                handleFileDownload(
                                                    selectedFileId,
                                                    selectedFileName
                                                );
                                                handleMenuClose();
                                            }
                                        }}
                                        sx={{ padding: '0 5px' }}
                                    >
                                        <Typography variant="caption1">
                                            {'Download'}
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            if (selectedFileId) {
                                                handleFileDelete(
                                                    ansId,
                                                    selectedFileId
                                                );
                                                handleMenuClose();
                                            }
                                        }}
                                        sx={{ padding: '0 5px' }}
                                    >
                                        <Typography variant="caption1">
                                            {'Delete'}
                                        </Typography>
                                    </MenuItem>
                                </Menu>
                            </Stack>
                        ))}
                    </Stack>
                </Popover>
            </Stack>
        </Stack>
    );
};

interface AnswerEditorModalProps {
    answer: any;
    displayedText: string;
    modelOpenState: boolean;
    answerValue: string;
    handleModalOpening: () => void;
    handleModalClosing: () => void;
    handleAnswerChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    handleAnswerSend: () => void;
    editText: string;
    addText: string;
    isInternalNote: boolean;
}

const StyledButton = styled(Button)({
    padding: '1px 3px',
});

export const AnswerEditorModal = ({
    answer,
    displayedText,
    modelOpenState,
    answerValue,
    handleModalOpening,
    handleModalClosing,
    handleAnswerChange,
    handleAnswerSend,
    editText,
    addText,
    isInternalNote = false,
}: AnswerEditorModalProps) => {
    const truncatedText = (text: string, wordLimit: number) => {
        if (text !== null) {
            const words = text.length;
            return words > wordLimit ? text.slice(0, wordLimit) + '...' : text;
        } else {
            return text;
        }
    };

    return (
        <Stack
            sx={{
                width: '100%',
                height: '100%',
            }}
        >
            <Stack spacing={'5px'}>
                <StyledButton
                    startIcon={
                        answer.answered || isInternalNote ? (
                            <EditIcon />
                        ) : (
                            <AddIcon />
                        )
                    }
                    onClick={handleModalOpening}
                    variant="outlined"
                    sx={{
                        height: '30px',
                        width: '120px',
                    }}
                >
                    <Typography variant="caption2">
                        {answer.answered ? editText : addText}
                    </Typography>
                </StyledButton>
                {answer.answered || isInternalNote ? (
                    <Typography variant="caption2">
                        <strong>{'Answer: '}</strong>
                        <span
                            style={{
                                color: theme.palette.text.mediumEmphasis,
                            }}
                        >
                            {truncatedText(displayedText, 50)}
                        </span>
                    </Typography>
                ) : (
                    ''
                )}
                <Modal open={modelOpenState} onClose={handleModalClosing}>
                    <Stack
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '38vw',
                            bgcolor: 'background.paper',
                            border: '1px solid #D4D4D4',
                            boxShadow: 24,
                            padding: '15px',
                            borderRadius: '7px',
                        }}
                        spacing={'10px'}
                    >
                        <Typography variant="subtitle1">
                            {answer.answered ? editText : addText}
                        </Typography>
                        <textarea
                            value={answerValue}
                            onChange={handleAnswerChange}
                            style={{
                                width: '98.3%',
                                height: '175px',
                                padding: '5px',
                                fontFamily: 'Mier-Book',
                                fontSize: '16px',
                                border: `1px solid ${theme.palette.structuralColors.stroke1}`,
                            }}
                        />
                        <Stack
                            direction={'row'}
                            sx={{
                                justifyContent: 'flex-end',
                            }}
                            spacing={'10px'}
                        >
                            <Button
                                variant="contained"
                                onClick={handleAnswerSend}
                                endIcon={<SendIcon />}
                            >
                                <Typography variant="caption1">
                                    {'Save'}
                                </Typography>
                            </Button>
                            <StyledButton
                                variant="outlined"
                                onClick={handleModalClosing}
                            >
                                <Typography variant="caption1">
                                    {'Close'}
                                </Typography>
                            </StyledButton>
                        </Stack>
                    </Stack>
                </Modal>
            </Stack>
        </Stack>
    );
};
