import {
    Stack,
    Typography,
    IconButton,
    Chip,
    TextField,
    Button,
    Checkbox,
    FormControlLabel,
    Radio,
} from '@mui/material';
import theme from '../../theme';
import React, { useCallback, useState } from 'react';
import { QuestionDTO } from '.';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { isEqual } from 'lodash';
import ConfirmationModal from '../../components/molecules/ConfirmationModal';

interface RenderQuestionProps {
    question: any;
    handleEditQuestion: (id: string) => void;
    handleDeleteQuestion: (id: string) => void;
}

const RenderQuestion: React.FC<RenderQuestionProps> = ({
    question,
    handleEditQuestion,
    handleDeleteQuestion,
}) => {
    const [confirmationOpen, setConfirmationOpen] = useState(false);

    const handleConfirmationModel = () => {
        setConfirmationOpen(!confirmationOpen);
    };

    const renderQuestionOptions = useCallback((question: QuestionDTO) => {
        switch (question.answerType) {
            case 'TEXT':
                return (
                    <TextField
                        size="small"
                        fullWidth
                        disabled
                        placeholder="Text answer"
                    />
                );
            case 'RADIO':
                return question.options?.map((option, index) => (
                    <FormControlLabel
                        key={index}
                        control={<Radio disabled />}
                        label={
                            <Typography variant="caption1">
                                {`${option.text} (Value: ${option.value}, Answer Value: ${option.answerValue})`}
                            </Typography>
                        }
                    />
                ));
            case 'CHECKBOX':
                return question.options?.map((option, index) => (
                    <FormControlLabel
                        key={index}
                        control={<Checkbox disabled />}
                        label={
                            <Typography variant="caption1">
                                {`${option.text} (Value: ${option.value}, Answer Value: ${option.answerValue})`}
                            </Typography>
                        }
                    />
                ));
            case 'FILE_UPLOAD':
                return <Button disabled>Upload File</Button>;
            case 'COMPOSITE':
                return (
                    <>
                        {question.options?.map((option, index) => (
                            <FormControlLabel
                                key={index}
                                control={<Radio disabled />}
                                label={
                                    <Typography variant="caption1">
                                        {`${option.text} (Value: ${option.value}, Answer Value: ${option.answerValue})`}
                                    </Typography>
                                }
                            />
                        ))}
                        {question.noteAllowed && (
                            <TextField
                                size="small"
                                fullWidth
                                disabled
                                placeholder="Add note"
                            />
                        )}
                        {question.fileUploadAllowed && (
                            <Button disabled>Upload File</Button>
                        )}
                    </>
                );
            default:
                return null;
        }
    }, []);

    return (
        <Stack
            key={question.id}
            sx={{
                border: '1px solid #DBDCE0',
                borderRadius: '10px',
                backgroundColor: 'white',
                padding: '20px',
                gap: '10px',
            }}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography variant="caption1">
                    <span
                        style={{
                            color: theme.palette.text.mediumEmphasis,
                        }}
                    >
                        {'Question : '}
                    </span>
                    {question.text}
                </Typography>
                <Stack direction="row">
                    <IconButton
                        onClick={() => handleEditQuestion(question.id!)}
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleConfirmationModel()}>
                        <DeleteIcon />
                    </IconButton>
                </Stack>
            </Stack>
            <Stack
                sx={{
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                }}
                direction={'row'}
            >
                <Typography variant="caption1">
                    <span
                        style={{
                            color: theme.palette.text.mediumEmphasis,
                        }}
                    >
                        {'Evidence Requirement : '}
                    </span>
                    {question.evidenceRequirement}
                </Typography>
                {question.domain && (
                    <Chip
                        label={
                            <Typography variant="caption2">
                                {question.domain}
                            </Typography>
                        }
                    />
                )}
            </Stack>
            <Typography variant="caption1">
                Answer Type: {question.answerType}
            </Typography>
            {renderQuestionOptions(question)}
            <ConfirmationModal
                open={confirmationOpen}
                onClose={handleConfirmationModel}
                onConfirm={() => handleDeleteQuestion(question.id!)}
                onCancel={handleConfirmationModel}
                title={`Are you sure you want to delete the question? This change will be reflected in all the ongoing and previous forms that have been sent to users.\n\nIf you do not want that, please consider creating a new form.`}
                confirmText={'Confirm'}
                cancelText={'Cancel'}
            />
        </Stack>
    );
};

export default React.memo(RenderQuestion, (prevProps, nextProps) => {
    return isEqual(prevProps.question, nextProps.question);
});
