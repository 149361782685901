import { Stack } from '@mui/material';
import DocumentHeader from '../../components/molecules/DocumentHeader';
import DocumentDetail from '../../components/organisms/DocumentDetail';
import MainTemplate from '../../components/templates/MainTemplate';
import RiskEvidence from '../../components/organisms/RiskEvidence';
import RiskEvaluation from '../../components/organisms/RiskEvaluation';
import RiskTreatment from '../../components/organisms/RiskTreatment';
import RiskMonitoring from '../../components/organisms/RiskMonitoring';
import { useEffect, useState } from 'react';
import { getAllUsers, getRiskRegister } from '../../services/api';
import RiskIdentificationComponent from '../../components/organisms/RiskIdentification';
import { useLocation, useNavigate } from 'react-router-dom';
import { DecodedJWT, UserData } from '../../utils/types';
import HistoryIcon from '@mui/icons-material/History';
import ChatIcon from '@mui/icons-material/Chat';
import RemarkViewer from '../../components/organisms/RemarkViewer';
import ActivityList from '../../components/organisms/ActivityList';
import { DocValues } from '../../components/organisms/DocumentTab';
import RiskAnalysisComponent from '../../components/organisms/RiskAnalysisTab';
import { jwtDecode } from 'jwt-decode';

const RiskRegistersDetailsPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const [userData, setUserData] = useState<UserData[]>([]);
    const [verticalTab, setVerticalTab] = useState(0);
    const [documentId, setDocumentId] = useState('');
    const [documentType, setDocumentType] = useState('');
    const [riskData, setRiskData] = useState();

    const handleVerticalTabChange = (newValue: number) => {
        setVerticalTab(newValue);
    };

    const handleDocValueChange = (docValues: DocValues) => {
        setDocumentId(docValues.docId);
        setDocumentType(docValues.type);
    };

    const fetchUserData = async () => {
        const response: UserData[] = await getAllUsers();
        if (response) {
            setUserData(response);
        } else {
            console.log('error');
        }
    };

    const fetchData = async () => {
        const response = await getRiskRegister(id);
        if (response) {
            setRiskData(response.riskRegister);
        } else {
            console.log('error');
        }
    };

    useEffect(() => {
        fetchData();
        fetchUserData();
    }, []);

    let token;
    if (localStorage.getItem('token')) {
        token = localStorage.getItem('token');
    }
    const { role } = jwtDecode<DecodedJWT>(token);

    const horizontalTabs = [
        {
            label: 'Risk Evidence',
            content: (
                <Stack
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    {id && (
                        <RiskEvidence
                            setRemarkTab={() => handleVerticalTabChange(0)}
                            onDocumentIdChange={(docValues) =>
                                handleDocValueChange(docValues)
                            }
                            riskId={id}
                            userRole={role}
                        />
                    )}
                </Stack>
            ),
        },
        {
            label: 'Risk Identification',
            content: (
                <Stack
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    {id && (
                        <RiskIdentificationComponent
                            riskId={id}
                            riskData={riskData}
                            fetchData={fetchData}
                        />
                    )}
                </Stack>
            ),
        },
        {
            label: 'Risk Analysis',
            content: (
                <Stack
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    {id && <RiskAnalysisComponent riskRegisterId={id} />}
                </Stack>
            ),
        },
        {
            label: 'Risk Evaluation',
            content: (
                <Stack
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    {id && (
                        <RiskEvaluation
                            userProfileData={userData}
                            riskId={id}
                            riskData={riskData}
                            fetchData={fetchData}
                        />
                    )}
                </Stack>
            ),
        },
        {
            label: 'Risk Treatment',
            content: (
                <Stack
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    {id && (
                        <RiskTreatment
                            userProfileData={userData}
                            riskId={id}
                            riskData={riskData}
                            fetchData={fetchData}
                        />
                    )}
                </Stack>
            ),
        },
        {
            label: 'Risk Monitoring',
            content: (
                <Stack
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    {id && (
                        <RiskMonitoring
                            userProfileData={userData}
                            riskId={id}
                            riskData={riskData}
                            fetchData={fetchData}
                        />
                    )}
                </Stack>
            ),
        },
    ];

    const verticalTabs = [
        {
            label: 'Remarks',
            icon: <ChatIcon />,
            content: <RemarkViewer docId={documentId} docType={documentType} />,
        },
        {
            label: 'Activities',
            icon: <HistoryIcon />,
            content: <ActivityList id={id} activityType={'Risk Register'} />,
        },
    ];

    return (
        <MainTemplate
            main={
                <Stack sx={{ width: '100%', height: '100%' }}>
                    {riskData && id && (
                        <DocumentHeader
                            backButtonClick={() => navigate('/u/riskregister')}
                            labelText={riskData.name}
                            id={id}
                            fetchData={fetchData}
                            userRole={role}
                            type={'RISKREGISTER'}
                        />
                    )}
                    <DocumentDetail
                        horizontalTabs={horizontalTabs}
                        verticalTabs={verticalTabs}
                        verticalTabValue={verticalTab}
                        onVerticalTabChange={handleVerticalTabChange}
                        hiddenHorizontalTabs={[]}
                        hiddenVerticalTabs={[]}
                    />
                </Stack>
            }
        />
    );
};

export default RiskRegistersDetailsPage;
