import { styled } from '@mui/material';
import {
    DataGrid,
    GridColDef,
    GridColumnVisibilityModel,
    GridValidRowModel,
} from '@mui/x-data-grid';
import theme from '../../../theme';

interface DataTableProps {
    columns: GridColDef[];
    rowsData: GridValidRowModel[];
    rowCount: number;
    paginationModel: { page: number; pageSize: number };
    onPaginationModelChange: (newModel: {
        page: number;
        pageSize: number;
    }) => void;
    isLoading: boolean;
    checkboxSelection: boolean;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: (
        newModel: GridColumnVisibilityModel
    ) => void;
    sx?: object;
    resizeThrottleMs?: number;
    slotProps?: any;
}

const StyledDataGrid = styled(DataGrid)({
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: theme.palette.primary[300],
        color: theme.palette.text.mediumEmphasis,
    },
});

const DataTable = (props: DataTableProps) => {
    const {
        rowsData,
        columns,
        rowCount,
        paginationModel,
        onPaginationModelChange,
        isLoading,
        checkboxSelection,
        columnVisibilityModel,
        onColumnVisibilityModelChange,
        sx,
        resizeThrottleMs,
        slotProps,
    } = props;

    return (
        <StyledDataGrid
            rows={rowsData}
            rowCount={rowCount}
            loading={isLoading}
            pageSizeOptions={[5, 10, 25, 50, 75, 100]}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={(newModel) => {
                onPaginationModelChange({
                    page: newModel.page,
                    pageSize: newModel.pageSize,
                });
            }}
            columns={columns}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnSorting
            disableColumnResize
            checkboxSelection={checkboxSelection}
            autoHeight
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={onColumnVisibilityModelChange}
            sx={{
                '.MuiDataGrid-cell': {
                    padding: '8px 10px',
                },
                flex: 1,
                minHeight: 0,
                ...sx,
            }}
            resizeThrottleMs={resizeThrottleMs}
            getRowHeight={() => 'auto'}
            slotProps={slotProps}
        />
    );
};

export default DataTable;
