import React, { useState } from 'react';
import { FormControlLabel, Menu, Stack, styled } from '@mui/material';
import theme from '../../../theme';
import CheckBox from '../../atoms/Checkbox';
import Typography from '../../atoms/Typography';
import Button from '../../atoms/Button';
import TuneIcon from '@mui/icons-material/Tune';
import { HandleCheckboxChange } from './utils';
import { FilterField } from '../../../utils/types';

export const InnerStyledStack = styled(Stack)({
    padding: '0 0 0 10px',
});

interface FilterPopupProps {
    filterFields: FilterField[];
    onFilterChange: (statusList: string[], severityList: string[]) => void;
}

const FilterPopup = (props: FilterPopupProps) => {
    const [filterFields, setFilterFields] = useState<FilterField[]>(
        props.filterFields
    );
    const [statusList, setStatusList] = useState<string[]>([]);
    const [severityList, setSeverityList] = useState<string[]>([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCheckboxChange = (
        fieldName: string,
        value: string,
        isActive: boolean
    ) => {
        let updatedStatusList = [...statusList];
        let updatedSeverityList = [...severityList];

        if (fieldName === 'STATUS') {
            if (isActive) {
                updatedStatusList = updatedStatusList.filter(
                    (status) => status !== value
                );
            } else {
                updatedStatusList.push(value);
            }
        }

        if (fieldName === 'SEVERITY') {
            if (isActive) {
                updatedSeverityList = updatedSeverityList.filter(
                    (severity) => severity !== value
                );
            } else {
                updatedSeverityList.push(value);
            }
        }

        setStatusList(updatedStatusList);
        setSeverityList(updatedSeverityList);
        props.onFilterChange(updatedStatusList, updatedSeverityList);
    };

    return (
        <>
            <Button
                onClick={handleClick}
                variant="outlined"
                startIcon={<TuneIcon />}
                sx={{ height: '4vh' }}
            >
                <Typography variant="body2" color={theme.palette.primary[500]}>
                    {'Filter'}
                </Typography>
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {filterFields.map((field) => (
                    <InnerStyledStack key={field.name} direction={'column'}>
                        <Typography
                            variant="caption1"
                            color={theme.palette.text.lowEmphasis}
                        >
                            {field.name}
                        </Typography>
                        {field.values.map((item) => (
                            <FormControlLabel
                                key={item.value}
                                control={
                                    <CheckBox
                                        checked={item.active}
                                        onChange={() => {
                                            HandleCheckboxChange(
                                                field.name,
                                                item.value,
                                                filterFields,
                                                setFilterFields
                                            );
                                            handleCheckboxChange(
                                                field.name,
                                                item.value,
                                                item.active
                                            );
                                        }}
                                        value={item.value}
                                        data-testid={item.value}
                                    />
                                }
                                label={
                                    <Typography
                                        variant={'caption2'}
                                        color={theme.palette.text.highEmphasis}
                                    >
                                        {item.label}
                                    </Typography>
                                }
                            />
                        ))}
                    </InnerStyledStack>
                ))}
            </Menu>
        </>
    );
};

export default FilterPopup;
