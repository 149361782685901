import { Autocomplete, Divider, Stack, styled, TextField } from '@mui/material';
import MainTemplate from '../../components/templates/MainTemplate';
import Typography from '../../components/atoms/Typography';
import InputField from '../../components/atoms/InputField';
import { useEffect, useState } from 'react';
import {
    emailTemplateAction,
    getEmailTemplates,
    saveTemplateMapping,
} from '../../services/api';
import Button from '../../components/atoms/Button';
import theme from '../../theme';
import CustomSnackbar from '../../components/molecules/CustomSnackbar';
import useSnackBar from '../../utils/hooks';

const StyledButton = styled(Button)({
    padding: '5px 8px',
    width: '160px',
});

interface EmailTemplate {
    id: string;
    templateName: string;
    subject: string;
    emailBody: string;
    contactName: string;
    contactEmail: string;
}

const templateTypes = [
    'APPROVAL_NOTIFICATION',
    'APPROVAL_REMINDER',
    'APPROVAL_RESEND',
    'VENDOR_NOTIFICATION',
    'VENDOR_REMINDER',
    'VENDOR_LOGIN_NOTIFICATION',
    'VENDOR_REVIEW_REMINDER',
    'VENDOR_FORM_SUBMISSION',
    'VENDOR_FORM_COMPLETION',
    'RISK_EVIDENCE_NOTIFICATION',
    'RISK_REMINDER',
];

const TemplateManagement = () => {
    const [emailTemplates, setEmailTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] =
        useState<EmailTemplate | null>(null);
    const [selectedMappingTemplates, setSelectedMappingTemplates] = useState<{
        [key: string]: EmailTemplate | null;
    }>({
        APPROVAL_NOTIFICATION: null,
        APPROVAL_REMINDER: null,
        APPROVAL_RESEND: null,
        VENDOR_NOTIFICATION: null,
        VENDOR_REMINDER: null,
        VENDOR_LOGIN_NOTIFICATION: null,
        VENDOR_REVIEW_REMINDER: null,
        VENDOR_FORM_SUBMISSION: null,
        VENDOR_FORM_COMPLETION: null,
        RISK_EVIDENCE_NOTIFICATION: null,
        RISK_REMINDER: null,
    });
    const [templateName, setTemplateName] = useState<string>('');
    const [emailData, setEmailData] = useState({
        emailSubject: '',
        emailBody: '',
        attachmentLink: '',
        contactName: '',
        contactEmail: '',
    });
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const { openSnackBar, handleClick, handleClose } = useSnackBar();

    const fetchEmailTemplates = async () => {
        const response = await getEmailTemplates();
        if (response) {
            setEmailTemplates(response.emailTemplates);
            const initialSelectedTemplates = templateTypes.reduce(
                (
                    acc: { [key: string]: EmailTemplate | null },
                    type: string
                ) => {
                    const mapping = response.templateMappings.find(
                        (mapping: any) => mapping.type === type
                    );
                    if (mapping) {
                        acc[type] = mapping.emailTemplate;
                    } else {
                        acc[type] = null;
                    }
                    return acc;
                },
                {}
            );
            setSelectedMappingTemplates(initialSelectedTemplates);
        } else {
            setMessage('Failed to get data. An error occurred.');
            handleClick();
            setSuccess(false);
        }
    };

    useEffect(() => {
        fetchEmailTemplates();
    }, []);

    const handleTemplateChange = (
        event: any,
        newValue: EmailTemplate | null
    ) => {
        setSelectedTemplate(newValue);
        if (newValue) {
            setEmailData({
                ...emailData,
                emailSubject: newValue.subject,
                emailBody: newValue.emailBody,
                contactName: newValue.contactName,
                contactEmail: newValue.contactEmail,
            });
        } else {
            setEmailData({
                ...emailData,
                emailSubject: '',
                emailBody: '',
                contactName: '',
                contactEmail: '',
            });
        }
    };

    const handleMappingTemplateChange =
        (type: string) => (event: any, newValue: EmailTemplate | null) => {
            setSelectedMappingTemplates((prev) => ({
                ...prev,
                [type]: newValue,
            }));
        };

    const handleSaveMappings = async () => {
        const mappings = Object.keys(selectedMappingTemplates).map((key) => ({
            type: key,
            emailTemplateId: selectedMappingTemplates[key]?.id || null,
        }));

        const response = await saveTemplateMapping(mappings);
        if (response) {
            setMessage('Mappings saved successfully.');
            handleClick();
            setSuccess(true);
        } else {
            setMessage('An error occurred while saving mapping.');
            handleClick();
            setSuccess(false);
        }
    };

    const disableMapppingSave = Object.values(selectedMappingTemplates).every(
        (template) => template === null
    );

    const handleSaveEmailTemplate = async () => {
        if (selectedTemplate) {
            // If template is selected, update the existing template
            const response = await emailTemplateAction({
                id: selectedTemplate.id,
                subject: emailData.emailSubject,
                emailBody: emailData.emailBody,
                contactName: emailData.contactName,
                contactEmail: emailData.contactEmail,
            });
            if (response) {
                setMessage('Template saved successfully');
                handleClick();
                setSuccess(true);
                fetchEmailTemplates();
            } else {
                setMessage('An error occurred while saving template.');
                handleClick();
                setSuccess(false);
            }
        } else if (templateName) {
            // If no template is selected, but template name is provided, add a new template
            const response = await emailTemplateAction({
                templateName: templateName,
                subject: emailData.emailSubject,
                emailBody: emailData.emailBody,
                contactName: emailData.contactName,
                contactEmail: emailData.contactEmail,
            });
            if (response) {
                setMessage('Template saved successfully');
                handleClick();
                setSuccess(true);
                fetchEmailTemplates();
            } else {
                setMessage('An error occurred while saving template.');
                handleClick();
                setSuccess(false);
            }
        }
        //handleFormReset();
    };

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setEmailData({
            ...emailData,
            [name]: value,
        });
    };

    const disableSave = selectedTemplate
        ? !emailData.emailSubject || !emailData.emailBody
        : !emailData.emailSubject || !emailData.emailBody || !templateName;

    return (
        <MainTemplate
            main={
                <Stack
                    direction={'row'}
                    sx={{
                        width: '100%',
                    }}
                >
                    <Stack
                        spacing={'10px'}
                        sx={{
                            margin: '10px',
                            width: '50%',
                            border: `1px solid ${theme.palette.structuralColors.stroke1}`,
                            padding: '10px',
                            borderRadius: '10px',
                        }}
                    >
                        <Typography variant="subtitle1">
                            {'Manage Email Templates'}
                        </Typography>
                        <Divider />
                        <Autocomplete
                            size="small"
                            options={emailTemplates}
                            getOptionLabel={(option) => option.templateName}
                            value={selectedTemplate}
                            onChange={handleTemplateChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Template"
                                    placeholder="Templates"
                                />
                            )}
                        />
                        {!selectedTemplate && (
                            <InputField
                                size="small"
                                label="Template Name"
                                name="templateName"
                                value={templateName}
                                onChange={(e) =>
                                    setTemplateName(e.target.value)
                                }
                                fullWidth
                            />
                        )}
                        <InputField
                            size="small"
                            label="Email Subject"
                            name="emailSubject"
                            value={emailData.emailSubject}
                            onChange={handleInputChange}
                            fullWidth
                        />
                        <textarea
                            name="emailBody"
                            placeholder="Email Body"
                            value={emailData.emailBody}
                            onChange={handleInputChange}
                            style={{
                                maxWidth: '98.5%',
                                padding: '5px',
                                border: '1px solid #cecede',
                                color: theme.palette.text.highEmphasis,
                                fontFamily: 'Mier-Book',
                                fontSize: '16px',
                                height: '30vh',
                                maxHeight: '50vh',
                            }}
                        />
                        <InputField
                            size="small"
                            label="Full Name"
                            name="contactName"
                            value={emailData.contactName}
                            onChange={handleInputChange}
                            fullWidth
                        />
                        <InputField
                            size="small"
                            label="Email"
                            name="contactEmail"
                            value={emailData.contactEmail}
                            onChange={handleInputChange}
                            fullWidth
                        />
                        <StyledButton
                            variant="contained"
                            onClick={handleSaveEmailTemplate}
                            disabled={disableSave}
                        >
                            <Typography variant="caption1">{'Save'}</Typography>
                        </StyledButton>
                    </Stack>
                    <Stack
                        sx={{
                            margin: '10px',
                            width: '50%',
                            border: `1px solid ${theme.palette.structuralColors.stroke1}`,
                            padding: '10px',
                            borderRadius: '10px',
                        }}
                    >
                        <Stack sx={{ width: '100%' }} spacing={'10px'}>
                            <Typography variant="subtitle1">
                                {'Select Notification Templates'}
                            </Typography>
                            <Divider />
                            {templateTypes.map((type) => (
                                <Stack
                                    direction={'row'}
                                    spacing={'10px'}
                                    key={type}
                                >
                                    <Stack
                                        sx={{
                                            minWidth: '15vw',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography variant="body1">
                                            {type.replaceAll('_', ' ')}
                                        </Typography>
                                    </Stack>
                                    <Autocomplete
                                        fullWidth
                                        size="small"
                                        options={emailTemplates}
                                        getOptionLabel={(option) =>
                                            option.templateName
                                        }
                                        value={selectedMappingTemplates[type]}
                                        onChange={handleMappingTemplateChange(
                                            type
                                        )}
                                        isOptionEqualToValue={(option, value) =>
                                            option.id === value?.id
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select Template"
                                                placeholder="Templates"
                                            />
                                        )}
                                    />
                                </Stack>
                            ))}
                            <StyledButton
                                variant="contained"
                                onClick={handleSaveMappings}
                                disabled={disableMapppingSave}
                            >
                                <Typography variant="caption1">
                                    {'Save Mapping'}
                                </Typography>
                            </StyledButton>
                        </Stack>
                    </Stack>
                    <CustomSnackbar
                        open={openSnackBar}
                        message={message}
                        success={success}
                        onClose={handleClose}
                        autoHideDuration={1800}
                    />
                </Stack>
            }
        />
    );
};

export default TemplateManagement;
