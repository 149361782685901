import { Stack, Typography, styled } from '@mui/material';
import { useState, useEffect } from 'react';
import theme from '../../../theme';
import useSnackBar from '../../../utils/hooks';
import ConfirmationModal from '../../molecules/ConfirmationModal';
import DataTable from '../DataTable';
import { UserForm } from './userForm';
import {
    addNewUser,
    getAllUsersWithRole,
    removeUser,
} from '../../../services/api';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getUsersColumns } from '../../../utils/helper';
import Button from '../../atoms/Button';
import CustomSnackbar from '../../molecules/CustomSnackbar';

const BackButtonStack = styled(Stack)({
    width: '100%',
    justifyContent: 'flex-start',
    flexDirection: 'row',
});

const BackButton = styled(Button)({
    '&:hover': {
        backgroundColor: theme.palette.structuralColors.white,
    },
    padding: '0px',
});

const ManageUsers = () => {
    const [reportList, setReportList] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const [isLoading, setIsLoading] = useState(true);
    const [rowCount, setRowCount] = useState(0);
    const [showComponent, setShowComponent] = useState(false);
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const { openSnackBar, handleClick, handleClose } = useSnackBar();
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({
        name: '',
        email: '',
    });
    const [fetchNewData, setFetchNewData] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const reports = await getAllUsersWithRole({
                pageNumber: paginationModel.page,
                sizeOfPage: paginationModel.pageSize,
            });
            if (reports) {
                setReportList(reports.content);
                setRowCount(reports.totalElements);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [paginationModel, fetchNewData]);

    const handleComponetChange = () => {
        setShowComponent(!showComponent);
    };

    const handleUserRemove = async (id: string) => {
        const userData = {
            userId: id,
        };
        const response = await removeUser(userData);
        if (response.success) {
            setMessage(response.message);
            handleClick();
            setSuccess(true);
        } else {
            setMessage(response.message);
            handleClick();
            setSuccess(false);
        }
        setFetchNewData(!fetchNewData);
    };

    const handleSubmit = async (name: string, email: string, role: string) => {
        const userData = {
            name: name,
            email: email,
            role: role,
        };
        const response = await addNewUser(userData);
        if (response.success) {
            setMessage(response.message);
            handleClick();
            setSuccess(true);
        } else {
            setMessage(response.message);
            handleClick();
            setSuccess(false);
        }
        setFetchNewData(!fetchNewData);
    };

    const handleOpen = () => setOpen(true);
    const handleModelClose = () => setOpen(false);

    return (
        <Stack>
            <CustomSnackbar
                open={openSnackBar}
                message={message}
                success={success}
                onClose={handleClose}
                autoHideDuration={2500}
            />
            {!showComponent && (
                <Stack
                    sx={{
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        paddingTop: '5vh',
                    }}
                >
                    <Stack sx={{ width: '70vw' }} spacing={'5vh'}>
                        <Stack
                            sx={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                variant="h2"
                                color={theme.palette.text.highEmphasis}
                            >
                                {'Users'}
                            </Typography>
                            <Button
                                onClick={handleComponetChange}
                                variant="contained"
                                sx={{ width: '250px' }}
                                disableElevation
                            >
                                <Typography variant="body1">
                                    {'Add User'}
                                </Typography>
                            </Button>
                        </Stack>
                        <DataTable
                            columns={getUsersColumns((row) => {
                                const rowData = {
                                    name: row.name,
                                    email: row.email,
                                    id: row.id,
                                };
                                setData(rowData);
                                handleOpen();
                            })}
                            rowsData={reportList || []}
                            rowCount={rowCount}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            isLoading={isLoading}
                            checkboxSelection={false}
                        />
                    </Stack>
                </Stack>
            )}
            {showComponent && (
                <Stack
                    sx={{
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        paddingTop: '5vh',
                    }}
                >
                    <Stack sx={{ width: '70vw' }} spacing={'2vh'}>
                        <BackButtonStack>
                            <BackButton
                                startIcon={<ArrowBackIcon />}
                                onClick={handleComponetChange}
                            >
                                <Typography
                                    variant={'body1'}
                                    color={theme.palette.primary[500]}
                                >
                                    {'Back'}
                                </Typography>
                            </BackButton>
                        </BackButtonStack>
                        <UserForm handleContinue={handleSubmit}></UserForm>
                    </Stack>
                </Stack>
            )}
            <ConfirmationModal
                open={open}
                onClose={handleModelClose}
                onConfirm={() => handleUserRemove(data.id)}
                onCancel={handleModelClose}
                title="Are you sure you want to remove access for the user?"
                confirmText="Confirm"
                cancelText="Cancel"
            />
        </Stack>
    );
};

export default ManageUsers;
