import React, { useState, useEffect } from 'react';
import {
    Stack,
    Autocomplete,
    TextField,
    MenuItem,
    Select,
    styled,
} from '@mui/material';
import dayjs from 'dayjs';
import {
    createFormSubmission,
    emailTemplateAction,
    getAllForms,
    getEmailTemplates,
} from '../../../services/api';
import { UserData } from '../../../utils/types';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Typography from '../../atoms/Typography';
import Button from '../../atoms/Button';
import InputField from '../../atoms/InputField';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import theme from '../../../theme';
import ConfirmationModal from '../../molecules/ConfirmationModal';
import useSnackBar from '../../../utils/hooks';
import GenericModal from '../GenericModal';
import CustomSnackbar from '../../molecules/CustomSnackbar';

const StyledButton = styled(Button)({
    padding: '5px 8px',
    width: '130px',
});

interface SendFormForSubmissionProps {
    vendorId: string;
    vendorUsers: any;
    internalUsers: any;
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '38vw',
    bgcolor: 'background.paper',
    border: '1px solid #D4D4D4',
    boxShadow: 24,
    padding: '15px',
    borderRadius: '7px',
};

interface EmailTemplate {
    id: string;
    templateName: string;
    subject: string;
    emailBody: string;
    contactName: string;
    contactEmail: string;
}

const SendFormForSubmission = (props: SendFormForSubmissionProps) => {
    const [formData, setFormData] = useState([]);
    const [emailTemplates, setEmailTemplates] = useState([]);
    const [selectedForm, setSelectedForm] = useState(null);
    const [selectedUser, setSelectedUser] = useState<UserData>(null);
    const [formType, setFormType] = useState<string>('INTERNAL');
    const [dueDate, setDueDate] = useState<Date | null>(null);
    const [assessmentName, setAssessmentName] = useState<string>('');
    const [openEmailModel, setOpenEmailModel] = useState(false);
    const [emailData, setEmailData] = useState({
        fromEmail: 'compliance-team@meesho.com',
        toEmail: '',
        emailSubject: '',
        emailBody: '',
        attachmentLink: '',
        contactName: '',
        contactEmail: '',
    });
    const [selectedTemplate, setSelectedTemplate] =
        useState<EmailTemplate | null>(null);
    const [templateName, setTemplateName] = useState<string>('');
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const { openSnackBar, handleClick, handleClose } = useSnackBar();

    const handleConfirmationModel = () => {
        setConfirmationModalOpen(!confirmationModalOpen);
    };

    const vendorUsers = props.vendorUsers || [];
    const internalUsers = props.internalUsers || [];

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setEmailData({
            ...emailData,
            [name]: value,
        });
    };

    const handleEmailModelOpen = () => {
        setOpenEmailModel(true);
    };

    const handleEmailModelClose = () => {
        setOpenEmailModel(false);
    };

    const fetchFormsData = async () => {
        const response = await getAllForms();
        if (response) {
            setFormData(response);
        } else {
            setMessage('Failed to fetch data from server');
            handleClick();
            setSuccess(false);
        }
    };

    const fetchEmailTemplates = async () => {
        const response = await getEmailTemplates();
        if (response) {
            setEmailTemplates(response.emailTemplates);
            const mapping = response.templateMappings.find(
                (mapping: any) => mapping.type === 'VENDOR_NOTIFICATION'
            );

            setSelectedTemplate(mapping.emailTemplate);
            setEmailData({
                ...emailData,
                emailSubject: mapping.emailTemplate.subject,
                emailBody: mapping.emailTemplate.emailBody,
                contactName: mapping.emailTemplate.contactName,
                contactEmail: mapping.emailTemplate.contactEmail,
            });
        } else {
            setMessage('Failed to fetch data from server');
            handleClick();
            setSuccess(false);
        }
    };

    useEffect(() => {
        fetchFormsData();
        fetchEmailTemplates();
    }, []);

    const handleFormChange = (event: any, newValue: any) => {
        setSelectedForm(newValue);
    };

    const handleUserChange = (event: any, newValue: UserData | null) => {
        setSelectedUser(newValue);
        setEmailData({ ...emailData, toEmail: newValue?.email });
    };

    const handleFormTypeChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setFormType(event.target.value as string);
        setSelectedUser(null);
    };

    const handleDueDateChange = (newValue: Date | null) => {
        setDueDate(newValue);
    };

    const handleAssessmentNameChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setAssessmentName(event.target.value);
    };

    const handleSubmit = async () => {
        if (selectedForm && selectedUser && dueDate) {
            const submissionData = {
                assessmentName: assessmentName,
                vendorId: props.vendorId,
                formId: selectedForm.id,
                respondents: [selectedUser.id],
                formType: formType,
                dueDate: dayjs(dueDate).format('YYYY-MM-DDTHH:mm:ss'),
            };

            const response = await createFormSubmission(submissionData);
            if (response) {
                handleFormReset();
                setMessage('Form sent successfully');
                handleClick();
                setSuccess(true);
            } else {
                setMessage('Failed to send form');
                handleClick();
                setSuccess(false);
            }
        } else {
            setMessage('Please complete all fields');
            handleClick();
            setSuccess(false);
        }
    };

    const isSubmitDisabled =
        !selectedForm || !selectedUser || !dueDate || !assessmentName;

    const handleFormReset = () => {
        setEmailData({
            fromEmail: 'compliance-team@meesho.com',
            toEmail: '',
            emailSubject: '',
            emailBody: '',
            attachmentLink: '',
            contactName: '',
            contactEmail: '',
        });
    };

    const handleTemplateChange = (
        event: any,
        newValue: EmailTemplate | null
    ) => {
        setSelectedTemplate(newValue);
        if (newValue) {
            setEmailData({
                ...emailData,
                emailSubject: newValue.subject,
                emailBody: newValue.emailBody,
                contactName: newValue.contactName,
                contactEmail: newValue.contactEmail,
            });
        } else {
            setEmailData({
                ...emailData,
                emailSubject: '',
                emailBody: '',
                contactName: '',
                contactEmail: '',
            });
        }
    };

    const handleSaveEmailTemplate = async () => {
        if (selectedTemplate) {
            // If template is selected, update the existing template
            await emailTemplateAction({
                id: selectedTemplate.id,
                subject: emailData.emailSubject,
                emailBody: emailData.emailBody,
                contactName: emailData.contactName,
                contactEmail: emailData.contactEmail,
            });
        } else if (templateName) {
            // If no template is selected, but template name is provided, add a new template
            await emailTemplateAction({
                templateName: templateName,
                subject: emailData.emailSubject,
                emailBody: emailData.emailBody,
                contactName: emailData.contactName,
                contactEmail: emailData.contactEmail,
            });
        }
        handleFormReset();
        handleEmailModelClose();
    };

    const disableSave = selectedTemplate
        ? !emailData.fromEmail ||
          !emailData.toEmail ||
          !emailData.emailSubject ||
          !emailData.emailBody ||
          !emailData.contactName ||
          !emailData.contactEmail
        : !emailData.fromEmail ||
          !emailData.toEmail ||
          !emailData.emailSubject ||
          !emailData.emailBody ||
          !emailData.contactName ||
          !emailData.contactEmail ||
          !templateName;

    return (
        <Stack spacing={'20px'} padding={'10px'}>
            <Stack
                spacing={'15px'}
                sx={{
                    border: `1px solid ${theme.palette.structuralColors.gray}`,
                    padding: '15px 10px',
                    borderRadius: '3px',
                }}
            >
                <Stack spacing={'16px'} sx={{ width: '40%' }}>
                    <Stack spacing={'6px'}>
                        <Typography variant="body2">
                            {'Assessment Name'}
                        </Typography>
                        <TextField
                            size="small"
                            label="Assessment Name"
                            fullWidth
                            value={assessmentName} // Bind state to the input value
                            onChange={handleAssessmentNameChange} // Handle state change
                        />
                    </Stack>
                    <Stack spacing={'6px'}>
                        <Typography variant="body2">{'Select Form'}</Typography>
                        <Autocomplete
                            size="small"
                            options={formData}
                            getOptionLabel={(option) => option.title}
                            onChange={handleFormChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Form"
                                    placeholder="Forms"
                                />
                            )}
                        />
                    </Stack>
                    <Stack spacing={'6px'}>
                        <Typography variant="body2">{'Select Type'}</Typography>
                        <Select
                            size="small"
                            value={formType}
                            onChange={handleFormTypeChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Select Type' }}
                        >
                            <MenuItem value="INTERNAL">Internal</MenuItem>
                            <MenuItem value="EXTERNAL">External</MenuItem>
                        </Select>
                    </Stack>
                    <Stack spacing={'6px'}>
                        <Typography variant="body2">{'Select User'}</Typography>
                        <Autocomplete
                            size="small"
                            options={
                                formType === 'INTERNAL'
                                    ? internalUsers
                                    : vendorUsers
                            }
                            getOptionLabel={(option) => option.name}
                            value={selectedUser}
                            onChange={handleUserChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select User"
                                    placeholder="Users"
                                />
                            )}
                        />
                    </Stack>
                    <Stack spacing={'6px'}>
                        <Typography variant="body2">
                            {'Select Due Date'}
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Due Date"
                                value={dueDate}
                                onChange={handleDueDateChange}
                                slotProps={{ textField: { size: 'small' } }}
                                disablePast
                            />
                        </LocalizationProvider>
                    </Stack>
                    <Button variant="text" onClick={handleEmailModelOpen}>
                        <Typography variant="body1">
                            {'Preview Email'}
                        </Typography>
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleConfirmationModel}
                        disabled={isSubmitDisabled}
                    >
                        <Typography variant="body1">{'Send Form'}</Typography>
                    </Button>
                </Stack>
                <GenericModal
                    open={openEmailModel}
                    onClose={handleEmailModelClose}
                >
                    <Stack sx={style} spacing={'12px'}>
                        <Stack
                            direction={'row'}
                            sx={{ justifyContent: 'space-between' }}
                        >
                            <Typography variant="subtitle1">
                                {'Draft Email'}
                            </Typography>
                            <StyledButton
                                startIcon={<RestartAltIcon />}
                                onClick={() => {
                                    setSelectedTemplate(null);
                                    handleFormReset();
                                }}
                                variant="outlined"
                            >
                                {'Reset'}
                            </StyledButton>
                        </Stack>
                        <Autocomplete
                            size="small"
                            options={emailTemplates}
                            getOptionLabel={(option) => option.templateName}
                            value={selectedTemplate}
                            onChange={handleTemplateChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Template"
                                    placeholder="Templates"
                                />
                            )}
                        />
                        {!selectedTemplate && (
                            <InputField
                                size="small"
                                label="Template Name"
                                name="templateName"
                                value={templateName}
                                onChange={(e) =>
                                    setTemplateName(e.target.value)
                                }
                                fullWidth
                            />
                        )}
                        <Stack
                            direction={'row'}
                            sx={{ justifyContent: 'space-between' }}
                        >
                            <Stack
                                direction={'row'}
                                sx={{
                                    justifyContent: 'center',
                                    alignItems: 'flex-end',
                                    width: '49%',
                                }}
                                spacing={'3px'}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{ paddingBottom: '6px' }}
                                >
                                    {'From: '}
                                </Typography>
                                <InputField
                                    size="small"
                                    label="From Email"
                                    name="fromEmail"
                                    value={emailData.fromEmail}
                                    onChange={handleInputChange}
                                    fullWidth
                                    variant="standard"
                                    disabled={true}
                                />
                            </Stack>
                            <Stack
                                direction={'row'}
                                sx={{
                                    justifyContent: 'center',
                                    alignItems: 'flex-end',
                                    width: '49%',
                                }}
                                spacing={'3px'}
                            >
                                <Typography variant="body1">
                                    {'To: '}
                                </Typography>
                                <InputField
                                    size="small"
                                    label="To Email"
                                    name="toEmail"
                                    value={emailData.toEmail}
                                    onChange={handleInputChange}
                                    variant="standard"
                                    fullWidth
                                />
                            </Stack>
                        </Stack>
                        <InputField
                            size="small"
                            label="Email Subject"
                            name="emailSubject"
                            value={emailData.emailSubject}
                            onChange={handleInputChange}
                            fullWidth
                        />
                        <textarea
                            name="emailBody"
                            placeholder="Email Body"
                            value={emailData.emailBody}
                            onChange={handleInputChange}
                            style={{
                                maxWidth: '98%',
                                padding: '5px',
                                border: '1px solid #cecede',
                                color: theme.palette.text.highEmphasis,
                                fontFamily: 'Mier-Book',
                                fontSize: '16px',
                                height: '200px',
                                maxHeight: '200px',
                            }}
                        />
                        <InputField
                            size="small"
                            label="Attachment Link"
                            name="attachmentLink"
                            value={emailData.attachmentLink}
                            onChange={handleInputChange}
                            fullWidth
                        />
                        <Stack
                            sx={{
                                border: '1px solid #D4D4D4',
                                padding: '10px',
                                borderRadius: '5px',
                            }}
                            spacing={'10px'}
                        >
                            <Stack>
                                <Typography variant="body1">
                                    {'Contact person details'}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color={theme.palette.text.lowEmphasis}
                                >
                                    {
                                        "Update / provide details of one of your company's personnel to whom the recipient can send queries."
                                    }
                                </Typography>
                            </Stack>
                            <InputField
                                size="small"
                                label="Full Name"
                                name="contactName"
                                value={emailData.contactName}
                                onChange={handleInputChange}
                                fullWidth
                            />
                            <InputField
                                size="small"
                                label="Email"
                                name="contactEmail"
                                value={emailData.contactEmail}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Stack>
                        <Stack
                            direction={'row'}
                            spacing={'10px'}
                            sx={{ justifyContent: 'flex-end' }}
                        >
                            <StyledButton
                                variant="contained"
                                onClick={handleEmailModelClose}
                                disabled={disableSave}
                            >
                                <Typography variant="caption1">
                                    {'Close'}
                                </Typography>
                            </StyledButton>
                            <StyledButton
                                variant="contained"
                                onClick={handleSaveEmailTemplate}
                                disabled={disableSave}
                            >
                                <Typography variant="caption1">
                                    {'Save Template'}
                                </Typography>
                            </StyledButton>
                        </Stack>
                    </Stack>
                </GenericModal>
                <ConfirmationModal
                    open={confirmationModalOpen}
                    onClose={handleConfirmationModel}
                    onConfirm={() => {
                        handleSubmit();
                    }}
                    onCancel={handleConfirmationModel}
                    title={'Are you sure you want to send the assessment?'}
                    confirmText={'Send'}
                    cancelText={'Cancel'}
                />
                <CustomSnackbar
                    open={openSnackBar}
                    message={message}
                    success={success}
                    onClose={handleClose}
                    autoHideDuration={1800}
                />
            </Stack>
        </Stack>
    );
};

export default SendFormForSubmission;
