import React, { useState } from 'react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import '@cyntler/react-doc-viewer/dist/index.css';
import { Stack, CircularProgress, Box } from '@mui/material';

const DocumentViewer = ({ filePreview, loading }) => {
    const [fileUrl, setFileUrl] = useState<string | null>(
        filePreview?.fileUrl || null
    );
    const [fileName, setFileName] = useState(filePreview?.fileName || '');

    const supportedFileTypes = ['png', 'jpeg', 'jpg', 'pdf', 'txt'];

    const getFileType = (fileName: string) => {
        const extension = fileName.split('.').pop()?.toLowerCase();

        if (
            ['docx', 'ppt', 'pptx', 'xls', 'xlsx', 'doc', 'csv'].includes(
                extension!
            )
        ) {
            return 'pdf';
        }

        return supportedFileTypes.includes(extension!) ? extension : 'default';
    };

    return (
        <Stack>
            {loading && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        zIndex: 1,
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            {fileUrl ? (
                <DocViewer
                    documents={[
                        {
                            uri: fileUrl,
                            fileName: fileName,
                            fileType: getFileType(fileName),
                        },
                    ]}
                    pluginRenderers={DocViewerRenderers}
                    config={{
                        csvDelimiter: ',',
                    }}
                />
            ) : (
                <h1>No file preview available</h1>
            )}
        </Stack>
    );
};

export default DocumentViewer;
