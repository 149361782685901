import { Divider, Stack } from '@mui/material';
import theme from '../../../theme';
import Typography from '../../atoms/Typography';
import Button from '../../atoms/Button';
import { useEffect, useState } from 'react';
import { addDocumentRemarks, getDocumentRemarks } from '../../../services/api';
import './index.css';
import useSnackBar from '../../../utils/hooks';
import CustomSnackbar from '../../molecules/CustomSnackbar';

const RemarkViewer = ({ docId, docType }) => {
    const [formData, setFormData] = useState({
        remarks: '',
    });
    const [comments, setComments] = useState([]);
    const [documentName, setDocumentName] = useState('');
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const { openSnackBar, handleClick, handleClose } = useSnackBar();

    const fetchData = async () => {
        if (docId) {
            const response = await getDocumentRemarks({
                docId: docId,
                type: docType,
            });

            if (response) {
                if (response.remarks) {
                    setComments(response.remarks);
                    setDocumentName(response.documentName);
                    setFormData({
                        remarks: '',
                    });
                } else {
                    setComments([]);
                }
            } else {
                setMessage('Failed to get remarks. Please try again.');
                handleClick();
                setSuccess(false);
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, [docId]);

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleAddRemark = async () => {
        const data = {
            remarks: formData.remarks,
            docId: docId,
            type: docType,
        };
        const response = await addDocumentRemarks(data);
        if (response) {
            setFormData({
                remarks: '',
            });
            setMessage('Remark added successfully.');
            handleClick();
            setSuccess(true);
            fetchData();
        } else {
            setMessage('Failed to add remark. Please try again.');
            handleClick();
            setSuccess(false);
        }
    };

    const formatDateTime = (dateTime: string) => {
        const date = new Date(dateTime);
        const formattedDate = date.toISOString().split('T')[0];
        return formattedDate;
    };

    return (
        <Stack>
            {docId != '' ? (
                <Stack spacing={'10px'} padding={'5px'}>
                    <Stack spacing={'5px'}>
                        <Typography
                            variant="subtitle1"
                            color={theme.palette.text.mediumEmphasis}
                        >
                            {'Add Remarks For - '}
                            {
                                <span
                                    style={{
                                        color: theme.palette.text.highEmphasis,
                                    }}
                                >
                                    {documentName}
                                </span>
                            }
                        </Typography>
                        <textarea
                            placeholder="Add Remarks"
                            name="remarks"
                            value={formData.remarks}
                            onChange={handleInputChange}
                            style={{
                                width: '96.5%',
                                padding: '5px',
                                border: '1px solid #cecede',
                                color: theme.palette.text.highEmphasis,
                                fontFamily: 'Mier-Book',
                                fontSize: '16px',
                            }}
                        />
                    </Stack>
                    <Button variant="outlined" onClick={handleAddRemark}>
                        <Typography variant="caption1">
                            {'Add Remarks'}
                        </Typography>
                    </Button>
                    <Divider />
                    <Stack>
                        <Typography
                            variant="subtitle1"
                            color={theme.palette.text.mediumEmphasis}
                        >
                            {'Added Remarks'}
                        </Typography>
                        <Stack
                            spacing={'10px'}
                            sx={{
                                height: '55vh',
                                overflow: 'scroll',
                            }}
                        >
                            {comments &&
                                comments.length > 0 &&
                                comments.map((data) => (
                                    <Stack
                                        key={data.id}
                                        sx={{
                                            borderRadius: '10px',
                                            border: '1px solid #EFEFEF',
                                        }}
                                    >
                                        <Stack
                                            sx={{
                                                padding: '5px 5px 3px 5px',
                                            }}
                                        >
                                            <textarea
                                                readOnly
                                                style={{
                                                    border: 'none',
                                                    color: theme.palette.text
                                                        .highEmphasis,
                                                    fontFamily: 'Mier-Book',
                                                    fontSize: '16px',
                                                }}
                                                value={data.text}
                                            />
                                        </Stack>
                                        <Stack
                                            direction={'row'}
                                            sx={{
                                                justifyContent: 'space-between',
                                                backgroundColor: '#EFEFEF',
                                                padding: '3px 5px 3px 5px',
                                                borderBottomLeftRadius: '9px',
                                                borderBottomRightRadius: '9px',
                                            }}
                                            spacing={'10px'}
                                        >
                                            <Typography
                                                variant="caption2"
                                                color={
                                                    theme.palette.text
                                                        .mediumEmphasis
                                                }
                                            >
                                                {'Added By: ' + data.addedBy}
                                            </Typography>

                                            <Typography
                                                variant="caption2"
                                                color={
                                                    theme.palette.text
                                                        .mediumEmphasis
                                                }
                                            >
                                                {'Added At: ' +
                                                    formatDateTime(
                                                        data.addedAt
                                                    )}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                ))}
                        </Stack>
                        <Divider />
                    </Stack>
                </Stack>
            ) : (
                <Stack
                    direction={'row'}
                    sx={{
                        justifyContent: 'center',
                        backgroundColor: theme.palette.accentColors.lightBlue,
                        border: `1px solid ${theme.palette.accentColors.blue}`,
                        padding: '10px',
                        borderRadius: '10px',
                    }}
                >
                    <Typography
                        variant="body1"
                        color={theme.palette.accentColors.blue}
                    >
                        {'Select document to Add/View remarks'}
                    </Typography>
                </Stack>
            )}
            <CustomSnackbar
                open={openSnackBar}
                message={message}
                success={success}
                onClose={handleClose}
                autoHideDuration={1800}
            />
        </Stack>
    );
};

export default RemarkViewer;
