import React from 'react';
import { Outlet } from 'react-router-dom';
import { VendorAuthContextProvider } from '../context/vendorAuthContext';

export const VendorAuthLayout = () => {
    return (
        <VendorAuthContextProvider>
            <Outlet />
        </VendorAuthContextProvider>
    );
};
