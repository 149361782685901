import { Stack } from '@mui/material';
import DocumentHeader from '../../components/molecules/DocumentHeader';
import AllApproveTab from '../../components/organisms/AllApprovals';
import ApproveTab from '../../components/organisms/ApproveTab';
import CreateApprovalTab from '../../components/organisms/CreateApprovalTab';
import DocumentDetail from '../../components/organisms/DocumentDetail';
import DocumentTab, { DocValues } from '../../components/organisms/DocumentTab';
import MainTemplate from '../../components/templates/MainTemplate';
import { useLocation, useNavigate } from 'react-router-dom';
import ChatIcon from '@mui/icons-material/Chat';
import { useEffect, useState } from 'react';
import RemarkViewer from '../../components/organisms/RemarkViewer';
import HistoryIcon from '@mui/icons-material/History';
import TuneIcon from '@mui/icons-material/Tune';
import RegistryPropertiesTab from '../../components/organisms/RegistryPropertiesTab';
import ActivityList from '../../components/organisms/ActivityList';
import { getAllUsers, getRegistry } from '../../services/api';
import { DecodedJWT, RegistryData, UserData } from '../../utils/types';
import { jwtDecode } from 'jwt-decode';

const RegistryDetailsPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const type = queryParams.get('type');
    const [verticalTab, setVerticalTab] = useState(0);
    const [documentId, setDocumentId] = useState('');
    const [documentType, setDocumentType] = useState('');
    const [userData, setUserData] = useState<UserData[]>([]);
    const [registryData, setRegistryData] = useState<RegistryData>({
        id: '',
        referenceId: '',
        name: '',
        registryStatus: '',
        team: '',
        dataClassification: '',
        type: '',
    });
    const [hiddenHorizontalTabs, setHiddenHorizontalTab] = useState([
        'Create Approval',
        'All Approval',
    ]);
    const [hiddenVerticalTabs, setHiddenVerticalTab] = useState(['Activities']);

    let token;
    if (localStorage.getItem('token')) {
        token = localStorage.getItem('token');
    }
    const { role } = jwtDecode<DecodedJWT>(token);

    const handleVerticalTabChange = (newValue: number) => {
        setVerticalTab(newValue);
    };

    const handleDocValueChange = (docValues: DocValues) => {
        setDocumentId(docValues.docId);
        setDocumentType(docValues.type);
    };

    const fetchUserData = async () => {
        const response: UserData[] = await getAllUsers();
        if (response) {
            setUserData(response);
        } else {
            console.log('error');
        }
    };

    const fetchRegistryById = async () => {
        const response = await getRegistry(id);
        if (response) {
            setRegistryData(response);
        } else {
            console.log('error');
        }
    };

    useEffect(() => {
        fetchRegistryById();
        if (role === 'ADMIN') {
            setHiddenHorizontalTab([]);
            setHiddenVerticalTab([]);
            fetchUserData();
        }
    }, []);

    const horizontalTabs = [
        {
            label: 'Documents & Links',
            content: (
                <Stack
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    {id && (
                        <DocumentTab
                            setRemarkTab={() => handleVerticalTabChange(1)}
                            registryId={id}
                            onDocumentIdChange={(docValues) =>
                                handleDocValueChange(docValues)
                            }
                            userRole={role}
                        />
                    )}
                </Stack>
            ),
        },
        {
            label: 'Approve',
            content: (
                <Stack
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    {id && <ApproveTab registryId={id} />}
                </Stack>
            ),
        },
        {
            label: 'Create Approval',
            content: (
                <Stack
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    {id && (
                        <CreateApprovalTab
                            registryId={id}
                            userData={userData}
                        />
                    )}
                </Stack>
            ),
        },
        {
            label: 'All Approval',
            content: (
                <Stack
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    {id && <AllApproveTab registryId={id} />}
                </Stack>
            ),
        },
    ];

    const verticalTabs = [
        {
            label: 'Properties',
            icon: <TuneIcon />,
            content: (
                <Stack
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    {id && (
                        <RegistryPropertiesTab
                            registryId={id}
                            userRole={role}
                            fetchData={fetchRegistryById}
                            resgistryData={registryData}
                        />
                    )}
                </Stack>
            ),
        },
        {
            label: 'Remarks',
            icon: <ChatIcon />,
            content: <RemarkViewer docId={documentId} docType={documentType} />,
        },
        {
            label: 'Activities',
            icon: <HistoryIcon />,
            content: <ActivityList id={id} activityType={'Registry'} />,
        },
    ];

    return (
        <MainTemplate
            main={
                <Stack sx={{ width: '100%', height: '100%' }}>
                    {registryData.name && id && (
                        <DocumentHeader
                            backButtonClick={() => navigate('/u/' + `${type}`)}
                            labelText={registryData.name}
                            id={id}
                            fetchData={fetchRegistryById}
                            userRole={role}
                            type={'REGISTRY'}
                        />
                    )}
                    <DocumentDetail
                        horizontalTabs={horizontalTabs}
                        verticalTabs={verticalTabs}
                        verticalTabValue={verticalTab}
                        onVerticalTabChange={handleVerticalTabChange}
                        hiddenHorizontalTabs={hiddenHorizontalTabs}
                        hiddenVerticalTabs={hiddenVerticalTabs}
                    />
                </Stack>
            }
        ></MainTemplate>
    );
};

export default RegistryDetailsPage;
