import React, { useEffect, useState } from 'react';
import {
    TextField,
    MenuItem,
    Autocomplete,
    Stack,
    styled,
    Button,
    Divider,
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Typography from '../../atoms/Typography';
import theme from '../../../theme';
import { Teams } from '../../../utils/constants';
import { updateRiskProcess } from '../../../services/api';
import { UserData } from '../../../utils/types';
import InputField from '../../atoms/InputField';
import useSnackBar from '../../../utils/hooks';
import CustomSnackbar from '../../molecules/CustomSnackbar';

type RiskTreatmentOption = 'Mitigate' | 'Accept' | 'Transfer' | 'Avoid';

const StyledButton = styled(Button)({
    padding: '5px 8px',
    width: '160px',
});

interface RiskTreatmentForm {
    riskTreatment: RiskTreatmentOption | '';
    actionPlan: string;
    businessJustification: string;
    riskAcceptanceOwnersTeam: string[];
    riskAcceptanceOwnerIds: string[];
    dependencies: string;
    targetDate: Dayjs | null;
}

interface RiskTreatmentComponentProps {
    riskId: string;
    userProfileData: any;
    riskData: any;
    fetchData: () => void;
}

const RiskTreatmentComponent = (props: RiskTreatmentComponentProps) => {
    const [formData, setFormData] = useState<RiskTreatmentForm>({
        riskTreatment: '',
        actionPlan: '',
        businessJustification: '',
        riskAcceptanceOwnersTeam: [],
        riskAcceptanceOwnerIds: [],
        dependencies: '',
        targetDate: null,
    });
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const { openSnackBar, handleClick, handleClose } = useSnackBar();
    const userData = props.userProfileData || [];
    const riskFormData = props.riskData || [];

    const fetchedData = () => {
        const response = riskFormData;
        if (response) {
            if (response.riskTreatment != null) {
                const data = response.riskTreatment;
                const formattedData = {
                    riskTreatment: data?.riskTreatment,
                    actionPlan: data?.actionPlan,
                    businessJustification: data?.businessJustification,
                    riskAcceptanceOwnersTeam: data?.riskAcceptanceOwnersTeam,
                    riskAcceptanceOwnerIds: data?.riskAcceptanceOwners.map(
                        (owner: UserData) => owner.id
                    ),
                    dependencies: data?.dependencies,
                    targetDate: data?.targetDate
                        ? dayjs(data.targetDate)
                        : null,
                };
                setFormData(formattedData);
            }
        } else {
            setMessage('Failed to fetch data from server.');
            handleClick();
            setSuccess(false);
        }
    };

    useEffect(() => {
        fetchedData();
    }, []);

    const handleInputChange =
        (field: keyof RiskTreatmentForm) =>
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setFormData({
                ...formData,
                [field]: event.target.value,
            });
        };

    const handleAutocompleteChange = (
        e: React.ChangeEvent<unknown>,
        value: string[] | UserData[],
        type: 'team' | 'user'
    ) => {
        if (type === 'team') {
            setFormData({
                ...formData,
                riskAcceptanceOwnersTeam: value as string[],
            });
        } else if (type === 'user') {
            setFormData({
                ...formData,
                riskAcceptanceOwnerIds: (value as UserData[]).map(
                    (user) => user.id
                ),
            });
        }
    };

    const handleDateChange = (date: Dayjs | null) => {
        setFormData({
            ...formData,
            targetDate: date,
        });
    };

    const handleSave = async () => {
        const payload = {
            ...formData,
            targetDate: formData.targetDate
                ? formData.targetDate.format('YYYY-MM-DD')
                : '',
        };

        const response = await updateRiskProcess(
            props.riskId,
            'TREATMENT',
            payload
        );
        if (response && response.success) {
            setMessage(response.message);
            handleClick();
            setSuccess(true);
            props.fetchData();
        } else {
            setMessage('Please fill in all the fields before saving.');
            handleClick();
            setSuccess(false);
        }
    };

    return (
        <Stack spacing={'20px'} padding={'10px'}>
            <Stack
                spacing={'15px'}
                sx={{
                    border: `1px solid ${theme.palette.structuralColors.gray}`,
                    padding: '15px 10px',
                    borderRadius: '3px',
                }}
            >
                <Typography
                    variant="subtitle1"
                    color={theme.palette.text.highEmphasis}
                >
                    {'Risk Treatment'}
                </Typography>
                <Divider />
                <TextField
                    size="small"
                    select
                    label="Risk Treatment"
                    value={formData.riskTreatment}
                    onChange={handleInputChange('riskTreatment')}
                    fullWidth
                >
                    {['Mitigate', 'Accept', 'Transfer', 'Avoid'].map(
                        (option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        )
                    )}
                </TextField>
                <Stack spacing={'5px'}>
                    <Typography
                        variant="caption1"
                        color={theme.palette.text.mediumEmphasis}
                    >
                        {'Risk Treatment Plan'}
                    </Typography>
                    <textarea
                        placeholder="Risk Treatment Plan"
                        value={formData.actionPlan}
                        onChange={handleInputChange('actionPlan')}
                        style={{
                            fontFamily: 'Mier-Book',
                            fontSize: '16px',
                            padding: '5px',
                            width: '99%',
                            border: `1px solid ${theme.palette.structuralColors.stroke1}`,
                            minHeight: '120px',
                        }}
                    />
                </Stack>
                <InputField
                    size="small"
                    label="Business Justification"
                    value={formData.businessJustification}
                    onChange={handleInputChange('businessJustification')}
                    fullWidth
                />
                <Autocomplete
                    multiple
                    size="small"
                    options={Teams}
                    getOptionLabel={(option) => option}
                    value={formData.riskAcceptanceOwnersTeam}
                    onChange={(e, value) =>
                        handleAutocompleteChange(e, value, 'team')
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Action Plan / Risk Acceptance Owner Team"
                            placeholder="Action Plan / Risk Acceptance Owner Team"
                        />
                    )}
                />
                <Autocomplete
                    multiple
                    size="small"
                    options={userData}
                    getOptionLabel={(option) => option.name}
                    value={userData.filter((user) =>
                        formData.riskAcceptanceOwnerIds.includes(user.id)
                    )}
                    onChange={(e, value) =>
                        handleAutocompleteChange(e, value, 'user')
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Action Plan / Risk Acceptance Owner"
                            placeholder="Action Plan / Risk Acceptance Owner"
                        />
                    )}
                />
                <InputField
                    size="small"
                    label="Dependencies"
                    value={formData.dependencies}
                    onChange={handleInputChange('dependencies')}
                    fullWidth
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Target Date"
                        value={formData.targetDate}
                        onChange={(newValue) => handleDateChange(newValue)}
                        slotProps={{ textField: { size: 'small' } }}
                    />
                </LocalizationProvider>
                <StyledButton variant="outlined" onClick={handleSave}>
                    <Typography variant="caption1">{'Save'}</Typography>
                </StyledButton>
            </Stack>
            <CustomSnackbar
                open={openSnackBar}
                message={message}
                success={success}
                onClose={handleClose}
                autoHideDuration={1800}
            />
        </Stack>
    );
};

export default RiskTreatmentComponent;
