import React, { useState, useEffect } from 'react';
import { getActivitiesById } from '../../../services/api';
import { Stack, Divider, List, ListItem, ListItemText } from '@mui/material';
import Typography from '../../atoms/Typography';
import useSnackBar from '../../../utils/hooks';
import CustomSnackbar from '../../molecules/CustomSnackbar';

const ActivityList = ({ activityType, id }) => {
    const [activities, setActivities] = useState([]);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const { openSnackBar, handleClick, handleClose } = useSnackBar();

    useEffect(() => {
        const fetchActivities = async () => {
            try {
                if (activityType === 'Registry') {
                    const response = await getActivitiesById(id, 'REGISTRY');
                    setActivities(response);
                } else if (activityType === 'Risk Register') {
                    const response = await getActivitiesById(
                        id,
                        'RISK_REGISTER'
                    );
                    setActivities(response);
                }
            } catch (error) {
                setMessage('Failed to resend approval. Please try again.');
                handleClick();
                setSuccess(false);
            }
        };

        fetchActivities();
    }, []);

    return (
        <Stack
            sx={{
                width: '100%',
                height: '82vh',
                overflow: 'scroll',
            }}
        >
            <Stack sx={{ backgroundColor: '#f2f2f2', padding: '5px 8px' }}>
                <Typography
                    variant="body2"
                    sx={{
                        color: '#4a4a4a',
                    }}
                >
                    Activity Details
                </Typography>
            </Stack>
            {activities && activities.length > 0 ? (
                <List>
                    {activities.map((activity) => (
                        <React.Fragment key={activity.id}>
                            <ListItem
                                alignItems="flex-start"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    paddingBottom: '12px',
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant="body2"
                                            component="span"
                                            sx={{
                                                color: '#333',
                                                fontWeight: 500,
                                            }}
                                        >
                                            {activity.details !=
                                                'Approval process completed' && (
                                                <span
                                                    style={{
                                                        textTransform:
                                                            'capitalize',
                                                    }}
                                                >
                                                    {activity.performedBy}
                                                </span>
                                            )}{' '}
                                            {activity.details} on{' '}
                                            {new Date(
                                                activity.performedAt
                                            ).toLocaleString()}
                                        </Typography>
                                    }
                                />
                                {activity.additionalInfo && (
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: '#7a7a7a',
                                            marginTop: '4px',
                                        }}
                                    >
                                        {activity.additionalInfo}
                                    </Typography>
                                )}
                            </ListItem>
                            <Divider sx={{ width: '100%' }} />
                        </React.Fragment>
                    ))}
                </List>
            ) : (
                <Stack
                    sx={{
                        marginTop: '15px',
                        padding: '5px',
                        textAlign: 'center',
                        border: '1px dashed grey',
                    }}
                >
                    <Typography
                        variant="body2"
                        sx={{
                            color: '#7a7a7a',
                        }}
                    >
                        No Activity yet
                    </Typography>
                </Stack>
            )}
            <CustomSnackbar
                open={openSnackBar}
                message={message}
                success={success}
                onClose={handleClose}
                autoHideDuration={1800}
            />
        </Stack>
    );
};

export default ActivityList;
