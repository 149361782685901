import * as React from 'react';
import Modal from '@mui/material/Modal';
import { Stack } from '@mui/material';

const ModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
};

interface GenericModalProps {
    open: boolean;
    onClose?: () => void;
    children: React.ReactNode;
    style?: React.CSSProperties;
}

const GenericModal: React.FC<GenericModalProps> = ({
    open,
    onClose,
    children,
    style,
}) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Stack sx={ModalStyle} style={style}>
                {children}
            </Stack>
        </Modal>
    );
};

export default GenericModal;
