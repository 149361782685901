// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea {
    width: 100%;
    border: 1px solid #cecede;
    background-color: #ffffff;
    border-radius: 5px;
    min-height: 120px;
}

textarea:focus {
    outline: none !important;
    border: none;
    box-shadow: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/organisms/RemarkViewer/index.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;IACzB,yBAAyB;IACzB,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,wBAAwB;IACxB,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":["textarea {\n    width: 100%;\n    border: 1px solid #cecede;\n    background-color: #ffffff;\n    border-radius: 5px;\n    min-height: 120px;\n}\n\ntextarea:focus {\n    outline: none !important;\n    border: none;\n    box-shadow: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
