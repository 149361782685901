import React, { useState, useEffect } from 'react';
import {
    TextField,
    MenuItem,
    Autocomplete,
    Stack,
    styled,
    Typography,
    Divider,
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import theme from '../../../theme';
import { UserData } from '../../../utils/types';
import { updateRiskProcess } from '../../../services/api';
import { Teams } from '../../../utils/constants';
import Button from '../../atoms/Button';
import useSnackBar from '../../../utils/hooks';
import CustomSnackbar from '../../molecules/CustomSnackbar';

type StatusOption = 'IN_PROGRESS' | 'PLANNED' | 'COMPLETED' | 'DELAYED';

const StyledButton = styled(Button)({
    padding: '5px 8px',
    width: '160px',
});

const StatusValues = [
    {
        id: 1,
        option: 'In Progress',
        value: 'IN_PROGRESS',
    },
    {
        id: 2,
        option: 'Planned',
        value: 'PLANNED',
    },
    {
        id: 3,
        option: 'Completed',
        value: 'COMPLETED',
    },
    {
        id: 3,
        option: 'Delayed',
        value: 'DELAYED',
    },
];

interface RiskMonitoringForm {
    status: StatusOption | '';
    keyHighlights: string;
    riskReviewerTeam: string[];
    riskReviewerIds: string[];
    actualClosureDate: Dayjs | null;
}

interface RiskMonitoringProps {
    riskId: string;
    userProfileData: any;
    riskData: any;
    fetchData: () => void;
}

const RiskMonitoring = (props: RiskMonitoringProps) => {
    const [formData, setFormData] = useState<RiskMonitoringForm>({
        status: '',
        keyHighlights: '',
        riskReviewerTeam: [],
        riskReviewerIds: [],
        actualClosureDate: null,
    });
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const { openSnackBar, handleClick, handleClose } = useSnackBar();
    const userData = props.userProfileData || [];
    const riskFormData = props.riskData || [];

    const fetchedData = async () => {
        const response = riskFormData;
        if (response) {
            if (response.riskMonitoring != null) {
                const data = response.riskMonitoring;
                const formattedData = {
                    status: data?.status || '',
                    keyHighlights: data?.keyHighlights || '',
                    riskReviewerTeam: data?.riskReviewerTeam || [],
                    riskReviewerIds: data?.riskReviewerNames
                        ? data?.riskReviewerNames.map(
                              (owner: UserData) => owner.id
                          )
                        : [],
                    actualClosureDate: data?.actualClosureDate
                        ? dayjs(data?.actualClosureDate)
                        : null,
                };
                setFormData(formattedData);
            }
        } else {
            setMessage('Failed to fetch data from server.');
            handleClick();
            setSuccess(false);
        }
    };

    useEffect(() => {
        fetchedData();
    }, []);

    useEffect(() => {
        if (formData.status === 'COMPLETED') {
            setFormData((prevState) => ({
                ...prevState,
                actualClosureDate: dayjs(),
            }));
        }
    }, [formData.status]);

    const handleInputChange =
        (field: keyof RiskMonitoringForm) =>
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setFormData({
                ...formData,
                [field]: event.target.value,
            });
        };

    const handleAutocompleteChange = (
        e: React.ChangeEvent<unknown>,
        value: any[],
        type: 'team' | 'user'
    ) => {
        if (type === 'team') {
            setFormData({
                ...formData,
                riskReviewerTeam: value as string[],
            });
        } else if (type === 'user') {
            setFormData({
                ...formData,
                riskReviewerIds: value.map((user) => user.id),
            });
        }
    };

    const handleSave = async () => {
        const payload = {
            ...formData,
            actualClosureDate: formData.actualClosureDate
                ? formData.actualClosureDate.format('YYYY-MM-DD')
                : null,
        };

        const response = await updateRiskProcess(
            props.riskId,
            'MONITORING',
            payload
        );
        if (response && response.success) {
            setMessage(response.message);
            handleClick();
            setSuccess(true);
            props.fetchData();
        } else {
            setMessage('Please fill in all the fields before saving.');
            handleClick();
            setSuccess(false);
        }
    };

    return (
        <Stack spacing={'20px'} padding={'10px'}>
            <Stack
                spacing={'15px'}
                sx={{
                    border: `1px solid ${theme.palette.structuralColors.gray}`,
                    padding: '15px 10px',
                    borderRadius: '3px',
                }}
            >
                <Typography
                    variant="subtitle1"
                    color={theme.palette.text.highEmphasis}
                >
                    {'Risk Monitoring'}
                </Typography>
                <Divider />
                <TextField
                    size="small"
                    select
                    label="RTP Status"
                    value={formData.status}
                    onChange={handleInputChange('status')}
                    fullWidth
                >
                    {StatusValues.map((data) => (
                        <MenuItem key={data.id} value={data.value}>
                            <Typography
                                variant="body1"
                                sx={{ textTransform: 'capitalize' }}
                            >
                                {data.option}
                            </Typography>
                        </MenuItem>
                    ))}
                </TextField>
                <Stack spacing={'5px'}>
                    <Typography
                        variant="caption1"
                        color={theme.palette.text.mediumEmphasis}
                    >
                        {'Key Highlights'}
                    </Typography>
                    <textarea
                        placeholder="Key Highlights"
                        value={formData.keyHighlights}
                        onChange={handleInputChange('keyHighlights')}
                        style={{
                            fontFamily: 'Mier-Book',
                            fontSize: '16px',
                            padding: '5px',
                            width: '99%',
                            border: `1px solid ${theme.palette.structuralColors.stroke1}`,
                            minHeight: '120px',
                        }}
                    />
                </Stack>
                <Autocomplete
                    multiple
                    size="small"
                    options={Teams}
                    getOptionLabel={(option) => option}
                    value={formData.riskReviewerTeam}
                    onChange={(e, value) =>
                        handleAutocompleteChange(e, value, 'team')
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Risk Reviewer Team"
                            placeholder="Risk Reviewer Team"
                        />
                    )}
                />
                <Autocomplete
                    multiple
                    size="small"
                    options={userData}
                    getOptionLabel={(option) => option.name}
                    value={userData.filter((user) =>
                        formData.riskReviewerIds.includes(user.id)
                    )}
                    onChange={(e, value) =>
                        handleAutocompleteChange(e, value, 'user')
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Risk Reviewer Name"
                            placeholder="Risk Reviewer Name"
                        />
                    )}
                />
                {formData.status === 'COMPLETED' && (
                    <TextField
                        size="small"
                        label="Actual Closure Date"
                        value={
                            formData.actualClosureDate
                                ? formData.actualClosureDate.format(
                                      'YYYY-MM-DD'
                                  )
                                : ''
                        }
                        fullWidth
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                )}
                <StyledButton variant="outlined" onClick={handleSave}>
                    <Typography variant="caption1">{'Save'}</Typography>
                </StyledButton>
            </Stack>
            <CustomSnackbar
                open={openSnackBar}
                message={message}
                success={success}
                onClose={handleClose}
                autoHideDuration={1800}
            />
        </Stack>
    );
};

export default RiskMonitoring;
