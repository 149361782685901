import React, { useState } from 'react';
import { Box, Divider, Drawer, IconButton, Stack, styled } from '@mui/material';
import Typography from '../../atoms/Typography';
import theme from '../../../theme';
import Icon from '../../atoms/Icon';
import CrossIcon from '../../../../public/assets/icons/cross.svg';
import Button from '../../atoms/Button';
import { addCommentToAnswer } from '../../../services/api';
import DoneAllIcon from '@mui/icons-material/DoneAll';

interface AddFormCommentsProps {
    open: boolean;
    onToggle: (isOpen: boolean) => void;
    comments: [];
    formSubmissionId: string;
    questionId: string;
    refreshAnswers: () => void;
    userRole: string;
    handleMarkAsResloved: () => void;
}

const StyledButton = styled(Button)({
    padding: '5px 8px',
    width: '160px',
});

const AddFormComments = (props: AddFormCommentsProps) => {
    const [formData, setFormData] = useState({
        comment: '',
    });

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleAddComment = async () => {
        const data = {
            formSubmissionId: props.formSubmissionId,
            questionId: props.questionId,
            comments: formData.comment,
        };
        const response = await addCommentToAnswer(data);
        if (response) {
            setFormData({
                comment: '',
            });
            props.refreshAnswers();
        } else {
            console.log('error');
        }
    };

    const formatDateTime = (dateTime: string) => {
        const date = new Date(dateTime);
        const formattedDate = date.toISOString().split('T')[0];
        return formattedDate;
    };

    const handleClose = () => {
        props.onToggle(false);
    };

    return (
        <React.Fragment key={'right'}>
            <Drawer open={props.open} anchor={'right'} onClose={handleClose}>
                <Stack sx={{ width: '45vw', height: '100%' }}>
                    <Stack
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '15px 10px',
                        }}
                    >
                        <Box sx={{ paddingTop: '5px' }}>
                            <Typography variant="h3">{'Comments'}</Typography>
                        </Box>
                        <IconButton onClick={handleClose}>
                            <Icon src={CrossIcon} alt={'Close Icon'}></Icon>
                        </IconButton>
                    </Stack>
                    <Divider
                        style={{
                            borderColor: theme.palette.structuralColors.gray,
                        }}
                    />
                    <Stack sx={{ padding: '10px' }}>
                        <Stack spacing={'10px'} padding={'5px'}>
                            <Stack spacing={'5px'}>
                                <textarea
                                    placeholder="Add Comment"
                                    name="comment"
                                    value={formData.comment}
                                    onChange={handleInputChange}
                                    style={{
                                        width: '98.5%',
                                        padding: '5px',
                                        border: '1px solid #cecede',
                                        color: theme.palette.text.highEmphasis,
                                        fontFamily: 'Mier-Book',
                                        fontSize: '16px',
                                    }}
                                />
                            </Stack>
                            <Stack
                                direction={'row'}
                                sx={{
                                    justifyContent: 'flexStart',
                                    alignItems: 'center',
                                }}
                                spacing={'10px'}
                            >
                                <StyledButton
                                    variant="outlined"
                                    onClick={handleAddComment}
                                >
                                    <Typography variant="caption1">
                                        {'Add Comment'}
                                    </Typography>
                                </StyledButton>
                                {props.userRole === 'ADMIN' && (
                                    <Button
                                        variant="contained"
                                        onClick={props.handleMarkAsResloved}
                                        startIcon={<DoneAllIcon />}
                                    >
                                        <Typography variant="caption1">
                                            {'Mark as resolved'}
                                        </Typography>
                                    </Button>
                                )}
                            </Stack>

                            <Divider />
                            <Stack>
                                <Typography
                                    variant="subtitle1"
                                    color={theme.palette.text.mediumEmphasis}
                                >
                                    {'Added Comments'}
                                </Typography>
                                <Stack
                                    spacing={'10px'}
                                    sx={{
                                        height: '55vh',
                                        overflow: 'scroll',
                                    }}
                                >
                                    {props.comments.length > 0 ? (
                                        <>
                                            {props.comments.map((data) => (
                                                <Stack
                                                    key={data.id}
                                                    sx={{
                                                        borderRadius: '10px',
                                                        border: '1px solid #EFEFEF',
                                                    }}
                                                >
                                                    <Stack
                                                        sx={{
                                                            padding:
                                                                '5px 5px 3px 5px',
                                                        }}
                                                    >
                                                        <textarea
                                                            readOnly
                                                            style={{
                                                                border: 'none',
                                                                color: theme
                                                                    .palette
                                                                    .text
                                                                    .highEmphasis,
                                                                fontFamily:
                                                                    'Mier-Book',
                                                                fontSize:
                                                                    '16px',
                                                            }}
                                                            value={data.text}
                                                        />
                                                    </Stack>
                                                    <Stack
                                                        direction={'row'}
                                                        sx={{
                                                            justifyContent:
                                                                'space-between',
                                                            backgroundColor:
                                                                '#EFEFEF',
                                                            padding:
                                                                '3px 5px 3px 5px',
                                                            borderBottomLeftRadius:
                                                                '9px',
                                                            borderBottomRightRadius:
                                                                '9px',
                                                        }}
                                                        spacing={'10px'}
                                                    >
                                                        <Typography
                                                            variant="caption2"
                                                            color={
                                                                theme.palette
                                                                    .text
                                                                    .mediumEmphasis
                                                            }
                                                        >
                                                            {'Added By: ' +
                                                                data.addedBy}
                                                        </Typography>

                                                        <Typography
                                                            variant="caption2"
                                                            color={
                                                                theme.palette
                                                                    .text
                                                                    .mediumEmphasis
                                                            }
                                                        >
                                                            {'Added At: ' +
                                                                formatDateTime(
                                                                    data.addedAt
                                                                )}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            ))}
                                        </>
                                    ) : (
                                        <Stack
                                            direction={'row'}
                                            sx={{
                                                justifyContent: 'center',
                                                backgroundColor:
                                                    theme.palette.accentColors
                                                        .lightBlue,
                                                border: `1px solid ${theme.palette.accentColors.blue}`,
                                                padding: '10px',
                                                borderRadius: '10px',
                                            }}
                                        >
                                            <Typography
                                                variant="body1"
                                                color={
                                                    theme.palette.accentColors
                                                        .blue
                                                }
                                            >
                                                {'No Comments Yet'}
                                            </Typography>
                                        </Stack>
                                    )}
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Drawer>
        </React.Fragment>
    );
};

export default AddFormComments;
