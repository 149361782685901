import { ThemeProvider } from '@mui/material';
import './style.css';
import theme from './theme';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import PolicyPage from './pages/PolicyPage';
import ProcedurePage from './pages/ProcedurePage';
import MandatoryDocumentPage from './pages/MandatoryDocumentPage';
import RegistryDetailsPage from './pages/RegistryDetailsPage';
import DashboardPage from './pages/DashboardPage';
import RiskRegisterDetailsPage from './pages/RiskRegistersDetailsPage';
import RiskRegistersPage from './pages/RiskRegistersPage';
import FormPage from './pages/FormPage';
import GenerateFormPage from './pages/GenerateFormPage';
import PrivateRoutes from './PrivateRoutes';
import LoginPage from './pages/LoginPage';
import { useAuth } from './context/authContext';
import VendorPage from './pages/VendorPage';
import VendorDetailsPage from './pages/VendorDetailsPage';
import VendorLoginPage from './pages/VendorLoginPage';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import { InternalAuthLayout } from './contextRouting/InternalAuthLayout';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import { VendorAuthLayout } from './contextRouting/VendorAuthLayout';
import VendorVerifyPage from './pages/VendorVerifyPage';
import VendorDashboardPage from './pages/VendorDashboardPage';
import VendorPrivateRoutes from './VendorPrivateRoutes';
import { useVendorAuth } from './context/vendorAuthContext';
import FormSubmissionComponent from './pages/FormSubmit/FormSubmissionComponent';
import ManageInternalUsers from './pages/ManageInternalUsers';
import TemplateManagement from './pages/TemplateManagement';

export const App = () => {
    const { isAuthenticated } = useAuth();
    const { isVendorAuthenticated } = useVendorAuth();

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <ScrollToTop />
                <Routes>
                    {/* Internal Routes with "/u/" */}

                    <Route element={<InternalAuthLayout />}>
                        <Route
                            path="/"
                            element={
                                isAuthenticated &&
                                localStorage.getItem('token') ? (
                                    <Navigate to="/u/dashboard" />
                                ) : (
                                    <LoginPage />
                                )
                            }
                        />
                        <Route path="/u" element={<PrivateRoutes />}>
                            <Route
                                path="dashboard"
                                element={<DashboardPage />}
                            />
                            <Route path="policies" element={<PolicyPage />} />
                            <Route
                                path="procedures"
                                element={<ProcedurePage />}
                            />
                            <Route
                                path="mandatorydocuments"
                                element={<MandatoryDocumentPage />}
                            />
                            <Route
                                path="registrydetails"
                                element={<RegistryDetailsPage />}
                            />
                            <Route
                                path="riskregister"
                                element={<RiskRegistersPage />}
                            />
                            <Route
                                path="riskregisterdetails"
                                element={<RiskRegisterDetailsPage />}
                            />
                            <Route path="vendors" element={<VendorPage />} />
                            <Route
                                path="vendordetails"
                                element={<VendorDetailsPage />}
                            />
                            <Route path="formPage" element={<FormPage />} />
                            <Route
                                path="generatePage"
                                element={<GenerateFormPage />}
                            />
                            <Route
                                path="manageUsers"
                                element={<ManageInternalUsers />}
                            />
                            <Route
                                path="formSubmission"
                                element={<FormSubmissionComponent />}
                            />
                            <Route
                                path="manageTemplate"
                                element={<TemplateManagement />}
                            />
                        </Route>
                    </Route>

                    {/* Vendor Routes with "/v/" */}
                    <Route element={<VendorAuthLayout />}>
                        <Route
                            path="/vendorlogin"
                            element={
                                isVendorAuthenticated &&
                                localStorage.getItem('accessToken') ? (
                                    <Navigate to="/v/vendordashboard" />
                                ) : (
                                    <VendorLoginPage />
                                )
                            }
                        />
                        <Route
                            path="/vendorverify"
                            element={<VendorVerifyPage />}
                        />
                        <Route path="/v" element={<VendorPrivateRoutes />}>
                            <Route
                                path="vendordashboard"
                                element={<VendorDashboardPage />}
                            />
                            <Route
                                path="formSubmission"
                                element={<FormSubmissionComponent />}
                            />
                        </Route>
                    </Route>
                </Routes>
            </ThemeProvider>
        </BrowserRouter>
    );
};
